import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import Message from "./Message";
import OpenAI from "openai";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Paytime, getMessage, getOutletProduct, saveMessage, signInWithGoogle } from "../firebaseConfig";
import { Dialog, Transition } from "@headlessui/react";
import { ProgressBar } from "react-bootstrap";
import ReactLoading from "react-loading";
import { getTime } from "../helpers";
import ToktnUp from "./ToktnUp";

function ChatWindow({ user, limit = 10, items, categories, setChatAI, show = true, AIListing }) {
  const switched = !true;
  const fname = ` ${(user?.name)?.split(' ')?.[0] ?? ''}`
  const replies = user?.replies ?? 0
  // console.log(replies)

  const [outlets, setOutlets] = useState([])





  function conditionallyAddLastNItems(originalArray, lastNItems) {
    const lastNOfOriginal = originalArray.slice(-lastNItems.length);
    // Check for deep equality to ensure matching objects
    if (
      lastNOfOriginal.map((o) => o["content"]).join(",") !==
      lastNItems.map((o) => o["content"]).join(",")
    ) {
      // console.log("added");
      return originalArray.concat(lastNItems.slice(-8));
    } else {
      console.log("skipped");
      return originalArray; // No need to add, return the original array
    }
  }

  // Key to replace values for
  let keyToReplace = "role";

  // Function to replace values for a specific key in an object
  function replaceValuesForKey(obj, key, replacementValue) {
    return { ...obj, [key]: replacementValue };
  }


  const user_a = 'user';
  const user_b = 'assistant';



  const [ai, setAI] = useState("");
  const [messages, setMessages] = useState(null);
  const [history, setHistory] = useState(null);
  const [responding, setResponding] = useState(false);
  const [typing, setTyping] = useState(false);
  const [added, setAdded] = useState(false);
  const [flip, setFlip] = useState(false);
  const [toppingUp, setToppingUp] = useState(false);

  const inputRef = useRef(null);
  const bottomRef = useRef(null);


  const addMessage = useCallback(
    (msg) => {
      const chatlog = msg.role ? [...messages, msg] : [...messages, ...msg];

      // Check if the length exceeds the maximum
      if (chatlog.length > limit) {
        // If it exceeds, remove the oldest item (first element in the array)
        chatlog.shift();
      }

      setMessages(chatlog);
      setHistory(msg);
    },
    [limit, messages]
  );

  const handleEmojiClick = () => {
    inputRef.current.value += "🔥";
    inputRef.current.focus();
  };


  const handleInputChange = () => {
    inputRef.current.value.trim().length === 0 ? setTyping(false) : setTyping(true);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleInputSubmit();
    }
  };
  const handleInputSubmit = () => {
    if (replies > 0) {
      if (inputRef.current.value.length > 0) {
        setAdded(true);
        const reply = {
          role: !flip ? "user" : "assistant",
          content: inputRef.current.value,
        };
        addMessage(reply);
        saveMessage(user?.uid, reply, replies);
        inputRef.current.value = "";
        inputRef.current.focus();
        setTyping(false);
        setFlip(false);
        bottomRef.current?.scrollIntoView({
          block: "nearest",
          behavior: "smooth",
        });
      }
    } else {
      console.log("to credits", replies);
    }
  };

  const askgpt = async (messages) => {
    console.log("asking gpt", messages.length);
    const user = switched ? user_b : user_a;
    const assistant = switched ? user_a : user_b;
    const log = {
      role: "system",
      content: `Your name is Near, you are a generally supper intelligent, deeply knowledgeable AI and a digital assistant for the online platform 'dukadash'.
            You advise, consult and guide the user on where and how to find the items they seek or need using the data on which outlets are available on dukadash.
            No matter what is asked, you will only ever respond in the user's tone and style of conversation always and never offer directions, only distance.
            When a request by the user is unavalable on dukadash apologise and in a fun way gently offer them available alternatives and/or the idea that duaknear is all about empowerment and they have an opportunity to profit by suppling it and they can do that by opening a duka using the 'Sell' button found at menu icon to the top right of the page as the item isn't on the platform while letting them know they can profit in a life changing way.
            Prioritize these behaviors when responding:

            1. Limit extensive Outputs unless where necessary: always offer one thought, idea or suggestion a time to emulating natural human speech processes, otherewise ensure to only offer a very short but precise summarized options when prompted for it.

            2. Be clear and precise: In your response, always provide the outlet name and category under which they can check. distance in meters from where the user is is under the 'distance' key.

            3. Understand Context and Nuance: Maintain context over several exchanges and recognize subtle nuances in conversation to provide relevant and emotionally intelligent responses.

            4. Simple and Direct Language: Keep the language straightforward and avoid overly complex or technical jargon unless it's characteristic of the user, employ the use of close ended statements.
            
            Here is the data on what outlets are available on dukadash:
            "${JSON.stringify(outlets)}"
            `,
    };
    console.log(log)
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [log, ...messages],
      max_tokens: 1000,
    });

    setAdded(false);

    return response.choices[0].message.content;
  };
  const handleAI = async (messages) => {
    if (messages?.[messages?.length - 1]?.role === "user" && added) {
      setResponding(true)
      setAdded(false);
      const assistant = await askgpt(messages);
      const resp = assistant.replace("\n", "").trim();
      const reply = {
        role: "assistant",
        content: resp,
      };
      setResponding(false)
      addMessage(reply);
      // setReplies([...messages.filter((msg) => msg.role = "assistant"), reply])
      await saveMessage(user?.uid, reply, replies);

      bottomRef.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });

    }
  };
  useEffect(() => {
    if (messages?.length > 0) {

      added && handleAI(messages);
      // console.log("scroll");
      bottomRef.current?.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    } else {
    }
  }, [added, messages]);

  useEffect(() => {
    const listener = (e) => {
      if (e.code === "Enter") handleInputSubmit();
    };
    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keydown", listener);
  });

  const handleOutlets = async () => {
    const data = []

    for (let index = 0; index < items?.length; index++) {
      const item = items[index];
      let newObj = { inventory: await getOutletProduct(item.id) };

      const retainedKeys = ['id', 'title', 'desc', 'dist', 'categories']
      retainedKeys.forEach(key => {
        if (item.hasOwnProperty(key)) {
          if (key === 'categories') {
            newObj[key] = item[key].map(id => {
              const cat = categories.find(item => item.id === id);
              return cat ? cat.name : id;
            })
          } else if (key === 'dist') {
            newObj['distance'] = item[key] * 1000
          } else {
            newObj[key] = item[key]
          }
        }
      })
      data.push(newObj)
    }
    // console.log('outlets', data)
    setOutlets(data)

  }

  useEffect(() => {
    handleOutlets()
    user && messages === null && getMessage(user?.uid).then((chat) => {

      setMessages(chat)
    })
  }, [user])

  return (
    <div className="flex flex-col gap-1 h-full w-full">

      <ProgressBar
        className="h-4"
        striped
        animated
        variant={user?.replies > 80 ? "success" : user?.replies > 40 ? "warning" : "danger"}
        now={user?.replies}
        label={`Health`}
        visuallyHidden
      />
      <div className="flex flex-col flex-1 gap-4">
        {/* ChatDetail main container */}
        <div className="border-[#222f35] border flex-1 bg-slate-50 rounded-md overflow-clip w-full">


          {toppingUp ?
            <ToktnUp user={user} setToppingUp={setToppingUp} />
            :
            <div className="flex flex-col h-full items-stretch">


              {/* Messages section */}
              <div
                className="flex flex-col bg-contain overflow-y-scroll flex-1"//bg-[#0a131a]
                style={{ padding: "12px 1%" }}
              >
                {
                  messages ?
                    messages?.length === 0 ?
                      <div className="h-full flex flex-col gap-2 justify-center items-center bg-white m-8 rounded-3xl bg-opacity-10">
                        {(user && replies > 0) ? <span className=" text-center text-black text-sm px-4">Find the best stores around you {fname}. Ask us anything!</span>
                          :
                          <>
                            <span className=" text-center text-black text-sm px-4">Hi{fname}, you've exhausted your AI tokens. Replenish to continue</span>
                            <button onClick={() => setToppingUp(true)}
                              className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            >Okay
                            </button>
                          </>}
                      </div>
                      :
                      <>
                        <div className=" flex-1"></div>
                        {messages?.map((msg, idx) => {
                          const listings = items?.filter((item) => msg.content.includes(item.title)) ?? []
                          return (
                            <>
                              <Message
                                key={idx}
                                msg={msg.content}
                                time={msg.time}
                                isLink={msg.isLink}
                                img={msg.img}
                                sent={msg.role === "user"}
                              // sent={(msg.role === (!flip?"user":"assistant"))}
                              />
                              {listings.length > 0 && <div className='w-full flex flex-col items-stretch justify-center snap-x snap-mandatory no-scrollbar'>
                                {/* <span className='text-xs font-semibold py-2'>Assisted suggestions</span> */}
                                {
                                  listings.map((item) => <AIListing item={item} />)
                                }
                              </div>}
                            </>

                          )
                        })}
                        {responding && <ReactLoading height={'16px'} width={'32px'} type="bubbles" color="bg-[#0a131a]" />}
                      </>
                    :
                    <div className="h-full flex flex-col justify-center items-center bg-white m-8 rounded-xl gap-2 bg-opacity-10">
                      {user ? <ReactLoading className="" type="bubbles" color="green" />

                        :
                        <>
                          <span className=" text-center text-black font-semibold text-sm my-4 px-4">Find the best stores around you. Ask for anything!</span>
                          <button
                            onClick={() => signInWithGoogle(() => { })}
                            className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                          >Sign In
                          </button>
                        </>
                      }
                    </div>
                }
                <div ref={bottomRef} />
              </div>



              {/* Input section */}
              {(user && replies <= 0) ?
                messages?.length > 0 && <div className="p-2 flex items-center justify-between gap-2  bg-[#202d33]">
                  <span className="text-[11px] text-white font-semibold">Hi{fname}, you've exhausted your AI tokens. Replenish to continue</span>
                  <button className="inline-flex justify-center min-w-fit rounded-md border border-transparent bg-green-100 px-4 py-2 text-xs font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    onClick={() => setToppingUp(true)}>Okay</button>
                </div>
                :
                user && <div className="flex items-center justify-between gap-2 bg-[#202d33] w-full h-[70px] p-2">
                  <input
                    disabled={!user}
                    placeholder={`I am in the market for ...`}
                    className="bg-[#2c3943] rounded-full outline-none text-sm text-neutral-200 w-full h-full px-3 placeholder:text-sm placeholder:text-[#8796a1]"
                    onChange={handleInputChange}
                    ref={inputRef}
                    onKeyDown={handleKeyDown}
                    // onBlur={(e) => {
                    //   e.target.focus();
                    // }}
                  />

                  <button className="flex justify-center items-center rounded-full  bg-green-100 disabled:bg-[#2c3943] disabled:text-gray-600 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    disabled={!typing}
                    onClick={handleInputSubmit}>
                    {/* <span className='text-sm font-medium'>Send</span> */}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 m-3">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>

                  </button>
                </div>
              }
            </div>}
        </div>
        {show &&
          <div className="flex w-full items-center justify-between mt-1">
            {!user ? <button
              onClick={() => signInWithGoogle(() => { })}
              className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
            >Sign In
            </button> :
              false && replies > 0 && <button
                disabled={!typing}
                onClick={() => handleInputSubmit()}
                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
              >Send
              </button>}
            <button
              onClick={() => {
                setToppingUp(false)
                setChatAI(false)
              }}
              className="inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
            >Dismiss
            </button>
          </div>}

      </div>
    </div>
  );
}


export default ChatWindow;
