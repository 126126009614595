import React, { useEffect, Fragment, useState, useRef } from 'react'
import { addListing, addMenu, captureBid, captureDeliver, capturePost, getAllPosts, getBids, getCategories, getDeliversNear, getDeliverUser, getLikes, getLovedLink, getMenu, getMenus, getRequests, getSaves, getUserMenus, getUserPosts, likeUnlike, prelovedUpdate, saveUnsave, signInWithGoogle, updateDeliverInfo } from '../firebaseConfig'
import { useNavigate, useParams } from 'react-router-dom'
import { validatePhoneNumber, isBlank, sleep, handleSignIn, processRequestData } from '../helpers'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Dialog, Transition, TabPanel, TabPanels, Tab, TabList, TabGroup } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import Listing from './Listing'
import Menu from '../pages/Menu'
import FavouriteListing from './FavouriteListing'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Alert, Collapse, ImageList, ImageListItem } from '@mui/material'
import { shareOnMobile } from 'react-mobile-share'
import PreLovedListing from './PreLovedListing'
import ReactLoading from 'react-loading'
import { Helmet } from 'react-helmet'
import ViewMore from './ViewMore'
import ImageToLink from './ImageToLink'
import Donate from './Donate'
import Message from './Message'
import ChatWindow from './ChatWindow'
import { ProgressBar } from 'react-bootstrap'
import AddDuka from './AddDuka'
import ReactTimeago from 'react-timeago'
import AIButton from './AIButton'
import RequestData from './RequestData'

const Home = ({ user, handleLocation, items, favs, loved, myloved, pos, param, handleFaves, handleLoved }) => {
    const cathead = { index: 0, id: 'auto', name: 'All outlets' }
    const base = 20
    const [top, setTop] = useState(true)
    const [outlets, setOutlets] = useState([])
    const post_tabs = [{ name: 'My Requests' }, { name: 'All Requests' }]
    const [tab_index, setTabIndex] = useState(0)
    const [selectedOutlet, setSelectedOutlet] = useState(null)
    const paramid = param?.id ?? ''
    const prelovedid = param?.prelovedid
    const [chatai, setChatAI] = useState(false)
    const [selected, setSelectedTab] = useState('Self serve')
    const [tab, setTab] = useState(['Self serve', 'Assisted', 'My Requests']);
    const [requests, setRequests] = useState([]);
    const [dominantColor, setDominant] = useState('')
    const [prelovedpopup, setPrelovedpopup] = useState(null)
    const [isOpen, setIsOpen] = useState([paramid])
    const [search, setSearch] = useState('')
    const [toggleSearch, setToggle] = useState(false)
    const [liked, setLiked] = useState(false)
    const [orders, setOrders] = useState(false)
    const [state, setState] = useState(null)
    const [main, setMain] = useState(null)
    const [saves, setSaves] = useState([])
    const [categories, setCategories] = useState([cathead])
    const [selectedCategory, setSelectedCategory] = useState(cathead)
    const [copied, setCopy] = useState(false)
    const [share, setShare] = useState(false)
    const [requestid, setRequestID] = useState(null)
    const [request, setRequest] = useState('')
    const [reply, setReply] = useState('Hi there, please reach out to us for help with this')
    const [requesting, setRequesting] = useState(false)
    const [userOutlets, setUserOutlets] = useState([])
    const [userPosts, setUserPosts] = useState([])
    const [allPosts, setAllPosts] = useState([])
    const [postBids, setPostBids] = useState([])
    const [partners, setPartners] = useState([])
    const [pickup, setPickup] = useState(false)
    const charge = 350


    const resetStates = () => {
        setIsListing(false)
        handleLoved()
        setTitle('')
        setDesc('')
        setUploading(false)
        setReason('')
        setPrice(null)
        setFiles([])
        setListingID('')
        setmsisdn(phone)
    }

    const navigate = useNavigate();
    const toPreloved = () => {
        navigate('/preloved')
    }
    const toAccount = () => {
        navigate('/account')
    }

    const handleShareClose = () => {
        setShare(false);
        setCopy(false);
    };
    const handleShareCopy = () => {
        setCopy(true);
    };
    const onLike = (isLike) => {
        setLiked(isLike)
        setIsOpen([])
        handleFaves()
    }
    const onSave = () => {
        getSaves(user.uid).then((res) => setSaves(res))
    }
    const handleClick = () => {
        if (toggleSearch) {
            setSearch('')
        }
        setToggle(!toggleSearch)
    }
    const handleLike = (id, likes) => {
        if (user) {
            const data = {
                id: id,
                likes: likes,
                uid: user.uid
            }
            likeUnlike(data, onLike)
        } else {
            handleSignIn()
        }
    }
    const handleSave = (id, saves) => {
        if (user) {
            const data = {
                id: id,
                saves: saves,
                uid: user.uid
            }
            saveUnsave(data, onSave)
        } else {
            handleSignIn()
        }
    }
    const handleShare = (item, pure) => {
        if (item.id === user.uid && pure) {
            toAccount()
        } else {

            setState(item)
            shareOnMobile(
                {
                    text: '',
                    url: `https://dukadash.com/${item.id}`,
                    title: '',
                },
                () => {
                    setIsOpen([])
                    setShare(true);
                }
            )
        }
    }

    const closeModal = () => {
        setIsOpen([])
        setSearch('')
        setToggle(false)
        setState(null)
        setPrelovedpopup(null)
        setLiked(false)
        setIsListing(false)
        setListingID('')
    }

    const getDominantColor = async (file) => {
        // Create an object URL for the image (temporary URL)
        const url = URL.createObjectURL(file);

        // Create a new image element
        const image = new Image();
        image.src = url;

        // Wait for the image to load asynchronously using await
        try {
            await new Promise((resolve, reject) => {
                image.onload = resolve;
                image.onerror = reject;
            });
        } catch (error) {
            console.error("Error loading image:", error);
            URL.revokeObjectURL(url); // Clean up even on error
            throw error; // Re-throw the error for further handling (optional)
        }


        // Create a hidden canvas element
        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = 1;
        const context = canvas.getContext('2d');

        // Draw the image onto the canvas (scaled down to a single pixel)
        context.drawImage(image, 0, 0);

        // Get the image data for the single pixel
        const imageData = context.getImageData(0, 0, 1, 1).data;

        // Extract the RGB values
        const red = imageData[0];
        const green = imageData[1];
        const blue = imageData[2];

        // Convert RGB to HEX (adapted for readability)
        const hex = "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).padStart(6, '0');
        setDominant(hex)
        return hex.toUpperCase();
    }
    const navigateToMenu = (item) => {
        // 👇️ navigate
        setIsOpen([item.id, ...isOpen])
        setState(item)
        const likes = favs.map(item => item['id']) ?? []
        setLiked(likes.includes(item.id))
        //navigate(`/${item.id}`, { state: item });
    }

    const handleCategories = async () => {
        const cats = await getCategories()
        setCategories([cathead, ...cats, { index: cats.length + 1, id: 'fin', name: 'etc' }])
    }


    const [uploading, setUploading] = useState(false)
    const [isListing, setIsListing] = useState(false)
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [reason, setReason] = useState('')
    const [price, setPrice] = useState(null)
    const [phone, setPhone] = useState('')
    const [percent, setPercent] = useState(0)
    const [files, setFiles] = useState([])

    const handleReplyChange = (event) => {
        setReply(event.target.value);
    }
    const handleRequestChange = (event) => {
        setRequest(event.target.value);
    }
    const handleNameChange = (event) => {
        setTitle(event.target.value);
    }
    const handleDescChange = (event) => {
        setDesc(event.target.value);
    }
    const handleReasonChange = (event) => {
        setReason(event.target.value);
    }
    const handlePriceChange = (event) => {
        setPrice(event.target.value);
    }
    const handleFileChange = (event) => {
        setPercent(0)
        const files = event.target.files;
        setFiles([...files]);
        getDominantColor(files[0])
            .then(dominantColor => {
                console.log("Dominant color:", dominantColor);
            })
            .catch(error => {
                console.error("Error getting dominant color:", error);
            });
    }

    const handlePhoneChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);
        // console.log(result, phoneNumber)
        if (result.result !== "invalid") {
            setPhone(result.result);
        } else {
            setPhone('')
        }
    }

    const closeListing = (id) => {
        setIsListing(false)
        handleLoved()
        setTitle('')
        setDesc('')
        setUploading(false)
        setReason('')
        setPrice(null)
        setFiles([])
        setListingID(id)
        setmsisdn(phone)
    }
    const handleUpload = async () => {
        if (pos) {
            setUploading(true)
            const data = {
                ...pos,
                title: title,
                desc: desc,
                reason: reason,
                price: parseInt(price),
                uid: user.uid,
                active: user?.active ?? false,
                phone: phone,
                caption: `🟢 ♦️${title} - ${desc}. ♦️ 🟢 💥Selling at kes ${price}. 💥 Reason for selling: ${reason}⭐Contact ${phone}🤙.`,
                dominantColor: dominantColor
            }

            if (files.length > 0) {
                await addListing(files, setPercent, data, closeListing)
            } else {
                setUploading(false)
                console.log('no file')
            }
        } else {
            console.log('no pos')
        }
    }

    const handleIsListing = async () => {

        if (user) {
            setIsListing(true)
        } else {
            await handleSignIn()
            setIsListing(true)
        }
    }


    const [prompting, setPrompting] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [msisdn, setmsisdn] = useState('');
    const [listingid, setListingID] = useState('');

    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);

        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }

    const SendPayload = async (payload, { setPrompting, setWaiting }) => {

        console.log(payload)

        if (payload.amount === '' || payload.msisdn === '') {
            alert("Missing info")
        } else {
            setPrompting(true);
            setWaiting(true);
            const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
            const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=preloved/${payload.account_id}`;
            fetch(url, {
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then(async (data) => {
                    console.log(data)

                    if (data.success) {
                        console.log('ok')


                        // await prelovedUpdate(payload.account_id, { active: true })
                    } else {

                    };
                    await sleep(12000)
                    setWaiting(false)
                    setPrompting(false)

                    closeModal()
                    handleLoved()
                })
                .catch((error) => {
                    console.error('Error:', error)
                    setPrompting(false)
                    setWaiting(false)
                    alert(error)
                });
        }
    }

    const handleMyListingClick = (item) => {
        if (item.active) { } else {
            setListingID(item.id)
            setmsisdn(item.phone)
        }
    }



    const fave = () => {
        handleFaves()
    }
    const AddLike = () => {
        return (
            <div className='p-2 flex space-x-2 items-center justify-center'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                    <path d="M9.653 16.915l-.005-.003-.019-.01a20.759 20.759 0 01-1.162-.682 22.045 22.045 0 01-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 018-2.828A4.5 4.5 0 0118 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 01-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 01-.69.001l-.002-.001z" />
                </svg>
                <span className='text-xs '>{favs.length > 0 ? "For your convenience" : "No favourites yet? They'll show up here for easy access!"}</span>
            </div>
        )
    }

    const handleSell = () => {
        if (user) {
            handleLocation(true)
        } else {
            handleSignIn()
        }
    }

    const handleCreatePost = async () => {
        const data = {
            uid: user?.uid ?? '',
            message: request,
            name: user?.name ?? '',
            email: user?.email ?? '',
            ...pos
        }
        setRequest('')
        await capturePost(data)
        const posts = await getUserPosts(user?.uid)
        setUserPosts(posts)
    }

    const handleCreateBid = async (postid) => {
        let outletdata = {}
        const retainedKeys = ['id', 'title', 'image', 'phone', 'lat', 'lng']
        retainedKeys.forEach(key => {
            if (selectedOutlet.hasOwnProperty(key)) {
                outletdata[key === 'id' ? 'vendorid' : key] = selectedOutlet[key]
            }
        })
        setSelectedOutlet(null)
        const data = {
            ...outletdata,
            uid: user?.uid ?? '',
            message: reply,
            name: outletdata?.title ?? '',
            postid: postid
        }
        await captureBid(data)
        const bids = await getBids(postid, pos)
        setPostBids(bids)
    }

    const handleRequestSelection = async (postid) => {
        setRequestID(postid)
        setPostBids([])
        if (postid) {
            const bids = await getBids(postid, pos)
            setPostBids(bids)
        } else {
            setPostBids([])
        }

    }

    const handleRequests = async () => {
        setUploading(true)
        const r = await getRequests(user.uid, processRequestData)
        setRequests(r)
        setUploading(false)
    }

    const handleInit = async () => {
        if (pos) {
            getAllPosts(pos, setAllPosts)
            getDeliversNear(pos, setPartners)
        }

        if (paramid !== '') {
            console.log(paramid)
            getMenu(paramid, pos).then((res) => { setState(res) })
        }
        if (user) {
            getSaves(user.uid).then((res) => setSaves(res))
            getUserMenus(user.uid, setUserOutlets)
            const posts = await getUserPosts(user.uid)
            setUserPosts(posts)

        }

        if (prelovedid) {
            getLovedLink(prelovedid).then((res) => {
                console.log(res)
                setPrelovedpopup(res)
            }
            )
        }
    }
    // detect whether user has scrolled the page down by 10px
    const scrollHandler = () => {
        window.scrollY > 50 ? setTop(false) : setTop(true)
    }
    const aiRef = useRef(null);
    const showAssistant = () => {
        aiRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    }

    useEffect(() => {
        scrollHandler()
        window.addEventListener('scroll', scrollHandler)
    }, [])

    useEffect(() => {
        handleInit()
        handleCategories()
    }, [pos, paramid, user, prelovedid])

    const AIListing = ({ item }) => {
        return (<div className='snap-center'>
            <Listing item={item} handleFaves={fave} user={user} pos={pos} handleShare={handleShare} categories={categories} isOpen={isOpen} setIsOpen={setIsOpen} ai={true} />
        </div>)
    }

    return (
        <div className={`flex flex-col flex-1 justify-start items-center ${chatai ? '' : 'pb-24'} bg-white`}>
            {selected === tab[0] &&
                <div className={`fixed w-full z-10 bg-opacity-90  transition duration-300 ease-in-out ${!top ? 'bg-white bg-opacity-30 hover:border-b-emerald-400 backdrop-blur-sm shadow-lg flex' : 'hidden'} bottom-8 left-0 max-w-fit right-0 mx-auto z-10 shadow rounded-lg overflow-clip`}>
                    
                <AIButton chatai={tab[1]} select={selected} text={tab[1]} showAssistant={() => {
                        setSelectedTab(tab[1])
                        setChatAI(true)
                        showAssistant()
                    }} />
                </div>
            }

            <div className={`text-center flex justify-center gap-2 items-center my-3 flex-row`}>
                {/* <AddDuka user={user} handleLocation={handleLocation} text={'Supply'} /> */}
                {/* <button className="flex-1  min-w-fit inline-flex justify-center items-center rounded-md border border-transparent bg-green-100 disabled:bg-gray-300 disabled:text-gray-900 px-3 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    onClick={() => {
                        setRiding(true)
                    }}>
                    <span className='font-semibold text-sm mx-2'>{user?.isRider ? 'Procure' : 'Procure'}</span>
                </button> */}
                {/* <button className="flex justify-center gap-2 items-center bg-white border rounded-lg p-2 hover:border-b-emerald-400"
                    onClick={() => {
                        setRequesting(true)
                    }}>
                    <span className='font-semibold text-sm'>Requests</span>
                </button> */}
                {/* <button className="flex justify-center gap-2 items-center bg-white border rounded-lg p-2 hover:border-b-emerald-400"
                    onClick={() => {
                        setPickup(true)
                        setUserDelivers([])
                    }}>
                    <span className='font-semibold text-sm'>Tumana</span>
                </button> */}
                <AIButton chatai={tab[0]} select={selected} text={tab[0]} showAssistant={() => {
                    setSelectedTab(tab[0])
                    setChatAI(false)
                }} />
                <AIButton chatai={tab[1]} select={selected} text={tab[1]} showAssistant={() => {
                    setSelectedTab(tab[1])
                    setChatAI(true)
                }} />
                {user && <AIButton chatai={tab[2]} select={selected} text={tab[2]} showAssistant={() => {
                    setSelectedTab(tab[2])
                    handleRequests()
                }} />}
            </div>
            <div ref={aiRef} />

            {selected === tab[0] &&
                <>

                    <div className='w-full flex justify-center no-scrollbar gap-x-2 py-2 md:max-w-xl flex-wrap max-w-full snap-x'>
                        {categories.map((category) => {
                            const selected = selectedCategory?.name === category.name
                            return (
                                <button key={category.id} onClick={() => setSelectedCategory(category)}
                                    className={`snap-start flex items-center gap-2 rounded-lg py-1.5 px-3 min-w-fit text-xs my-1 ${selected ? 'text-white bg-red-400' : 'text-black bg-white hover:bg-blue-100'} `}
                                >
                                    {selected && <CheckIcon className="h-4 fill-white" />}
                                    <span>{category.name}</span>
                                </button>
                            )
                        })}
                    </div>
                    <div className='w-full flex flex-col  flex-1 items-stretch'>
                        {<Listbox className='mt-3 w-full flex justify-center items-center' as={'div'} value={selectedCategory} onChange={setSelectedCategory}>
                            <ListboxButton className=' bg-white px-4 py-1 rounded-md min-w-[250px] relative border-b'>
                                {selectedCategory?.name}
                                <ChevronDownIcon
                                    className="group pointer-events-none absolute top-2.5 right-2.5 w-4 fill-black/60"
                                    aria-hidden="true"
                                />
                            </ListboxButton>
                            <ListboxOptions className='bg-white rounded-md border my-1 h-[200px] overflow-scroll z-10' anchor="bottom">
                                {categories.map((category) => (
                                    <ListboxOption key={category.id} value={category}
                                        className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-blue-100 data-[selected]:text-white data-[selected]:bg-red-400 m-1 "
                                    >
                                        <CheckIcon className="invisible h-4 fill-white group-data-[selected]:visible" />
                                        {category.name}
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </Listbox>}
                        {/* user likes */}
                        {favs.length > 0 && <div className='px-2 my-2 flex flex-col bg-red-900 text-white'>
                            <AddLike />
                            {
                                <div className='px-2 my-4 flex flex-wrap items-stretch justify-center text-black'>
                                    {favs.length > 0 &&
                                        favs?.map((item) => {
                                            return <FavouriteListing item={item} navigateToMenu={navigateToMenu} />
                                        })}
                                </div>
                            }
                        </div>
                        }

                        {/* user menus */}
                        {!prelovedpopup &&
                            <>

                                <div className='flex flex-col overflow-auto items-center justify-center snap-x snap-mandatory no-scrollbar'>

                                    {pos ?
                                        <>
                                            {items ?
                                                items.filter((item) => item.categories.includes(selectedCategory.id) || selectedCategory.index === 0).length > 0 ?
                                                    items.filter((item) => item.categories.includes(selectedCategory.id) || selectedCategory.index === 0).map((item) => {
                                                        return <div className='snap-center w-full md:w-[30vw]'>
                                                            <Listing item={item} handleFaves={fave} user={user} pos={pos} handleShare={handleShare} categories={categories} isOpen={isOpen} setIsOpen={setIsOpen} /></div>
                                                    })
                                                    : <div className='flex flex-col space-y-2 justify-between items-center my-8'>
                                                        <span className='text-xs font-light'>Sorry, no listings for {selectedCategory.name}!</span>

                                                    </div>
                                                :
                                                <ReactLoading type="bubbles" color="black" />
                                            }
                                            {/* <Donate user={user} /> */}
                                        </>
                                        :
                                        <div className='mt-16 flex flex-col px-8 space-y-4 items-center justify-center'>

                                            <span className='text-lg font-light text-red-500 max-w-sm text-center'>Location access is required to show nearby dukas. Use a regular browser then enable to continue!</span>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                        {/* more list */}
                        {/* {items && <div className='px-2 mt-2 flex flex-col' style={{ backgroundColor: 'rgba(238,233,221, 1)' }}>
                        <div className='mt-4 flex space-x-2 items-center justify-center'>
    
                            <span className='text-sm font-light'>More</span>
                        </div>
                        {
                            <div className='px-2 mb-4 flex flex-wrap items-stretch justify-center text-black w-full'>
                                {items.length > 0 &&
                                    items?.map((item) => {
                                        return <FavouriteListing item={item} navigateToMenu={navigateToMenu} />
                                    })}
                            </div>
                        }
                    </div>
                    } */}

                        {/* vitusmart */}
                        {/* {loved.length > 0 && <div className='my-2 flex flex-col bg-white items-center'>
                        {
                            myloved.length > 0 && <div className=' flex flex-col space-y-4 bg-white mt-4 w-full items-center '>
                                <span className='text-xs font-semibold '>My resales</span>
                                <div className=' p-2 flex flex-row overflow-x-auto w-full bg-white space-x-2'>
                                    {
                                        myloved.map((item) => {
                                            return <PreLovedListing item={item} handleSave={handleSave} foruser={true} handleMyListingClick={handleMyListingClick} />
                                        })}
                                </div>
                            </div>
                        }
    
                        {
                            saves.length > 0 && <div className=' mt-4 flex flex-col space-y-4 bg-red-900 w-full items-center '>
                                <div className='p-2 flex flex-row overflow-x-auto overscroll-contain w-full bg-red-900 text-white space-x-2'>
                                    {
                                        saves?.map((item) => {
                                            return <PreLovedListing item={item} handleSave={handleSave} saved={true} topped={true} />
                                        })
                                    }
                                </div>
                            </div>
                        }
    
                        <div className='py-2 px-4  mt-8 w-full flex items-center justify-between'>
                            <div className='md:flex-1 flex space-x-2 justify-center items-center'>
    
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="hidden md:block w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
                                </svg>
                                <span className='text-lg font-bold my-4'>Resale items</span>
                            </div>
                            <ViewMore goto={toPreloved} />
                        </div>
                        {loved.length > 0 &&
                            <div className='flex flex-col items-center space-y-4 pb-16'>
                                <div className='flex flex-wrap justify-between'>
    
                                    <>
                                        <div className={`${' w-1/2 md:w-72'} inline-flex flex-col justify-start p-6 mb-4 flex-shrink-0`}>
                                            <div className={`flex space-x-1 items-center justify-center cursor-pointer aspect-square rounded-2xl border hover:bg-green-900 bg-green-100 text-green-900 hover:text-white hover:shadow shadow-slate-950  my-2`}
                                                onClick={handleIsListing}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                                </svg>
    
                                                <span className='block text-xs'>List on dukadash!</span>
                                            </div>
                                        </div>
                                    </>
                                    {
                                        loved?.filter(
                                            item => (item.caption.toLowerCase().includes(search.toLowerCase()))
                                        ).map((item) => {
                                            return <PreLovedListing item={item} handleSave={handleSave} saved={saves.map(i => i.code).includes(item.code)} />
                                        })}
                                </div>
    
                                <ViewMore goto={toPreloved} />
                            </div>
                        }
                    </div>} */}


                    </div>
                </>
            }
            {selected === tab[1] &&
                <div className='w-full flex-1 md:max-w-xl px-2 flex flex-col gap-2 items-center '>
                    <ChatWindow user={user} items={items} categories={categories} show={false} AIListing={AIListing} />
                </div>
            }
            {selected === tab[2] &&
                <div className='w-full md:max-w-xl pt-4 px-2 flex flex-col gap-2 items-center '>
                    {
                        uploading?
                        <ReactLoading type="bubbles" color="black" />
                        :
                        requests.map((request) => {
                            return (
                                    <RequestData item={request} clickable={true} />
                            )
                        })
                        
                    }
                </div>
            }

            <Transition appear show={orders} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={setOrders}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0">
                        <div className="flex h-full items-center justify-center p-3">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="h-[90%] flex flex-col w-full max-w-md transform rounded-2xl overflow-clip shadow-xl transition-all" style={{ backgroundColor: 'rgba(238,233,221, 1)' }}>
                                    <div className='bg-white flex gap-4 shadow-md justify-center items-center'>
                                        {post_tabs.map(({ name }, idx) => {
                                            return (
                                                <button className={`${tab_index === idx ? 'bg-red-400 text-white ' : 'hover:bg-red-200 hover:text-white '} my-4 mx-1 rounded-full py-1 px-3 text-sm/6 font-semibold focus:outline-none focus:outline-1 focus:outline-red-900`}
                                                    onClick={() => setTabIndex(idx)}>
                                                    {name}
                                                </button>
                                            )
                                        })
                                        }
                                    </div>

                                    <div className='flex-1 overflow-auto flex flex-col gap-2 py-2 px-3 bg-gray-200'>

                                        {(tab_index === 0 ? userPosts : allPosts).map((post, idx) => {
                                            return (
                                                <div key={idx} className={`${(requestid === post.id) ? 'border-2 border-gray-800' : ''} rounded bg-gray-100 flex flex-col`}>
                                                    <button className={`flex px-2 py-3 shadow-md gap-1 justify-between items-center rounded  bg-white `}
                                                        onClick={() => {
                                                            handleRequestSelection((requestid === post.id) ? null : post.id)
                                                        }}>

                                                        <div className='flex flex-col justify-start items-start px-1'>
                                                            <span className='text-start text-sm font-light'>{post.message}</span>
                                                            <span className='text-[8px] font-semibold text-gray-500'>{post.date}</span>
                                                        </div>
                                                        <div className="inline-flex justify-center rounded-full  border-transparent  px-4 py-1">
                                                            {(requestid === post.id) ?
                                                                <ChevronUpIcon
                                                                    className="group pointer-events-none top-2.5 right-2.5 w-4 fill-black/60"
                                                                    aria-hidden="true"
                                                                /> :
                                                                <ChevronDownIcon
                                                                    className="group pointer-events-none top-2.5 right-2.5 w-4 fill-black/60"
                                                                    aria-hidden="true"
                                                                />}
                                                        </div>
                                                    </button>
                                                    {(requestid === post.id) &&
                                                        <div key={idx} className='flex flex-col w-full'>
                                                            {!(postBids.find((bid) => userOutlets.some(item => item.id === bid.vendorid))) &&
                                                                <div key={idx} className='flex flex-col py-2 gap-1 border m-2'>
                                                                    <span className='px-2 text-xs font-semibold'>{tab_index !== 0 ? 'Respond to the request! Choose Duka' : 'No bids yet.'}</span>
                                                                    {tab_index !== 0 && <div className='flex px-2 flex-1  overflow-x-auto no-scrollbar'>
                                                                        {userOutlets.map((outlet) => {
                                                                            return (
                                                                                <><button
                                                                                    onClick={() => { setSelectedOutlet((selectedOutlet?.id === outlet.id) ? null : outlet) }}
                                                                                    className={`${(selectedOutlet?.id === outlet.id) ? 'border-black font-bold' : 'font-light border-transparent'} border m-2  min-w-fit inline-flex justify-between items-center rounded-md bg-white gap-1 px-2 py-1 text-sm font-medium`}>
                                                                                    <img alt='' src={outlet.image} className={`w-6 h-6 border object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                                                                    <span className='my-0 text-xs text-center'>{`${outlet.title} (${outlet.active ? 'Listed' : 'Unlisted'})`}</span>
                                                                                </button>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        <AddDuka user={user} text={'Add your duka'} />
                                                                    </div>}
                                                                    {(userOutlets.find((outlet) => selectedOutlet?.id === outlet.id)) &&
                                                                        <div className='flex flex-row items-center gap-2 px-1 py-2 border-t'>
                                                                            <input
                                                                                className=" px-4 py-2 w-full overflow-hidden rounded-lg bg-white text-left border sm:text-sm"
                                                                                placeholder='I will ...'
                                                                                defaultValue={reply}
                                                                                onChange={(e) => handleReplyChange(e)}
                                                                                type='text' />
                                                                            <button
                                                                                disabled={reply === ''}
                                                                                type="button"
                                                                                className="inline-flex min-w-fit justify-center rounded-md border bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 disabled:text-gray-900 disabled:bg-gray-100"
                                                                                onClick={() => handleCreateBid(post.id)}
                                                                            >Send</button>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }

                                                            {
                                                                postBids.map((bid, idx) => {
                                                                    return (

                                                                        <div key={idx} className={`m-2 flex flex-col justify-between items-start rounded-md bg-white gap-1 px-2 text-sm font-medium`}>
                                                                            <div key={idx} className='flex flex-row w-full justify-between items-start gap-2 border-b pt-2 pb-1'>
                                                                                <img alt='' src={bid.image} className={`w-6 h-6 border object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                                                                <div className='flex-1 flex flex-col justify-start items-start'>
                                                                                    <span className='my-0 text-sm text-center'>{`${bid.name}`}</span>
                                                                                    <span className='my-0 text-[10px] text-center font-light text-red-600'>{`${bid?.dist * 5 < 60 ? `${(1 + bid?.dist * 5)?.toFixed(0) ?? '--'} minute${(1 + bid?.dist * 5)?.toFixed(0) === '1' ? '' : 's'} away` : `${((bid?.dist * 5) / 60)?.toFixed(0) ?? '--'} ${((bid?.dist * 5) / 60)?.toFixed(0) === '1' ? 'hour' : 'hrs'} away`}`}</span>
                                                                                </div>
                                                                                <span className='my-0 text-[8px] text-center text-gray-600'>{`${bid.date}`}</span>
                                                                            </div>
                                                                            <span className='text-start text-xs font-light  py-3 px-2'>{bid.message}</span>

                                                                            <div className='flex w-full flex-row gap-8 py-2 items-center justify-between'>
                                                                                <a className={`flex gap-2 flex-1 text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                                                                    href={`tel://${bid.phone}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                                                                                        <path fill-rule="evenodd" d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z" clip-rule="evenodd" />
                                                                                    </svg>
                                                                                    <span>{`Call`}</span>
                                                                                </a>
                                                                                <a className={`flex gap-2 flex-1 text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                                                                    href={`https://wa.me/${bid.phone}?text=${encodeURIComponent(post.message)}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                                                                    </svg>
                                                                                    <span>{`Text`}</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                            )
                                        }
                                        )}

                                    </div>

                                    <div className='flex flex-col items-center gap-3 border-t p-4 bg-white border-black'>
                                        {tab_index === 0 && <input
                                            className=" p-4 relative w-full cursor-default overflow-hidden rounded-xl bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                                            placeholder="I'm in need of..."
                                            defaultValue={request}
                                            onChange={(e) => handleRequestChange(e)}
                                            disabled={!user}
                                            type='text' />}
                                        <div className='w-full flex justify-between'>
                                            {tab_index === 0 ? user ?
                                                <button
                                                    disabled={request === ''}
                                                    type="button"
                                                    className="rounded border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 hover:bg-green-2 disabled:text-gray-900 disabled:bg-gray-100"
                                                    onClick={handleCreatePost}
                                                >Post Request</button> :
                                                <button
                                                    type="button"
                                                    className="rounded border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 hover:bg-green-2 disabled:text-gray-900 disabled:bg-gray-100"
                                                    onClick={() => signInWithGoogle(() => { })}
                                                >Sign In to Request</button>
                                                : <></>
                                            }

                                            <button
                                                type="button"
                                                className="rounded border border-transparent px-4 py-2 text-sm font-medium  text-gray-900 bg-gray-100"
                                                onClick={() => setRequesting(false)}
                                            >Dismiss</button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={requesting} as={Fragment}>
                <Dialog as="div" className="relative z-10 " onClose={setRequesting}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0">
                        <div className="flex h-full items-center justify-center p-3">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="h-[90%] flex flex-col w-full max-w-md transform rounded-2xl overflow-clip shadow-xl transition-all" style={{ backgroundColor: 'rgba(238,233,221, 1)' }}>
                                    <div className='bg-white flex gap-4 shadow-md justify-center items-center'>
                                        {post_tabs.map(({ name }, idx) => {
                                            return (
                                                <button className={`${tab_index === idx ? 'bg-red-400 text-white ' : 'hover:bg-red-200 hover:text-white '} my-4 mx-1 rounded-full py-1 px-3 text-sm/6 font-semibold focus:outline-none focus:outline-1 focus:outline-red-900`}
                                                    onClick={() => setTabIndex(idx)}>
                                                    {name}
                                                </button>
                                            )
                                        })
                                        }
                                    </div>

                                    <div className='flex-1 overflow-auto flex flex-col gap-2 py-2 px-3 bg-gray-200'>

                                        {(tab_index === 0 ? userPosts : allPosts).map((post, idx) => {
                                            return (
                                                <div key={idx} className={`${(requestid === post.id) ? 'border-2 border-gray-800' : ''} rounded bg-gray-100 flex flex-col`}>
                                                    <button className={`flex px-2 py-3 shadow-md gap-1 justify-between items-center rounded  bg-white `}
                                                        onClick={() => {
                                                            handleRequestSelection((requestid === post.id) ? null : post.id)
                                                        }}>

                                                        <div className='flex flex-col justify-start items-start px-1'>
                                                            <span className='text-start text-sm font-light'>{post.message}</span>
                                                            <span className='text-[8px] font-semibold text-gray-500'>{post.date}</span>
                                                        </div>
                                                        <div className="inline-flex justify-center rounded-full  border-transparent  px-4 py-1">
                                                            {(requestid === post.id) ?
                                                                <ChevronUpIcon
                                                                    className="group pointer-events-none top-2.5 right-2.5 w-4 fill-black/60"
                                                                    aria-hidden="true"
                                                                /> :
                                                                <ChevronDownIcon
                                                                    className="group pointer-events-none top-2.5 right-2.5 w-4 fill-black/60"
                                                                    aria-hidden="true"
                                                                />}
                                                        </div>
                                                    </button>
                                                    {(requestid === post.id) &&
                                                        <div key={idx} className='flex flex-col w-full'>
                                                            {!(postBids.find((bid) => userOutlets.some(item => item.id === bid.vendorid))) &&
                                                                <div key={idx} className='flex flex-col py-2 gap-1 border m-2'>
                                                                    <span className='px-2 text-xs font-semibold'>{tab_index !== 0 ? 'Respond to the request! Choose Duka' : 'No bids yet.'}</span>
                                                                    {tab_index !== 0 && <div className='flex px-2 flex-1  overflow-x-auto no-scrollbar'>
                                                                        {userOutlets.map((outlet) => {
                                                                            return (
                                                                                <><button
                                                                                    onClick={() => { setSelectedOutlet((selectedOutlet?.id === outlet.id) ? null : outlet) }}
                                                                                    className={`${(selectedOutlet?.id === outlet.id) ? 'border-black font-bold' : 'font-light border-transparent'} border m-2  min-w-fit inline-flex justify-between items-center rounded-md bg-white gap-1 px-2 py-1 text-sm font-medium`}>
                                                                                    <img alt='' src={outlet.image} className={`w-6 h-6 border object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                                                                    <span className='my-0 text-xs text-center'>{`${outlet.title} (${outlet.active ? 'Listed' : 'Unlisted'})`}</span>
                                                                                </button>
                                                                                </>
                                                                            )
                                                                        })}
                                                                        <AddDuka user={user} text={'Add your duka'} />
                                                                    </div>}
                                                                    {(userOutlets.find((outlet) => selectedOutlet?.id === outlet.id)) &&
                                                                        <div className='flex flex-row items-center gap-2 px-1 py-2 border-t'>
                                                                            <input
                                                                                className=" px-4 py-2 w-full overflow-hidden rounded-lg bg-white text-left border sm:text-sm"
                                                                                placeholder='I will ...'
                                                                                defaultValue={reply}
                                                                                onChange={(e) => handleReplyChange(e)}
                                                                                type='text' />
                                                                            <button
                                                                                disabled={reply === ''}
                                                                                type="button"
                                                                                className="inline-flex min-w-fit justify-center rounded-md border bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 disabled:text-gray-900 disabled:bg-gray-100"
                                                                                onClick={() => handleCreateBid(post.id)}
                                                                            >Send</button>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            }

                                                            {
                                                                postBids.map((bid, idx) => {
                                                                    return (

                                                                        <div key={idx} className={`m-2 flex flex-col justify-between items-start rounded-md bg-white gap-1 px-2 text-sm font-medium`}>
                                                                            <div key={idx} className='flex flex-row w-full justify-between items-start gap-2 border-b pt-2 pb-1'>
                                                                                <img alt='' src={bid.image} className={`w-6 h-6 border object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                                                                <div className='flex-1 flex flex-col justify-start items-start'>
                                                                                    <span className='my-0 text-sm text-center'>{`${bid.name}`}</span>
                                                                                    <span className='my-0 text-[10px] text-center font-light text-red-600'>{`${bid?.dist * 5 < 60 ? `${(1 + bid?.dist * 5)?.toFixed(0) ?? '--'} minute${(1 + bid?.dist * 5)?.toFixed(0) === '1' ? '' : 's'} away` : `${((bid?.dist * 5) / 60)?.toFixed(0) ?? '--'} ${((bid?.dist * 5) / 60)?.toFixed(0) === '1' ? 'hour' : 'hrs'} away`}`}</span>
                                                                                </div>
                                                                                <span className='my-0 text-[8px] text-center text-gray-600'>{`${bid.date}`}</span>
                                                                            </div>
                                                                            <span className='text-start text-xs font-light  py-3 px-2'>{bid.message}</span>

                                                                            <div className='flex w-full flex-row gap-8 py-2 items-center justify-between'>
                                                                                <a className={`flex gap-2 flex-1 text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                                                                    href={`tel://${bid.phone}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                                                                                        <path fill-rule="evenodd" d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z" clip-rule="evenodd" />
                                                                                    </svg>
                                                                                    <span>{`Call`}</span>
                                                                                </a>
                                                                                <a className={`flex gap-2 flex-1 text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                                                                    href={`https://wa.me/${bid.phone}?text=${encodeURIComponent(post.message)}`}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                                                                    </svg>
                                                                                    <span>{`Text`}</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                            )
                                        }
                                        )}

                                    </div>

                                    <div className='flex flex-col items-center gap-3 border-t p-4 bg-white border-black'>
                                        {tab_index === 0 && <input
                                            className=" p-4 relative w-full cursor-default overflow-hidden rounded-xl bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
                                            placeholder="I'm in need of..."
                                            defaultValue={request}
                                            onChange={(e) => handleRequestChange(e)}
                                            disabled={!user}
                                            type='text' />}
                                        <div className='w-full flex justify-between'>
                                            {tab_index === 0 ? user ?
                                                <button
                                                    disabled={request === ''}
                                                    type="button"
                                                    className="rounded border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 hover:bg-green-2 disabled:text-gray-900 disabled:bg-gray-100"
                                                    onClick={handleCreatePost}
                                                >Post Request</button> :
                                                <button
                                                    type="button"
                                                    className="rounded border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 hover:bg-green-2 disabled:text-gray-900 disabled:bg-gray-100"
                                                    onClick={() => signInWithGoogle(() => { })}
                                                >Sign In to Request</button>
                                                : <></>
                                            }

                                            <button
                                                type="button"
                                                className="rounded border border-transparent px-4 py-2 text-sm font-medium  text-gray-900 bg-gray-100"
                                                onClick={() => setRequesting(false)}
                                            >Dismiss</button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            {user && <Transition appear show={isListing} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >{
                                    pos ? <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Welcome to Dukadash!
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div className='flex flex-col space-y-4'>
                                                <input placeholder='Item name' type='text' onChange={(e) => handleNameChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Item description' type='text' onChange={(e) => handleDescChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Reason for selling' type='text' onChange={(e) => handleReasonChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Selling price' type='number' onChange={(e) => handlePriceChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Phone Number' type='number' onChange={(e) => handlePhoneChange(e)}
                                                    className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />

                                                <label for="avatar">Upload photos of your item:</label>
                                                <div className=' flex flex-wrap'>

                                                    {files.length > 0 && files.map((file) => {
                                                        const image = URL.createObjectURL(file)
                                                        return (
                                                            <img alt='item_photo' src={image} className='w-16 md:w-24 h-16 md:h-24 object-cover object-center rounded m-1' loading='lazy' />
                                                        )
                                                    })}
                                                </div>
                                                <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                                                    onChange={handleFileChange} multiple />
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            {uploading ?
                                                <ReactLoading type="bubbles" color="black" />
                                                : <button
                                                    disabled={
                                                        title === ''
                                                        || phone === ''
                                                        || !pos
                                                        || (files.length === 0)
                                                        || reason === ''
                                                        || desc === ''
                                                        || !(price > 0)
                                                    }
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                    onClick={handleUpload}
                                                >
                                                    Create Listing
                                                </button>}
                                        </div>
                                    </Dialog.Panel>
                                        :
                                        <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                                            <ReactLoading type="bubbles" color="black" />
                                        </div>
                                }
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {<Transition appear show={listingid !== ''} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-green-100 p-6"
                                    > Users can only explore <b>ACTIVE</b> listings.<br />An Mpesa prompt will be sent to your phone number when you activate.
                                    </Dialog.Title>


                                    <div className='flex flex-col items-center justify-center py-8 px-4'>
                                        <label className=" text-xs">Listing Fee</label>
                                        <span className=' text-lg m-1 font-bold'>
                                            {new Intl.NumberFormat().format(charge)}/=
                                        </span>

                                        <label className="mt-4 mb-1 text-xs" >{"Confirm your number"}</label>
                                        <input placeholder='Whatsapp Number'
                                            type='tel'
                                            onChange={(e) => handleMSISDNChange(e)}
                                            name="msisdn"
                                            defaultValue={`+${msisdn === '' ? phone : msisdn}`}
                                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                        />
                                    </div>

                                    <div className="flex items-center justify-around m-4">
                                        {(prompting) ?
                                            (waiting) ?
                                                <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                                :
                                                <div className="sm:m-4 flex flex-col justify-center items-center">
                                                    <span>{`welcome 🙂`}</span>
                                                </div>
                                            :
                                            <>
                                                <button onClick={() => { setListingID('') }}
                                                    className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                                                    Dismiss
                                                </button>
                                                <button
                                                    disabled={msisdn === ''}
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        SendPayload({
                                                            amount: charge,
                                                            msisdn: msisdn,
                                                            account_id: listingid
                                                        }, { setPrompting, setWaiting })
                                                    }}
                                                >Activate
                                                </button>
                                            </>
                                        }
                                    </div>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {<Transition appear show={prelovedpopup !== null} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6"
                                    > {prelovedpopup?.caption}</Dialog.Title>

                                    <div className=' max-h-[60vh] overflow-auto px-4 bg-red-900'>
                                        <ImageList variant="masonry" sx={{ marginTop: '16px' }} cols={1} gap={8}>
                                            {prelovedpopup?.thumb?.map((item) => (
                                                <ImageListItem key={item}>
                                                    <img
                                                        className='rounded-lg'
                                                        src={`${item}?w=248&fit=crop&auto=format`}
                                                        srcSet={`${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                        alt={item.title}
                                                        loading="lazy"
                                                    />
                                                </ImageListItem>
                                            ))}
                                        </ImageList>
                                    </div>
                                    <div className='p-4 flex justify-around items-center'>

                                        {prelovedpopup?.phone && <a href={`tel:${prelovedpopup.phone}`}
                                            className="inline-flex justify-center  border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                        >
                                            Call Seller
                                        </a>}

                                        <button
                                            onClick={closeModal}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                        >Close</button>
                                    </div>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}


            <Transition appear show={pickup} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setPickup}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0">
                        <div className="flex flex-col h-full px-3 items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >{
                                    pos ?
                                        <Dialog.Panel className="flex flex-col w-full max-h-[90vh] max-w-md transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 px-6" >Dukadashers near you!</Dialog.Title>

                                            <div className='bg-gray-200 flex flex-col flex-1 gap-2 justify-between items-stretch w-full h-full border-t border-b my-4 px-3 py-2 overflow-y-auto'>
                                                {

                                                    partners.map((deliver, idx) => {
                                                        return (

                                                            <div key={idx} className={`border shadow-md rounded p-2 flex flex-col justify-between items-start bg-white gap-1 px-2 text-sm font-medium`}>
                                                                <div key={idx} className='flex flex-row w-full justify-between items-start gap-2  pt-y pb-1'>
                                                                    <img alt='' src={deliver.image} className={`w-6 h-6 border object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                                                    <div className='flex-1 flex flex-col justify-start items-start'>
                                                                        <span className={`my-0 text-sm text-start font-semibold ${(deliver?.dist) > 1 ? 'text-red-600' : 'text-green-600'}`}>{`${deliver.name} - ${(deliver?.dist)} km from you`}</span>
                                                                        <span className={`my-0 text-[8px] text-center font-semibold`}>Updated <ReactTimeago date={deliver.updated} minPeriod={60} /></span>
                                                                        {/* <span className={`my-0 text-[8px] text-center font-semibold text-gray-600`}>{`Vehicle: ${deliver.vehicle.name}`}</span> */}
                                                                    </div>
                                                                    <div className={`flex flex-col justify-start items-end`}>
                                                                        <span className='my-0 text-xs text-center'></span>
                                                                        <span className='my-0 text-xs text-center font-semibold'>{`${new Intl.NumberFormat().format(base + (deliver.price * deliver.dist))}/=`}</span>
                                                                    </div>
                                                                </div>
                                                                <span className='text-start text-xs font-light my-2 px-2 max-w-full line-clamp-3 hover:line-clamp-none'>{deliver.vehicle.name}</span>

                                                                <div className='flex w-full flex-row gap-8 py-2 px-8 items-center justify-center'>
                                                                    <a className={`flex gap-2 flex-1 text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                                                        href={`tel://${deliver.phone}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                                                                            <path fill-rule="evenodd" d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <span>{`Call`}</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </div>


                                            <div className='flex flex-row px-6 justify-between items-center gap-3 bg-white border-black'>

                                                <button
                                                    type="button"
                                                    className="rounded border border-transparent px-4 py-2 text-sm font-medium  text-gray-900 bg-gray-100"
                                                    onClick={() => setPickup(false)}
                                                >Dismiss</button>
                                            </div>
                                        </Dialog.Panel>
                                        :
                                        <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                                            <ReactLoading type="bubbles" color="black" />
                                        </div>
                                }
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </div>
    )
}

export default Home