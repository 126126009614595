import React, { useEffect, useState } from 'react'
import { sleep, validatePhoneNumber } from '../helpers';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import ReactLoading from 'react-loading';
import { signInWithGoogle } from '../firebaseConfig';

const ToktnUp = ({ user, setToppingUp }) => {
    const min = 50
    const [prompting, setPrompting] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [msisdn, setmsisdn] = useState('')
    const [amount, setAmount] = useState(100)
    const [donating, setDonating] = useState(false)

    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);

        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }
    const handleAmountChange = (event) => {
        const a = event.target.value
        setAmount(parseInt(a))
    }

    const SendPayload = async (payload, { setPrompting, setWaiting }) => {

        console.log(payload)

        if (payload.amount === '' || payload.msisdn === '') {
            alert("Missing info")
        } else {
            setPrompting(true);
            setWaiting(true);
            const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
            const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=replies/${payload.account_id}`;
            fetch(url, {
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then(async (data) => {
                    console.log(data)

                    if (data.success) {
                        console.log('ok')


                        // await prelovedUpdate(payload.account_id, { active: true })
                    } else {

                    };
                    await sleep(15000)
                    setWaiting(false)
                    setPrompting(false)
                    setToppingUp(false)

                })
                .catch((error) => {
                    console.error('Error:', error)
                    setPrompting(false)
                    setWaiting(false)
                    alert(error)
                });
        }
    }

    useEffect(() => {
        setmsisdn(user?.phone)
    }, [user])

    return (
        <>
            {
                <div className="w-full flex flex-col">


                    <button
                        className="w-full relative bg-green-100 h-10 space-x-8 px-4"
                        onClick={() => {
                            setDonating(!donating)
                        }}
                    >
                        <span className=" absolute top-0 bottom-0 left-0 right-0 text-[11px] text-center text-gray-900 py-3">Minimum token amount is {min}/=</span>

                       
                    </button>

                    <div className='flex flex-col items-center justify-center py-2'>
                        <div className='flex flex-row items-center justify-center px-4'>
                            <input placeholder='Amount'
                                type='number'
                                onChange={(e) => handleAmountChange(e)}
                                name="amount"
                                defaultValue={`${amount}`}
                                className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${amount < min ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                            />
                        </div>
                    </div>

                    {user ?
                        <>
                            <div className='flex flex-col items-center justify-center px-4'>
                                <span className=' text-lg m-1 font-bold'>

                                </span>

                                {/* {msisdn !== '' && <label className="mt-4 mb-1 text-xs" >{"Confirm your number"}</label>} */}
                                <input placeholder='Mpesa Number'
                                    type='tel'
                                    onChange={(e) => handleMSISDNChange(e)}
                                    name="msisdn"
                                    defaultValue={`${msisdn ?? ''}`}
                                    className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                />
                            </div>

                            <div className="flex items-center justify-around m-4">
                                {(prompting) ?
                                    (waiting) ?
                                        <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                        :
                                        <div className="sm:m-4 flex flex-col justify-center items-center">
                                            <span>{`welcome 🙂`}</span>
                                        </div>
                                    :
                                    <>
                                        <button
                                            disabled={msisdn === '' || amount < min}
                                            className="inline-flex justify-center rounded-md border border-transparent bg-green-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                SendPayload({
                                                    amount: amount,
                                                    msisdn: msisdn,
                                                    account_id: user?.uid
                                                }, { setPrompting, setWaiting })
                                            }}
                                        >Replenish</button>
                                    </>
                                }
                            </div>
                        </>
                        :
                        <button
                            className="inline-flex m-6 justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={() => { signInWithGoogle() }}
                        >Sign in to TopUp!</button>
                    }
                </div>
            }
        </>
    )
}

export default ToktnUp