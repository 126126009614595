import { doc } from 'firebase/firestore'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useDocument } from 'react-firebase-hooks/firestore'
import { COLLECTIONS, addSharable, addToCollection, db, getShared, getUser, getUserShares, signInWithGoogle, updateUser } from '../firebaseConfig'
import { getMoment, shorten, sleep, validatePhoneNumber } from '../helpers'
import ImageToLink from '../components/ImageToLink'
import ReactLoading from 'react-loading';
import { Alert, Collapse } from '@mui/material'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Dialog, Transition } from '@headlessui/react'
import { useParams } from 'react-router-dom'

const WhatsappEverClear = ({ user }) => {
    const params = useParams();
    const max = 10
    const mom = getMoment()
    const periods = [
        { key: 'day', days: 1, min: 50, moment: 1 },
        { key: 'week', days: 7, min: 299, moment: 1 },
        { key: 'month', days: 28, min: 999, moment: 1 },
        // { key: 'year', days: (28 * 13), min: 4999, moment: 1 },
    ]
    const [dominantColor, setDominant] = useState('')
    const [link, setLink] = useState(null)
    const [adding, setAdding] = useState(null)
    const [shared, setShared] = useState(null)
    const [shareCopy, setShareCopy] = useState(null)
    const [shares, setShares] = useState([])
    const [copied, setCopy] = useState(false)
    const [creating, setCreating] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [shareLoaded, setShareLoaded] = useState(false)
    const [name, setName] = useState(`${mom.date} ${mom.time}`)
    const [caption, setCaption] = useState('curated with 🤍 using - photo reels')
    const [desc, setDesc] = useState('curated with 🤍 using - photo reels')
    const [files, setFiles] = useState([])



    const closeListing = () => {
        setUploading(false)
        setFiles([])
    }
    const handleNameChange = (event) => {
        setName(event.target.value);
    }
    const handleCaptionChange = (event) => {
        setCaption(event.target.value);
        setDesc(event.target.value);
    }
    const handleFileChange = (event) => {
        const newFiles = event.target.files;
        setFiles([...files, ...newFiles].slice(0, max));
        getDominantColor(newFiles[0])
            .then(dominantColor => {
                console.log("Dominant color:", dominantColor);
            })
            .catch(error => {
                console.error("Error getting dominant color:", error);
            });
    }
    const handleFileAdd = (event) => {
        const newFiles = event.target.files;
        setFiles([...files, ...newFiles].slice(0, max));
        getDominantColor(newFiles[0])
            .then(dominantColor => {
                console.log("Dominant color:", dominantColor);
            })
            .catch(error => {
                console.error("Error getting dominant color:", error);
            });
    }
    const handleShareClose = () => {
        setCopy(false);
        closeListing()
        closeModal()
    }
    const handleShareCopy = () => {
        setCopy(true);
    }
    const openModal = () => {
        setUploading(false)
        setLink(null)
        setCopy(false)
    }
    const closeModal = () => {
        setCreating(false)
        setFiles([])
        setAdding(null)
        setUploading(false)
        setLink(null)
    }
    const closeAddModal = () => {
        setAdding(null)
        setFiles([])
        setUploading(false)
    }
    const getDominantColor = async (file) => {
        // Create an object URL for the image (temporary URL)
        const url = URL.createObjectURL(file);

        // Create a new image element
        const image = new Image();
        image.src = url;

        // Wait for the image to load asynchronously using await
        try {
            await new Promise((resolve, reject) => {
                image.onload = resolve;
                image.onerror = reject;
            });
        } catch (error) {
            console.error("Error loading image:", error);
            URL.revokeObjectURL(url); // Clean up even on error
            throw error; // Re-throw the error for further handling (optional)
        }


        // Create a hidden canvas element
        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = 1;
        const context = canvas.getContext('2d');

        // Draw the image onto the canvas (scaled down to a single pixel)
        context.drawImage(image, 0, 0);

        // Get the image data for the single pixel
        const imageData = context.getImageData(0, 0, 1, 1).data;

        // Extract the RGB values
        const red = imageData[0];
        const green = imageData[1];
        const blue = imageData[2];

        // Convert RGB to HEX (adapted for readability)
        const hex = "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).padStart(6, '0');
        setDominant(hex)
        return hex.toUpperCase();
    }
    const handleUpload = async () => {
        setUploading(true)

        if (files.length > 0) {
            const data = {
                hits: 0,
                title: name,
                uid: user?.uid ?? '',
                menuid: '',
                phone: user_data?.phone ?? '',
                description: desc,
                caption: caption,
                dominantColor: dominantColor
            }
            const link = await addSharable(files, data, closeModal)
            setLink(link)
        } else {
            setUploading(false)
            console.log('no file')
        }
    }

    const [period, setPeriod] = useState(periods[0])
    const [user_data, setUser] = useState(null)
    const [prompting, setPrompting] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [msisdn, setmsisdn] = useState('')

    const [value, loading, error] = useDocument(doc(db, COLLECTIONS.CONSTS, "app"), { snapshotListenOptions: { includeMetadataChanges: true } });

    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);

        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }

    const handlePeriod = (p) => {
        setPeriod(p)
    }

    const SendPayload = async (payload, { setPrompting, setWaiting }) => {

        console.log(payload)

        if (payload.amount === '' || payload.msisdn === '') {
            alert("Missing info")
        } else {
            setPrompting(true);
            setWaiting(true);
            const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
            const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=subscription/${payload.account_id}`;
            fetch(url, {
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then(async (data) => {
                    console.log(data)

                    if (data.success) {
                        console.log('ok')
                        await updateUser(payload.account_id, { phone: payload.msisdn })
                    } else {

                    };
                    await sleep(12000)
                    setWaiting(false)
                    setPrompting(false)

                })
                .catch((error) => {
                    console.error('Error:', error)
                    setPrompting(false)
                    setWaiting(false)
                    alert(error)
                });
        }
    }

    const [isDragging, setIsDragging] = useState(false)
    const fileInputRef = useRef(null)
    const addFileRef = useRef(null)

    const handleFiles = (newfiles) => {
        console.log('vbbc')
        if ((newfiles.length === 0)) return
        [...newfiles].forEach((file) => {
            file.type?.includes('image') && getDominantColor(newfiles[0])
                .then(dominantColor => {
                    console.log("Dominant color:", dominantColor);
                })
                .catch(error => {
                    console.error("Error getting dominant color:", error);
                });

        });
        setFiles([...files, ...([...newfiles].filter((f) => f.type?.includes('image')))].slice(0, max))

    }

    const onDragOver = (e) => {
        e.preventDefault()
        setIsDragging(true)
    }
    const onDragLeave = (e) => {
        e.preventDefault()
        setIsDragging(false)
    }
    const onDrop = (e) => {
        e.preventDefault()
        setIsDragging(false)
        const files = e.dataTransfer.files
        handleFiles(files)
    }
    const handleSaveCopy = async (id) => {
        setShareCopy(null)
        setCopy(false)
        const lnk = `https://dukadash.com/photos/${id}`//await shorten(`https://us-central1-autosoft-614e7.cloudfunctions.net/socialShare/share/${id}`)
        setLink(lnk)
        console.log(id)
        setShareCopy(id)

    }

    const handleAdd = async () => {

        setUploading(true)

        if (files.length > 0 && adding) {
            const data = {
                id: adding.id,
                uid: adding.uid,
                size: adding.images?.length ?? 0,
            }
            console.log('adding', data)
            await addToCollection(files, data, closeAddModal)
        } else {
            setUploading(false)
            console.log('no file or item')
        }

    }

    const handleUser = (user) => {
        // console.log(user)
        setUser(user)
        setmsisdn(user?.phone)
    }


    useEffect(() => {
        params.id && !shared && getShared(params.id, setShared, setShares, setShareLoaded)
    }, [params, shared])

    useEffect(() => {
        user && getUser(user?.uid, handleUser).then((unsub) => { })
        user && getUserShares(user?.uid, setShares).then((unsub) => { })

    }, [user])
    return (
        <div className='flex flex-col space-y-4 justify-around items-center pt-24 pb-16' style={{ backgroundColor: '#00000000' }}
            onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
            {isDragging &&
                <div className='absolute z-20 top-0 left-0 right-0 bottom-0 bg-white bg-opacity-80'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute top-0 right-0">
                        <path fillRule="evenodd" d="M14.78 5.22a.75.75 0 00-1.06 0L6.5 12.44V6.75a.75.75 0 00-1.5 0v7.5c0 .414.336.75.75.75h7.5a.75.75 0 000-1.5H7.56l7.22-7.22a.75.75 0 000-1.06z" clipRule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute top-0 left-0">
                        <path d="M6.28 5.22a.75.75 0 00-1.06 1.06l7.22 7.22H6.75a.75.75 0 000 1.5h7.5a.747.747 0 00.75-.75v-7.5a.75.75 0 00-1.5 0v5.69L6.28 5.22z" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute bottom-0 right-0">
                        <path fillRule="evenodd" d="M14.78 14.78a.75.75 0 01-1.06 0L6.5 7.56v5.69a.75.75 0 01-1.5 0v-7.5A.75.75 0 015.75 5h7.5a.75.75 0 010 1.5H7.56l7.22 7.22a.75.75 0 010 1.06z" clipRule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-20 h-20 absolute bottom-0 left-0">
                        <path fillRule="evenodd" d="M5.22 14.78a.75.75 0 001.06 0l7.22-7.22v5.69a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75h-7.5a.75.75 0 000 1.5h5.69l-7.22 7.22a.75.75 0 000 1.06z" clipRule="evenodd" />
                    </svg>

                    <div className='w-full h-full flex justify-center items-center space-x-4'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-28 h-28">
                            <path fillRule="evenodd" d="M5.5 17a4.5 4.5 0 01-1.44-8.765 4.5 4.5 0 018.302-3.046 3.5 3.5 0 014.504 4.272A4 4 0 0115 17H5.5zm3.75-2.75a.75.75 0 001.5 0V9.66l1.95 2.1a.75.75 0 101.1-1.02l-3.25-3.5a.75.75 0 00-1.1 0l-3.25 3.5a.75.75 0 101.1 1.02l1.95-2.1v4.59z" clipRule="evenodd" />
                        </svg>

                        <span className='font-bold text-4xl'>Drop files anywhere</span>
                    </div>



                </div>}

            <button onClick={() => setCreating(true)}
                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            >Create collection
            </button>
            {shareLoaded ? <>
                {
                    params.id && shared &&

                    <>

                        <span className="text-center py-4 px-2 text-black w-full max-w-md font-light text-sm rounded-2xl">{shared.title}</span>
                        {/* <span className="text-center px-2 text-black w-full max-w-md font-semibold text-xs rounded-2xl">{shared.title}</span> */}
                        <div className="w-full max-w-[400px] flex flex-col justify-center items-center px-2 space-y-4">
                            {shared.images.map((img, idx) => {
                                return (
                                    <div className='flex flex-col justify-center items-center space-y-1'>
                                        <img key={idx}
                                            className="w-full h-auto rounded-2xl my-0 border border-white bg-slate-300 object-center object-cover"
                                            src={img.url}
                                            alt=""
                                            loading="lazy" />
                                            <span className='text-[8px] text-center font-semibold bg-white bg-opacity-80 rounded-xl px-2 py-1'>{img.caption ?? ''}</span>
                                    </div>
                                )
                            })}

                            <div className='flex items-center justify-center space-x-1'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-3 h-3">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                                    <path fillRule="evenodd" d="M1.38 8.28a.87.87 0 0 1 0-.566 7.003 7.003 0 0 1 13.238.006.87.87 0 0 1 0 .566A7.003 7.003 0 0 1 1.379 8.28ZM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
                                </svg>
                                <span className='text-xs font-semibold'>{shared.hits ?? 0} {shared.hits === 1 ? 'view' : `views`}</span>
                            </div>
                        </div>
                    </>
                }

                {params.id && shared && shares &&

                    <div className='flex flex-col w-full max-w-md py-16 px-8'>

                        {shares.length > 0 && <span className=' font-semibold text-lg '>{`More collections ..`}</span>}
                        {
                            shares.filter((s) => s.id !== params.id).map((share, idx) => {
                                const is_user = user?.uid === share.uid
                                const lim = is_user ? 3 : 4
                                return (
                                    <div key={share.id} className=' max-w-full w-full flex flex-col items-start border-b border-black py-4 space-x-2 h-full'>
                                        <div className='flex justify-between w-full'>
                                            <a href={`https://dukadash.com/photos/${share.id}`}
                                                className={`${share.dominantColor ? `bg-[${share.dominantColor}]` : ''} flex max-w-full overflow-hidden`}>

                                                {share.images?.slice(0, lim)?.map((image, idx) => <img alt='item_photo' key={idx} src={image.url}
                                                    className='w-12 h-12 md:w-20 md:h-20 m-1 bg-slate-500 object-cover object-center rounded'
                                                    loading='lazy' />
                                                )}
                                                {share.images?.length > lim && <div className='w-12 h-12 md:w-20 md:h-20 m-1 flex justify-center items-center rounded'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                                    </svg>

                                                </div>}
                                            </a>
                                            {is_user &&
                                                <button className={`w-12 h-12 md:w-20 md:h-20 m-1 bg-white text-red-900 flex justify-center items-center rounded`}
                                                    onClick={() => {
                                                        setAdding(share)
                                                    }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                    </svg>
                                                </button>}
                                        </div>

                                        <div className='flex flex-col flex-1 items-start space-y-1 justify-between h-full'>

                                            <span className='text-xs line-clamp-2 text-ellipsis'>{share.title}</span>
                                            <div className='flex items-center justify-center space-x-1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-3 h-3">
                                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                                                    <path fillRule="evenodd" d="M1.38 8.28a.87.87 0 0 1 0-.566 7.003 7.003 0 0 1 13.238.006.87.87 0 0 1 0 .566A7.003 7.003 0 0 1 1.379 8.28ZM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
                                                </svg>
                                                <span className='text-xs font-semibold'>{share.hits ?? 0} {share.hits === 1 ? 'view' : `views`}</span>
                                            </div>
                                        </div>

                                        <div className='flex flex-col justify-center h-full flex-1 mt-4 max-w-[60%]'>
                                            {shareCopy === share.id ? <CopyToClipboard
                                                text={link}
                                                onCopy={() => setCopy(true)}>
                                                <button
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                >Click to copy and share link
                                                </button>
                                            </CopyToClipboard> :
                                                <button onClick={() => handleSaveCopy(share.id)}
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                >Generate sharable link
                                                </button>}
                                            <Collapse in={(shareCopy === share.id) && copied}>
                                                <Alert severity="success" sx={{ mt: 1 }}>
                                                    {`Link Copied- ${link}`}
                                                </Alert>
                                            </Collapse>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                }</>
                :
                params.id && <div className='flex w-full justify-center items-center'>
                    <ReactLoading type="bubbles" color="black" />
                </div>
            }


            {user && !params.id && shares &&
                <div className='flex flex-col w-full max-w-md px-8'>
                    {
                        shares.map((share, idx) => {
                            const is_user = user?.uid === share.uid
                            const lim = is_user ? 3 : 4
                            return (
                                <div key={share.id} className=' max-w-full w-full flex flex-col items-start border-b border-black py-4 space-x-2 h-full'>
                                    <div className='flex justify-between w-full'>
                                        <a href={`https://dukadash.com/photos/${share.id}`}
                                            className={`${share.dominantColor ? `bg-[${share.dominantColor}]` : ''} flex max-w-full overflow-hidden`}>

                                            {share.images?.slice(0, lim)?.map((image, idx) => <img alt='item_photo' key={idx} src={image.url}
                                                className='w-12 h-12 md:w-20 md:h-20 m-1 bg-slate-500 object-cover object-center rounded'
                                                loading='lazy' />
                                            )}
                                            {share.images?.length > lim && <div className='w-12 h-12 md:w-20 md:h-20 m-1 flex justify-center items-center rounded'>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
                                                </svg>

                                            </div>}
                                        </a>
                                        {is_user &&
                                            <button className={`w-12 h-12 md:w-20 md:h-20 m-1 bg-white text-red-900 flex justify-center items-center rounded`}
                                                onClick={() => {
                                                    setAdding(share)
                                                }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                                </svg>
                                            </button>}
                                    </div>

                                    <div className='flex flex-col flex-1 items-start space-y-1 justify-between h-full'>
                                        <span className='text-xs line-clamp-2 text-ellipsis'>{share.title}</span>
                                        <div className='flex items-center justify-center space-x-1'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-3 h-3">
                                                <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
                                                <path fillRule="evenodd" d="M1.38 8.28a.87.87 0 0 1 0-.566 7.003 7.003 0 0 1 13.238.006.87.87 0 0 1 0 .566A7.003 7.003 0 0 1 1.379 8.28ZM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" clipRule="evenodd" />
                                            </svg>
                                            <span className='text-xs font-semibold'>{share.hits ?? 0} {share.hits === 1 ? 'view' : `views`}</span>
                                        </div>
                                    </div>

                                    <div className='flex flex-col justify-center h-full flex-1 mt-4 max-w-[60%]'>
                                        {shareCopy === share.id ? <CopyToClipboard
                                            text={link}
                                            onCopy={() => setCopy(true)}>
                                            <button
                                                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                            >Click to copy and share link
                                            </button>
                                        </CopyToClipboard> :
                                            <button onClick={() => handleSaveCopy(share.id)}
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            >Generate sharable link
                                            </button>}
                                        <Collapse in={(shareCopy === share.id) && copied}>
                                            <Alert severity="success" sx={{ mt: 1 }}>
                                                {`Link Copied- ${link}`}
                                            </Alert>
                                        </Collapse>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            }

            {<Transition appear show={creating} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { setCreating(false) }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    {/* <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6"
                                    > {`adding?.title`}</Dialog.Title> */}
                                    {user_data?.active ?
                                        <div className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                            <p
                                                as="h3"
                                                className="text-lg font-medium leading-6 text-gray-900 p-4"
                                            >
                                                Photo-reel creator
                                            </p>
                                            <div className="mt-2">
                                                <div className='flex flex-col space-y-4'>

                                                    {<span className=' text-white text-xs text-center bg-orange-400  rounded border py-2 px-4'>{`Share multiple photos in a photo reel. It's pretty neat!`}</span>}
                                                    <input placeholder='Enter title' type='text' onChange={(e) => () => { }}
                                                        className=" p-4 relative hidden w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                    <div className='w-full py-4 flex flex-col justify-center items-center border-green-400 border-dashed border-2'>
                                                        <div className=' flex flex-wrap px-2'>

                                                            {files.length > 0 && files.map((file, idx) => {
                                                                const image = URL.createObjectURL(file)
                                                                return (
                                                                    <div key={idx}>
                                                                        <img alt='item_photo' src={image}
                                                                            className='w-16 md:w-24 h-16 md:h-24 object-cover object-center rounded m-1'
                                                                            loading='lazy' />
                                                                        <button onClick={() => {
                                                                            const remFiles = files.filter((f) => f !== file)
                                                                            setFiles([...remFiles])
                                                                        }}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                                                                <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clip-rule="evenodd" />
                                                                            </svg>

                                                                        </button>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                        <button onClick={() => { fileInputRef.current.click() }}
                                                            className='py-4 px-8 bg-green-400 text-white'>Select photos ({max} Max)</button>

                                                        <input className='bg-white hidden' type="file" accept="image/*" id="avatar" name="avatar"
                                                            ref={fileInputRef}
                                                            max={4}
                                                            onChange={handleFileChange} multiple={true} />
                                                    </div>
                                                    <input placeholder='Enter caption' type='text' onChange={(e) => handleCaptionChange(e)}
                                                        className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />


                                                    <Collapse in={copied && !shareCopy}>
                                                        <Alert severity="success" sx={{ mb: 2 }}>
                                                            {`Link copied! ${link}`}
                                                        </Alert>
                                                    </Collapse>
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                {

                                                    (link && !uploading) ?
                                                        <div className="flex items-center justify-between mt-4">
                                                            <button onClick={handleShareClose}
                                                                className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                                                                Dismiss
                                                            </button>
                                                            <CopyToClipboard
                                                                text={link}
                                                                onCopy={handleShareCopy}>
                                                                <button
                                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                                >Copy to clipboard
                                                                </button>
                                                            </CopyToClipboard>
                                                        </div>
                                                        : uploading ?
                                                            <ReactLoading type="bubbles" color="black" />

                                                            : <div className='flex flex-col space-y-4 justify-between items-center'>
                                                                {!user ? <span className=' text-red-600 text-xs'>{`Sign in and subscribe below to start`}</span> :
                                                                    (name === ''
                                                                        || files.length === 0
                                                                        || desc === '') ? <span className=' text-blue-600 text-xs'>{`Upload your photos & enter caption above`}</span>
                                                                        : !user_data?.active ? <span className=' text-green-600 text-xs'>{`Almost there! Subscribe below`}</span> : <></>}
                                                                <button
                                                                    disabled={
                                                                        (!user_data?.active ?? true) ||
                                                                        name === ''
                                                                        || files.length === 0
                                                                        || desc === ''
                                                                    }
                                                                    type="button"
                                                                    className="inline-flex justify-center rounded-md border disabled:bg-gray-300 disabled:text-gray-900 border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                                    onClick={handleUpload}
                                                                >
                                                                    Create Reel
                                                                </button>
                                                            </div>
                                                }
                                            </div>
                                        </div>
                                        :
                                        //!user && 
                                        <div className="w-full flex flex-col  max-w-md transform overflow-hidden rounded-2xl align-middle shadow-xl transition-all">
                                            <div
                                                className="leading-6 text-sm text-gray-900 text-center bg-green-100 px-6 py-3"
                                            >
                                                {/* Login/Create your account */}
                                                Pick your <b>subscription</b> duration!
                                                <br />
                                                {/* <span className="text-xs">No delivery fees</span> */}
                                            </div>
                                            <div className='flex flex-col items-center justify-center py-4'>
                                                <label className=" text-xs">
                                                    <b>{`${period.moment} ${period.key}${period.key === 'month' ? '' : period.key === 'year' ? '' : ''}`}</b></label>
                                                <div className='flex flex-row flex-wrap items-center justify-center px-4'>
                                                    {periods.map((p, idx) => <button key={idx}
                                                        className={`inline-flex justify-center rounded-md border border-transparent ${p.key === period.key ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                                                        onClick={() => handlePeriod(p)}
                                                    >
                                                        {new Intl.NumberFormat().format(p.min)}/=
                                                    </button>)}
                                                </div>
                                            </div>
                                            {user ?
                                                <>
                                                    <div className='flex flex-col items-center justify-center px-4'>
                                                        <span className=' text-lg m-1 font-bold'>

                                                        </span>

                                                        {/* {msisdn !== '' && <label className="mt-4 mb-1 text-xs" >{"Confirm your number"}</label>} */}
                                                        <input placeholder='Mpesa Number'
                                                            type='tel'
                                                            onChange={(e) => handleMSISDNChange(e)}
                                                            name="msisdn"
                                                            defaultValue={`${msisdn}`}
                                                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                                        />
                                                    </div>

                                                    <div className="flex items-center justify-around m-4">
                                                        {(prompting) ?
                                                            (waiting) ?
                                                                <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                                                :
                                                                <div className="sm:m-4 flex flex-col justify-center items-center">
                                                                    <span>{`welcome 🙂`}</span>
                                                                </div>
                                                            :
                                                            <>
                                                                <button
                                                                    disabled={!((value?.data()?.["fee"] !== null) && msisdn !== '')}
                                                                    className="inline-flex justify-center rounded-md border border-transparent disabled:text-gray-900 bg-green-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                                    onClick={() => {
                                                                        SendPayload({
                                                                            amount: period.min,
                                                                            msisdn: msisdn,
                                                                            account_id: user?.uid
                                                                        }, { setPrompting, setWaiting })
                                                                    }}
                                                                >Subscribe</button>
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <button
                                                    className="inline-flex m-6 justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium disabled:bg-gray-300 text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                    onClick={() => { signInWithGoogle() }}
                                                >{period.min === 0 ? 'Get Started! Sign In' : 'Sign in to subscribe'}</button>
                                            }
                                        </div>
                                    }

                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {<Transition appear show={adding !== null} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => { }}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-[#eee9dd80] p-6"
                                    > {adding?.title}</Dialog.Title>

                                    <div className=' max-h-[60vh] overflow-auto px-4 bg-white py-8'>
                                        <div className='w-full py-4 flex flex-col justify-center items-center border-green-400 border-dashed border-2'>
                                            <div className=' flex flex-wrap px-2'>

                                                {files.length > 0 && files.map((file, idx) => {
                                                    const image = URL.createObjectURL(file)
                                                    return (
                                                        <div key={idx}>
                                                            <img alt='item_photo' src={image}
                                                                className='w-16 md:w-24 h-16 md:h-24 object-cover object-center rounded m-1'
                                                                loading='lazy' />
                                                            <button onClick={() => {
                                                                const remFiles = files.filter((f) => f !== file)
                                                                setFiles([...remFiles])
                                                            }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
                                                                    <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16ZM8.28 7.22a.75.75 0 0 0-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 1 0 1.06 1.06L10 11.06l1.72 1.72a.75.75 0 1 0 1.06-1.06L11.06 10l1.72-1.72a.75.75 0 0 0-1.06-1.06L10 8.94 8.28 7.22Z" clip-rule="evenodd" />
                                                                </svg>

                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <button onClick={() => { addFileRef.current.click() }}
                                                className='py-4 px-8 bg-green-400 text-white'>Select photos</button>

                                            <input className='bg-white hidden' type="file" accept="image/*" id="avatar" name="avatar"
                                                ref={addFileRef}
                                                onChange={handleFileAdd} multiple={true} />
                                        </div>
                                    </div>
                                    {uploading ? <div className='flex justify-center items-center'>
                                        <ReactLoading type="bubbles" color="black" />
                                    </div>

                                        : <div className='p-4 flex justify-around items-center'>

                                            <button
                                                onClick={handleAdd}
                                                className="inline-flex justify-center  border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                            >
                                                Upload
                                            </button>

                                            <button
                                                onClick={closeAddModal}
                                                className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                            >Close</button>
                                        </div>}
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

        </div>
    )
}

export default WhatsappEverClear