import React from 'react'
import { useEffect } from 'react'
import { addListing, getLoved, getMyLoved, getSaves, saveUnsave } from '../firebaseConfig'
import { useState } from 'react'
import PreLovedListing from '../components/PreLovedListing'
import ReactLoading from 'react-loading';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { handleSignIn, sleep, validatePhoneNumber } from '../helpers'

const Preloved = ({prod, user}) => {
    const charge = 350

    const [uid, setUID] = useState(null)
    const [myloved, setMyLoved] = useState([])
    const [loved, setLoved] = useState([])
    const [search, setSearch] = useState('')
    const [toggleSearch, setToggle] = useState(false)
    const [saves, setSaves] = useState([])
    const [uploading, setUploading] = useState(false)
    const [isListing, setIsListing] = useState(false)
    const [title, setTitle] = useState('')
    const [desc, setDesc] = useState('')
    const [reason, setReason] = useState('')
    const [price, setPrice] = useState(null)
    const [phone, setPhone] = useState('')
    const [percent, setPercent] = useState(0)
    const [files, setFiles] = useState([])
    const [dominantColor, setDominant] = useState('')


    const [prompting, setPrompting] = useState(false);
    const [waiting, setWaiting] = useState(false);
    const [msisdn, setmsisdn] = useState('');
    const [listingid, setListingID] = useState('');
    const [pos, setPos] = useState(null)

    const handleLocation = async () => {
        //console.log(pos)
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: parseFloat(position.coords.latitude),
                        lng: parseFloat(position.coords.longitude),
                    }

                    setPos(pos)

                },
                (e) => {
                    console.log("Browser doesn't support Geolocation", e)
                    // handleLocationError(true, infoWindow, map.getCenter());
                }
            );
        } else {
            console.log("Browser doesn't support Geolocation")
            // Browser doesn't support Geolocation
            // handleLocationError(false, infoWindow, map.getCenter());
        }
    }

    const closeModal = () => {
        setSearch('')
        setToggle(false)
        setIsListing(false)
        setListingID('')
    }
    const handleMSISDNChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);

        if (result.result !== "invalid") {
            setmsisdn(result.result);
        } else {
            setmsisdn('')
        }
    }

    const SendPayload = async (payload, { setPrompting, setWaiting }) => {

        console.log(payload)

        if (payload.amount === '' || payload.msisdn === '') {
            alert("Missing info")
        } else {
            setPrompting(true);
            setWaiting(true);
            const url = `https://us-central1-firenode-86abd.cloudfunctions.net/app/gateway`;
            const body = `amount=${payload.amount}&msisdn=${payload.msisdn}&account_id=preloved/${payload.account_id}`;
            fetch(url, {
                body: body,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                method: "POST",
            })
                .then((response) => response.json())
                .then(async (data) => {
                    console.log(data)

                    if (data.success) {
                        console.log('ok')


                        // await prelovedUpdate(payload.account_id, { active: true })
                    } else {

                    };
                    await sleep(12000)
                    setWaiting(false)
                    setPrompting(false)

                    closeModal()
                    handleLoved()
                })
                .catch((error) => {
                    console.error('Error:', error)
                    setPrompting(false)
                    setWaiting(false)
                    alert(error)
                });
        }
    }

    const handleMyListingClick = (item) => {
        if (item.active) { } else {
            setListingID(item.id)
            setmsisdn(item.phone)
        }
    }
    const handleNameChange = (event) => {
        setTitle(event.target.value);
    }
    const handleDescChange = (event) => {
        setDesc(event.target.value);
    }
    const handleReasonChange = (event) => {
        setReason(event.target.value);
    }
    const handlePriceChange = (event) => {
        setPrice(event.target.value);
    }
    const handleFileChange = (event) => {
        setPercent(0)
        const files = event.target.files;
        setFiles([...files]);

        getDominantColor(files[0])
            .then(dominantColor => {
                console.log("Dominant color:", dominantColor);
            })
            .catch(error => {
                console.error("Error getting dominant color:", error);
            });
    }

    const handlePhoneChange = (event) => {
        const phoneNumber = event.target.value
        const result = validatePhoneNumber(phoneNumber);
        // console.log(result, phoneNumber)
        if (result.result !== "invalid") {
            setPhone(result.result);
        } else {
            setPhone('')
        }
    }

    const closeListing = (id) => {
        setIsListing(false)
        handleLoved()
        setTitle('')
        setDesc('')
        setUploading(false)
        setReason('')
        setPrice(null)
        setFiles([])
        setListingID(id)
        setmsisdn(phone)
    }
    const getDominantColor = async (file) => {
        // Create an object URL for the image (temporary URL)
        const url = URL.createObjectURL(file);

        // Create a new image element
        const image = new Image();
        image.src = url;

        // Wait for the image to load asynchronously using await
        try {
            await new Promise((resolve, reject) => {
                image.onload = resolve;
                image.onerror = reject;
            });
        } catch (error) {
            console.error("Error loading image:", error);
            URL.revokeObjectURL(url); // Clean up even on error
            throw error; // Re-throw the error for further handling (optional)
        }


        // Create a hidden canvas element
        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = 1;
        const context = canvas.getContext('2d');

        // Draw the image onto the canvas (scaled down to a single pixel)
        context.drawImage(image, 0, 0);

        // Get the image data for the single pixel
        const imageData = context.getImageData(0, 0, 1, 1).data;

        // Extract the RGB values
        const red = imageData[0];
        const green = imageData[1];
        const blue = imageData[2];

        // Convert RGB to HEX (adapted for readability)
        const hex = "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).padStart(6, '0');
        setDominant(hex)
        return hex.toUpperCase();
    }
    const handleUpload = async () => {
        if (pos) {
            setUploading(true)
            const data = {
                ...pos,
                title: title,
                desc: desc,
                reason: reason,
                price: parseInt(price),
                uid: uid,
                active: true,
                phone: phone,
                caption: `🟢 ♦️${title} - ${desc}. ♦️ 🟢 💥Selling at kes ${price}. 💥 Reason for selling: ${reason}⭐Contact ${phone}🤙.`,
                dominantColor: dominantColor
            }

            if (files.length > 0) {
                await addListing(files, setPercent, data, closeListing)
            } else {
                setUploading(false)
                console.log('no file')
            }
        } else {
            console.log('no pos')
        }
    }


    const onSave = () => {
        getSaves(uid).then((res) => setSaves(res))
    }
    const handleSave = (id, saves) => {
        if (uid) {
            const data = {
                id: id,
                saves: saves,
                uid: uid
            }
            saveUnsave(data, onSave)
        } else {
            handleSignIn()
        }
    }
    const handleLoved = () => {
        getLoved(setLoved, 1500)
    }
    const handleIsListing = async () => {

        if (user?.uid) {
            setIsListing(true)
        } else {
            await handleSignIn()
            setIsListing(true)
        }
    }
    const handleClick = () => {
        if (toggleSearch) {
            setSearch('')
        }
        setToggle(!toggleSearch)
    }

    useEffect(() => {
        handleLoved()
        handleLocation()
        setUID(user?.uid)
        
        user && getMyLoved(user.uid, setMyLoved)
    }, [user])
    return (
        <div className=' pt-8 md:pt-10'>

            <div className='py-2 px-8 flex space-x-2 items-center justify-between mt-8 w-full'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
                </svg>
                {!toggleSearch ? <span className='text-xs font-semibold my-4'>{"Fairly priced pre-loved items!"}</span>

                    : <input
                        className={` border-2 w-full md:w-1/2 mx-2 h-10 md:h-12 font-light py-2 px-4 text-xl text-center text-black line-clamp-1 overflow-hidden bg-white bg-opacity-80 rounded-full`}
                        placeholder="search"
                        autoFocus='false'
                        type='text'
                        onChange={(e) => { setSearch(e.target.value) }} />
                }
                <button
                    onClick={handleClick}>
                    {toggleSearch ?
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                            <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                        </svg>

                    }

                </button>
            </div>

            {loved.length > 0 &&
                <div className='flex flex-wrap  justify-between'>

                    <>
                        <div className={`${' w-1/2 md:w-72'} inline-flex flex-col justify-start p-6 mb-4 flex-shrink-0`}>
                            <div className={`flex space-x-1 items-center justify-center cursor-pointer aspect-square rounded-2xl border hover:bg-green-900 bg-green-100 text-green-900 hover:text-white hover:shadow shadow-slate-950  my-2`}
                                onClick={handleIsListing}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                </svg>

                                <span className='block text-xs'>List on dukadash!</span>
                            </div>
                        </div>
                    </>
                    {
                        loved?.filter(
                            item => (item.caption.toLowerCase().includes(search.toLowerCase()))
                        ).map((item) => {
                            return <PreLovedListing item={item} handleSave={handleSave} saved={saves.map(i => i.code).includes(item.code)} />
                        })}
                </div>
            }

            {uid && <Transition appear show={isListing} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >{
                                    pos ? <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Welcome to Dukadash!
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <div className='flex flex-col space-y-4'>
                                                <input placeholder='Item name' type='text' onChange={(e) => handleNameChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Item description' type='text' onChange={(e) => handleDescChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Reason for selling' type='text' onChange={(e) => handleReasonChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Selling price' type='number' onChange={(e) => handlePriceChange(e)}
                                                    className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                                                <input placeholder='Phone Number' type='number' onChange={(e) => handlePhoneChange(e)}
                                                    className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />

                                                <label for="avatar">Upload photos of your item:</label>
                                                <div className=' flex flex-wrap'>

                                                    {files.length > 0 && files.map((file) => {
                                                        const image = URL.createObjectURL(file)
                                                        return (
                                                            <img alt='item_photo' src={image} className='w-16 md:w-24 h-16 md:h-24 object-cover object-center rounded m-1' loading='lazy' />
                                                        )
                                                    })}
                                                </div>
                                                <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                                                    onChange={handleFileChange} multiple />
                                            </div>
                                        </div>

                                        <div className="mt-4">
                                            {uploading ?
                                                <ReactLoading type="bubbles" color="black" />
                                                : <button
                                                    disabled={
                                                        title === ''
                                                        || phone === ''
                                                        || !pos
                                                        || !files
                                                        || reason === ''
                                                        || desc === ''
                                                        || !(price > 0)
                                                    }
                                                    type="button"
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                    onClick={handleUpload}
                                                >
                                                    Create Listing
                                                </button>}
                                        </div>
                                    </Dialog.Panel>
                                        :
                                        <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                                            <ReactLoading type="bubbles" color="black" />
                                        </div>
                                }
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}

            {<Transition appear show={listingid !== ''} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        className="leading-6 text-sm text-gray-900 text-center bg-green-100 p-6"
                                    > Users can only explore <b>ACTIVE</b> listings.<br />An Mpesa prompt will be sent to your phone number when you activate.
                                    </Dialog.Title>


                                    <div className='flex flex-col items-center justify-center py-8 px-4'>
                                        <label className=" text-xs">Listing Fee</label>
                                        <span className=' text-lg m-1 font-bold'>
                                            {new Intl.NumberFormat().format(charge)}/=
                                        </span>

                                        <label className="mt-4 mb-1 text-xs" >{"Confirm your number"}</label>
                                        <input placeholder='Whatsapp Number'
                                            type='tel'
                                            onChange={(e) => handleMSISDNChange(e)}
                                            name="msisdn"
                                            defaultValue={`+${msisdn === '' ? phone : msisdn}`}
                                            className={`p-4 text-center font-semibold relative w-full cursor-default overflow-hidden rounded-lg bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${msisdn === '' ? 'ring-red-100 focus-visible:ring-offset-red-300' : 'ring-teal-100 focus-visible:ring-offset-teal-300'} sm:text-sm`}
                                        />
                                    </div>

                                    <div className="flex items-center justify-around m-4">
                                        {(prompting) ?
                                            (waiting) ?
                                                <ReactLoading className="loading-anim" type="bubbles" color="green" />
                                                :
                                                <div className="sm:m-4 flex flex-col justify-center items-center">
                                                    <span>{`welcome 🙂`}</span>
                                                </div>
                                            :
                                            <>
                                                <button onClick={() => { setListingID('') }}
                                                    className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                                                    Dismiss
                                                </button>
                                                <button
                                                    disabled={msisdn === ''}
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                    onClick={() => {
                                                        SendPayload({
                                                            amount: charge,
                                                            msisdn: msisdn,
                                                            account_id: listingid
                                                        }, { setPrompting, setWaiting })
                                                    }}
                                                >Activate
                                                </button>
                                            </>
                                        }
                                    </div>
                                </Dialog.Panel>

                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>}
        </div>
    )
}

export default Preloved