import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from 'firebase/app'
import React, { useState, useEffect, useRef } from 'react';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut, OAuthProvider } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { collection, query, where, getFirestore, doc, onSnapshot, setDoc, getDoc, getDocs, Timestamp, updateDoc, orderBy, limit, addDoc, startAt, endAt, increment, arrayUnion } from 'firebase/firestore';
import { useCollection, useDocument, useCollectionData } from 'react-firebase-hooks/firestore';
import dayjs from "dayjs";
import { geohashQueryBounds, distanceBetween, geohashForLocation } from 'geofire-common'
import { addDays, getMoment, shorten, sleep } from "./helpers";
import { now } from "moment";


const radiusInM = 40000
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDCuwWHLDk-BKDMUFFGRVQgtagDDEbbWjw",
    authDomain: "dukadash.com",
    projectId: "autosoft-614e7",
    storageBucket: "autosoft-614e7.appspot.com",
    messagingSenderId: "860007949461",
    appId: "1:860007949461:web:cc1d25273ed7b783f1a79c",
    measurementId: "G-RDEYD6BDCJ"
};

export const COLLECTIONS = {
    POSTS: "Posts",
    BIDS: "Bids",
    REQUESTS: "Requests",
    PRODUCTS: "Products",
    PHONES: "Phones",
    ACCOUNTS: "Accounts",
    MENUS: "Menus",
    CONSTS: "Constants",
    INVOICES: "Invoices",
    PRELOVED: "Preloved",
    SHARED: "Shared",
    CHATS: "AIChats",
    DELIVERY: "Delivery",
    USERS: "Users",
    CATEGORIES: "Categories",
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
const storage = getStorage(app);

export function SignIn() {
    return (
        <button onClick={signInWithMicrosoft}
            style={{ borderRadius: '8', margin: '10px', width: '15vw' }}
        >
            Sign In
        </button>
    )
}

export function SignOut() {
    return (
        <button onClick={logout}>Sign out</button>
    );
}

export const signInWithMicrosoft = async (callback) => {
    const provider = new OAuthProvider('microsoft.com')
    signInWithPopup(auth, provider)
        .then((result) => {
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.

            // Get the OAuth access token and ID Token
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            console.log('cred', credential)
        })
        .catch((error) => {
            // Handle error.
            console.log('err', error)
        });
}

export const signInWithGoogle = async (callback) => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
        .then(async (result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            //check if user exists

            const user_doc = doc(db, COLLECTIONS.USERS, user.uid);
            const docSnap = await getDoc(user_doc);

            if (docSnap.exists()) {
                await updateDoc(user_doc, { token: token, replies: docSnap.data().replies ?? 10 })
                // doc.data() will be undefined in this case
                console.log("User exists!");
            } else {
                // save user
                const docData = {
                    created: Timestamp.fromDate(new Date()),
                    uid: user.uid,
                    name: user.displayName,
                    email: user.email,
                    replies: 10,
                    token: token,
                    photo: user.photoURL,
                    isVendor: false,
                    active: false,
                    isRider: false,
                    about: ""
                };

                await setDoc(user_doc, docData)


            }
            callback && callback?.()
        }).catch((error) => {
            console.log(error);
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
            callback && callback?.()
        });
}
export const getCategories = async () => {

    const data = []

    const q = query(
        collection(db, COLLECTIONS.CATEGORIES),
        orderBy('name', 'asc')
    )

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc, idx) => {
            data.push({
                index: idx + 1,
                id: doc.id,
                name: doc.data().name,
            })
        })
    }

    return data
}

export const logout = () => {
    signOut(auth).then(() => {
        // Sign-out successful.
    }).catch((error) => {
        // An error happened.
    });
}

export const getUser = async (id, setUser) => {
    if (id) {
        const ref = doc(db, COLLECTIONS.USERS, id)

        const unsub = onSnapshot(
            ref,
            { includeMetadataChanges: true },
            (d) => {
                const data = {
                    uid: d.id,
                    phone: d.data()?.phone ?? '',
                    isVendor: d.data()?.isVendor ?? false,
                    isRider: d.data()?.isRider ?? false,
                    active: d.data()?.active ?? false,
                    name: d.data()?.name ?? '',
                    email: d.data()?.email ?? '',
                    image: d.data()?.image ?? '',
                    token: d.data()?.token,
                    photo: d.data()?.photo,
                    replies: d.data()?.replies ?? 10,
                    service: d.data()?.service
                }
                setUser(data)
            });
        return unsub
    } else { setUser(null) }
    return null
}

export const updateUser = async (id, data) => {

    const col = doc(db, COLLECTIONS.USERS, id)
    await setDoc(col, data, { merge: true })
}

export const getMenu = async (id, pos) => {
    const ref = doc(db, COLLECTIONS.MENUS, id)
    const d = await getDoc(ref)
    if (d.exists()) {

        const created = d.data().created
        const date = created.toDate()
        const shortdate = date.toLocaleDateString("en-GB")
        const lat = d.data().lat
        const lng = d.data().lng

        // We have to filter out a few false positives due to GeoHash
        // accuracy, but most will match
        const distanceInKm = pos ? distanceBetween([lat, lng], [pos.lat, pos.lng]).toFixed(1) : 0

        const data = {
            id: d.id,
            title: d.data().title,
            image: d.data().image,
            desc: d.data().desc,
            phone: d.data().phone,
            limit: d.data().limit,
            requests: d.data().requests,
            prompt: d.data().prompt,
            sales: d.data().sales,
            lat: d.data().lat,
            lng: d.data().lng,
            bonus: d.data().bonus,
            balance: d.data().balance,
            active: d.data().active,
            categories: d.data().categories ?? [],
            editor: auth.currentUser ? auth.currentUser.uid === d.data().uid : false,
            dist: distanceInKm,
            within: d.data().limit >= distanceInKm,
            likes: d.data().likes,
            date: shortdate,
        }

        if (pos) {
            const center = [pos.lat, pos.lng];
            const lat = d.data().lat
            const lng = d.data().lng

            const distanceInKm = distanceBetween([lat, lng], center).toFixed(1);

            return {
                ...data,
                dist: distanceInKm,
                within: d.data().limit >= distanceInKm,
            }
        }
        return data
    } else { return null }
}

export const getUserMenus = async (uid, setItems) => {

    const data = []

    const q = query(
        collection(db, COLLECTIONS.MENUS),
        where('uid', '==', uid),
        orderBy('created', 'desc')
    )

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const created = doc.data().created
            const date = created.toDate()
            const shortdate = date.toLocaleDateString("en-GB")
            data.push({
                id: doc.id,
                title: doc.data().title,
                image: doc.data().image,
                phone: doc.data().phone,
                lat: doc.data().lat,
                lng: doc.data().lng,
                desc: doc.data().desc,
                active: doc.data().active,
                limit: doc.data().limit,
                requests: doc.data().requests,
                likes: doc.data().likes,
                prompt: doc.data().prompt,
                date: shortdate
            })
        })
    } else { console.log('no stores yet') }
    setItems(data)


}
export const getUserShares = async (uid, setItems) => {
    // console.log(uid)
    if (uid) {

        const q = query(
            collection(db, COLLECTIONS.SHARED),
            where('uid', '==', uid),
            orderBy('created', 'desc'),
            limit(10)
        )

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            if (!querySnapshot.empty) {
                const data = []
                querySnapshot.forEach((doc) => {
                    const created = doc.data().created
                    const date = created.toDate()
                    const shortdate = date.toLocaleDateString("en-GB")
                    data.push({
                        id: doc.id,
                        title: doc.data().title,
                        hits: doc.data().hits,
                        images: doc.data().images,
                        uid: doc.data().uid,
                        menuid: doc.data().menuid,
                        phone: doc.data().phone ?? '',
                        description: doc.data().description,
                        caption: doc.data().caption,
                        dominantColor: doc.data().dominantColor,
                        date: shortdate
                    })
                })
                setItems(data)
            } else { console.log('nothing yet') }

        });
        return unsubscribe
    }

}

export const getMenus = async (pos, setItems) => {
    if (pos) {
        const center = [pos.lat, pos.lng];
        const data = []
        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.MENUS),
                where('active', '==', true),
                orderBy('geohash', 'asc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(1000)
            )
            promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const lat = doc.get('lat');
                    const lng = doc.get('lng');

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = distanceBetween([lat, lng], center).toFixed(1);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {

                        const created = doc.data().created
                        const date = created.toDate()
                        const shortdate = date.toLocaleDateString("en-GB")

                        const push = {
                            id: doc.id,
                            title: doc.data().title,
                            lat: doc.data().lat,
                            lng: doc.data().lng,
                            image: doc.data().image,
                            desc: doc.data().desc,
                            limit: doc.data().limit,
                            dist: distanceInKm,
                            within: doc.data().limit >= distanceInKm,
                            phone: doc.data().phone,
                            requests: doc.data().requests,
                            categories: doc.data().categories ?? [],
                            likes: doc.data().likes,
                            prompt: doc.data().prompt,
                            date: shortdate
                        }
                        data.push(push);
                    }
                }
            }

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            setItems(docs)
        });
    } else {

        // const q = query(
        //     collection(db, COLLECTIONS.MENUS),
        //     where('active', '==', true),
        //     orderBy("created", "desc"),
        //     limit(20)
        // )
        // const querySnapshot = await getDocs(q);
        // const data = []

        // querySnapshot.forEach((doc) => {
        //     data.push({
        //         id: doc.id,
        //         dist: null,
        //         title: doc.data().title,
        //         image: doc.data().image,
        //         desc: doc.data().desc,
        //         requests: doc.data().requests,
        //         phone: doc.data().phone
        //     })
        // })
        // setItems(data)
    }
}

const like = async (data, onLike) => {
    console.log('liking')
    const d = {
        ...data,
        status: 1,
        time: Timestamp.fromDate(new Date()),
    }
    const col1 = collection(db, `${COLLECTIONS.MENUS}/${data.id}/likes`)
    const col2 = collection(db, `${COLLECTIONS.USERS}/${data.uid}/likes`)

    const d1 = doc(col1, data.uid);
    const d2 = doc(col2, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    await menuUpdate(data.id, { likes: increment(1) })

    onLike(true)
}
const unlike = async (data, onLike) => {
    console.log('unliking')
    const d = {
        ...data,
        status: 0,
        time: Timestamp.fromDate(new Date()),
    }
    console.log(d)

    const d1 = doc(db, `${COLLECTIONS.MENUS}/${data.id}/likes`, data.uid);
    const d2 = doc(db, `${COLLECTIONS.USERS}/${data.uid}/likes`, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    data.likes > 0 && await menuUpdate(data.id, { likes: increment(-1) })
    onLike(false)
}

export const likeUnlike = async (data, onLike) => {
    const docSnap = await getDoc(doc(db, `${COLLECTIONS.MENUS}/${data.id}/likes`, data.uid));

    if (docSnap.exists()) {
        if (docSnap.data().status === 0) {
            await like(data, onLike)
        } else {
            await unlike(data, onLike)
        }
    } else {
        await like(data, onLike)
    }
}

const save = async (data, onSave) => {
    console.log('saving')
    const d = {
        ...data,
        status: 1,
        time: Timestamp.fromDate(new Date()),
    }
    console.log(d)
    const col1 = collection(db, `${COLLECTIONS.PRELOVED}/${data.id}/saves`)
    const col2 = collection(db, `${COLLECTIONS.USERS}/${data.uid}/saves`)

    const d1 = doc(col1, data.uid);
    const d2 = doc(col2, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    await prelovedUpdate(data.id, { saves: increment(1) })

    onSave()
}

const unsave = async (data, onSave) => {
    console.log('unsaving')
    const d = {
        ...data,
        status: 0,
        time: Timestamp.fromDate(new Date()),
    }
    console.log(d)

    const d1 = doc(db, `${COLLECTIONS.PRELOVED}/${data.id}/saves`, data.uid);
    const d2 = doc(db, `${COLLECTIONS.USERS}/${data.uid}/saves`, data.id);
    await setDoc(d1, d, { merge: true })
    await setDoc(d2, d, { merge: true })
    data.saves > 0 && await prelovedUpdate(data.id, { saves: increment(-1) })
    onSave()
}

export const saveUnsave = async (data, onSave) => {
    const docSnap = await getDoc(doc(db, `${COLLECTIONS.PRELOVED}/${data.id}/saves`, data.uid));

    if (docSnap.exists()) {
        if (docSnap.data().status === 0) {
            await save(data, onSave)
        } else {
            await unsave(data, onSave)
        }
    } else {
        await save(data, onSave)
    }
}

export const getLikes = async (id) => {
    const q = query(
        collection(db, `${COLLECTIONS.USERS}/${id}/likes`),
        where('status', '==', 1)
    )

    const querySnapshot = await getDocs(q);
    const likes = []
    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            likes.push({
                key: doc.id,
                id: doc.data().id,
                uid: doc.data().uid,
                status: doc.data().status,
            })
        })
    } else { console.log('no likes yet') }
    return likes
}
export const getSaves = async (id) => {
    const q = query(
        collection(db, `${COLLECTIONS.USERS}/${id}/saves`),
        where('status', '==', 1)
    )

    const querySnapshot = await getDocs(q);
    const saves = []
    if (!querySnapshot.empty) {
        const s = []
        querySnapshot.forEach((doc) => {
            s.push(doc.data().id)
        })

        if (s.length > 0) {
            const q2 = query(
                collection(db, COLLECTIONS.PRELOVED),
                where('code', 'in', s),
            )

            const qs = await getDocs(q2);
            if (!qs.empty) {
                qs.forEach((doc) => {

                    const caption = doc.data()["caption"]

                    const posted = doc.data()["posted"].toDate()
                    saves.push({
                        id: doc.id,
                        thumb: doc.data()["images"],
                        caption: caption,
                        status: doc.data()["status"],
                        saves: doc.data()["saves"],
                        code: doc.data()["code"],
                        posted: posted
                    })

                })
            } else { console.log('no saves yet') }
        }
    } else { console.log('no saves yet') }

    return saves
}

export const getFavs = async (pos, setFavs, uid) => {

    const objarr = await getLikes(uid)
    if (objarr.length > 0) {
        const favourites = objarr.map(item => item['id'])
        const center = [pos.lat, pos.lng];
        const data = []
        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.MENUS),
                where('active', '==', true),
                where('id', 'in', favourites),
                orderBy('geohash', 'asc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(200)
            )
            promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const lat = doc.get('lat');
                    const lng = doc.get('lng');

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = distanceBetween([lat, lng], center).toFixed(1);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {

                        const created = doc.data().created
                        const date = created.toDate()
                        const shortdate = date.toLocaleDateString("en-GB")

                        const push = {
                            id: doc.id,
                            title: doc.data().title,
                            image: doc.data().image,
                            desc: doc.data().desc,
                            limit: doc.data().limit,
                            dist: distanceInKm,
                            within: doc.data().limit >= distanceInKm,
                            phone: doc.data().phone,
                            requests: doc.data().requests,
                            likes: doc.data().likes,
                            prompt: doc.data().prompt,
                            date: shortdate
                        }
                        data.push(push);
                    }
                }
            }

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            setFavs(docs)
        });
    } else { setFavs([]) }
}

export const getLoved = async (setLoved, lim) => {

    const q = query(
        collection(db, COLLECTIONS.PRELOVED),
        where('active', '==', true),
        orderBy('posted', 'desc'),
        limit(lim)
    )


    const querySnapshot = await getDocs(q);
    const loved = []
    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const caption = doc.data()["caption"]
            if (!caption.toLocaleLowerCase().includes("we ") && !caption.toLocaleLowerCase().includes("our ") && !caption.toLocaleLowerCase().includes("sold")) {
                const posted = doc.data()["posted"].toDate()
                loved.push({
                    id: doc.id,
                    thumb: doc.data()["images"],
                    caption: doc.data()["caption"],
                    status: doc.data()["status"],
                    saves: doc.data()["saves"],
                    posted: posted,
                    active: doc.data().active,
                    version: doc.data().version,
                    phone: doc.data().phone,
                    uid: doc.data().uid,
                    code: doc.data()["code"]
                })
            }
        })
        setLoved(loved)
    } else { console.log('no loved yet') }
}

export const getLovedLink = async (id) => {

    const ref = doc(db, COLLECTIONS.PRELOVED, id)
    const d = await getDoc(ref)
    if (d.exists()) {


        const data = {
            id: d.id,
            thumb: d.data()["images"],
            caption: d.data()["caption"],
            status: d.data()["status"],
            saves: d.data()["saves"],
            posted: d.data()["posted"].toDate(),
            active: d.data().active,
            version: d.data().version,
            phone: d.data().phone,
            uid: d.data().uid,
            code: d.data()["code"]
        }

        return data
    } else { return null }
}
export const getMyLoved = async (uid, setMyLoved) => {

    const q = query(
        collection(db, COLLECTIONS.PRELOVED),
        where('uid', '==', uid),
        orderBy('posted', 'desc')
    )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data = []
        querySnapshot.forEach((doc) => {
            const posted = doc.data()["posted"].toDate()
            data.push({
                id: doc.id,
                thumb: doc.data()["images"],
                caption: doc.data()["caption"],
                status: doc.data()["status"],
                saves: doc.data()["saves"],
                posted: posted,
                active: doc.data().active,
                version: doc.data().version,
                phone: doc.data().phone,
                uid: doc.data().uid,
                code: doc.data()["code"]
            })
        })
        setMyLoved(data)
    });
    return unsubscribe
}

const uploadProductImage = async (file, setPercent, uid, pid) => {
    const extension = file.name.split('.').pop()
    const path = `/dukadash/products/${uid}/${pid}.${extension}`
    console.log(path)
    const storageRef = ref(storage, path)
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            // update progress
            setPercent(percent)
        },
        (err) => {
            console.log(err)
        },
        async () => {
            // download url
            await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                await setProductUpdate(pid, { image: url })
            });
        }
    );
}
const uploadStoreImage = async (file, setPercent, uid, pid, handleFetch) => {
    const extension = file.name.split('.').pop()
    const path = `/app/stores/${uid}/${pid}.${extension}`
    console.log(path)
    const storageRef = ref(storage, path)
    const uploadTask = uploadBytesResumable(storageRef, file);
    await uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )
            // update progress
            setPercent(percent)
        },
        (err) => {
            console.log(err)
        },
        async () => {
            // download url
            await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                await menuUpdate(pid, { image: url })
                handleFetch?.()
            });
        }
    );
}
export const getGeoHash = async (data) => {
    return geohashForLocation([data.lat, data.lng])
}

export const updateProduct = async (file, setPercent, data, closeModal, handleFetch) => {

    await setProductUpdate(data.id, data)
    if (file) {
        await uploadProductImage(file, setPercent, data.uid, data.id)
    }
    closeModal()
    handleFetch()

}

export const updateMenu = async (file, setPercent, data, closeModal, handleFetch) => {

    if (file) {
        await uploadStoreImage(file, setPercent, data.id, data.id, handleFetch)
    }
    await menuUpdate(data.id, data)
    closeModal()
    handleFetch()

}

export const captureDeliver = async (file, setPercent, data, closeListing) => {
    const hash = geohashForLocation([data.lat, data.lng])
    console.log('hash', data)
    const d = {
        ...data,
        geohash: hash,
        created: Timestamp.fromDate(new Date()),
        updated: Timestamp.fromDate(new Date()),
    }
    const col = collection(db, COLLECTIONS.DELIVERY)
    const post = await addDoc(col, d)
    await updateUser(data.uid, {
        phone: data.phone
    })


    const update = async (d) => {
        const col = doc(db, COLLECTIONS.DELIVERY, post.id)
        await setDoc(col, d, { merge: true })
        await updateUser(data.uid, { isRider: true })
    }

    await uploadImage(file, closeListing, data.uid, post.id, COLLECTIONS.DELIVERY, update)

}

export const updateDeliverInfo = async (id, file, setPercent, data, closeListing) => {
    const hash = geohashForLocation([data.lat, data.lng])
    console.log('hash', data)
    const d = {
        ...data,
        geohash: hash,
        updated: Timestamp.fromDate(new Date()),
    }

    await updateDoc(doc(db, COLLECTIONS.DELIVERY, id), d)
    await updateUser(data.uid, {
        phone: data.phone
    })

    const update = async (d) => {
        const col = doc(db, COLLECTIONS.DELIVERY, id)
        await setDoc(col, d, { merge: true })
        await updateUser(data.uid, { isRider: true })
    }

    if (file.length > 0) { await uploadImage(file, closeListing, data.uid, id, COLLECTIONS.DELIVERY, update) }

}

export const updateDeliver = async (id, pos) => {
    const hash = geohashForLocation([pos.lat, pos.lng])
    const d = {
        ...pos,
        geohash: hash,
        updated: Timestamp.fromDate(new Date()),
    }

    await updateDoc(doc(db, COLLECTIONS.DELIVERY, id), d)
}

export const getDeliversNear = async (pos, setItems) => {
    logEvent(analytics, "delivers request", { outlet: pos })

    if (pos.lat && pos.lng) {

        const center = [pos.lat, pos.lng];

        const data = []
        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.DELIVERY),
                orderBy('geohash', 'asc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(20)
            )
            promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const lat = doc.get('lat');
                    const lng = doc.get('lng');

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = (distanceBetween([lat, lng], center)).toFixed(1);
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {

                        const updated = doc.data().updated
                        const date = updated.toDate()
                        const shortdate = date.toLocaleDateString("en-GB")
                        const push = {
                            dist: distanceInKm,
                            id: doc.id,
                            updated: date,
                            name: doc.data().name,
                            lat: lat,
                            lng: lng,
                            vehicle: doc.data().vehicle,
                            image: doc.data().image,
                            price: doc.data().price,
                            phone: doc.data().phone,
                            intro: doc.data().intro
                        }
                        data.push(push);
                    }
                }
            }

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            setItems(docs)
        });

    }
}

export const getDeliverUser = async (uid, pos) => {
    logEvent(analytics, "getting deliver data", { uid: uid })

    const q = query(
        collection(db, COLLECTIONS.DELIVERY),
        where('uid', '==', uid)
    )
    const data = []
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {

            const lat = doc.data().lat
            const lng = doc.data().lng

            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = distanceBetween([lat, lng], [pos.lat, pos.lng]).toFixed(1);
            const distanceInM = (distanceInKm * 1000).toFixed(2);

            const updated = doc.data().updated
            const date = updated.toDate()
            const shortdate = date.toLocaleDateString("en-GB")
            const shorttime = date.toLocaleTimeString("en-GB")
            const push = {
                id: doc.id,
                dist: distanceInKm,
                name: doc.data().name,
                image: doc.data().image,
                price: doc.data().price,
                phone: doc.data().phone,
                intro: doc.data().intro,
                vehicle: doc.data().vehicle,
                updated: `${shortdate} at ${shorttime}`,
            }
            data.push(push);
            updateDeliver(doc.id, pos)

        })
    }


    return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))



}

export const captureRequest = async (data) => {
    const id = data.vendor.id
    const col = collection(db, COLLECTIONS.REQUESTS)
    const request = await addDoc(col, data)
    if (id !== '') {
        await menuUpdate(id, { requests: increment(1) })
    }
    return request.id
}

export const getRequest = async (id, setItem, process) => {

    const ref = doc(db, COLLECTIONS.REQUESTS, id)
    const unsub = onSnapshot(ref, async (d) => {
        if (d.exists()) {
            const data = {
                id: d.id,
                cart: d.data().cart,
                logged: d.data().logged,
                location: d.data().location,
                paid: d.data().paid,
                dasher: d.data().dasher,
                charge: d.data().charge,
                user: d.data().user,
                vendor: d.data().vendor,
                debitedAt: d.data().debitedAt,
            }
            setItem(process(data))
        }
    })
    return unsub
}

export const getRequests = async (uid, process) => {

    const myarr = []
    const q = query(
        collection(db, COLLECTIONS.REQUESTS),
        where('ids','array-contains', uid),
        orderBy('logged', 'desc'),
        limit(50)
    )

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        
        querySnapshot.forEach((d)=>{

            const p = process({
                id: d.id,
                cart: d.data().cart,
                logged: d.data().logged,
                location: d.data().location,
                paid: d.data().paid,
                dasher: d.data().dasher,
                charge: d.data().charge,
                user: d.data().user,
                vendor: d.data().vendor,
                debitedAt: d.data().debitedAt,
            })
            myarr.push(p)
        
        })
    }
    return myarr
}

export const capturePost = async (data) => {
    const hash = geohashForLocation([data.lat, data.lng])
    console.log('hash', data)
    const d = {
        ...data,
        geohash: hash,
        created: Timestamp.fromDate(new Date()),
    }
    const col = collection(db, COLLECTIONS.POSTS)
    const request = await addDoc(col, d)
    return request.id
}

export const getAllPosts = async (pos, setItems) => {

    if (false) {

        const center = [pos.lat, pos.lng];

        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.POSTS),
                orderBy('created', 'desc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(100)
            )
            promises.push(getDocs(q));
        }


        const data = []
        // Collect all the query results together into a single list
        Promise.all(promises).then((querySnapshot) => {

            querySnapshot.forEach((snap) => {
                if (!snap.empty) {
                    console.log('all post ids', snap.docs)
                    snap.forEach((doc) => {
                        const lat = doc.data().lat
                        const lng = doc.data().lng

                        // We have to filter out a few false positives due to GeoHash
                        // accuracy, but most will match
                        const distanceInKm = distanceBetween([lat, lng], center);
                        const distanceInM = distanceInKm * 1000;
                        if (distanceInM <= radiusInM) {

                            const created = doc.data().created
                            const date = created ? created.toDate() : ''
                            const shortdate = created ? date.toLocaleDateString("en-GB") : ''
                            const push = {
                                id: doc.id,
                                dist: distanceInKm,
                                message: doc.data().message,
                                name: doc.data().name,
                                uid: doc.data().uid,
                                date: shortdate
                            }
                            data.push(push);
                        }
                    })
                } else {
                    console.log('no posts')
                }
            })

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            console.log('all posts', docs)
            setItems(docs)
        });

    } else {
        const data = []

        const q = query(
            collection(db, COLLECTIONS.POSTS),
            orderBy('created', 'desc'),
            limit(100)
        )

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
                const created = doc.data().created
                const date = created ? created.toDate() : ''
                const shortdate = created ? date.toLocaleDateString("en-GB") : ''
                data.push({
                    dist: 0,
                    id: doc.id,
                    message: doc.data().message,
                    name: doc.data().name,
                    uid: doc.data().uid,
                    date: shortdate
                })
            })
        }
        setItems(data)
    }
}
export const getUserPosts = async (uid) => {

    const data = []

    const q = query(
        collection(db, COLLECTIONS.POSTS),
        where('uid', '==', uid),
        orderBy('created', 'desc'),
        limit(100)
    )

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const created = doc.data().created
            const date = created ? created.toDate() : ''
            const shortdate = created ? date.toLocaleDateString("en-GB") : ''
            data.push({
                dist: 0,
                id: doc.id,
                message: doc.data().message,
                name: doc.data().name,
                uid: doc.data().uid,
                date: shortdate
            })
        })
    }

    return data
}

export const captureBid = async (data) => {
    const d = {
        ...data,
        created: Timestamp.fromDate(new Date()),
    }
    const col = collection(db, COLLECTIONS.BIDS)
    const bid = await addDoc(col, d)
    return bid.id
}

export const getBids = async (id, pos) => {

    const data = []
    const center = [pos.lat, pos.lng];

    const q = query(
        collection(db, COLLECTIONS.BIDS),
        where('postid', '==', id),
        orderBy('created', 'desc')
    )

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
            const created = doc.data().created
            const date = created ? created.toDate() : ''
            const shortdate = created ? date.toLocaleDateString("en-GB") : ''
            const lat = doc.get('lat')
            const lng = doc.get('lng')
            // We have to filter out a few false positives due to GeoHash
            // accuracy, but most will match
            const distanceInKm = distanceBetween([lat, lng], center).toFixed(1)
            data.push({
                dist: distanceInKm,
                id: doc.id,
                uid: doc.data().uid ?? '',
                message: doc.data().message,
                postid: doc.data().postid,
                phone: doc.data().phone,
                image: doc.data().image,
                name: doc.data().name ?? '',
                vendorid: doc.data().vendorid,
                date: shortdate
            })
        })
    } else {
        // console.log('no bids')
    }

    return data
}


const menuUpdate = async (id, data) => {
    const col = doc(db, COLLECTIONS.MENUS, id)
    await setDoc(col, data, { merge: true })
}
export const prelovedUpdate = async (id, data) => {
    const col = doc(db, COLLECTIONS.PRELOVED, id)
    await setDoc(col, data, { merge: true })
}



const setProductUpdate = async (id, data) => {
    const col = doc(db, COLLECTIONS.PRODUCTS, id)
    await setDoc(col, data, { merge: true })
}

export const addProduct = async (file, setPercent, data, handleFetch, handleDish, closeModal) => {
    const hash = geohashForLocation([data.lat, data.lng])
    console.log('hash', data)

    const ref = doc(db, COLLECTIONS.CONSTS, 'app')
    const d = await getDoc(ref)
    const charge = d.data().charge
    const days = d.data().days
    console.log('const', d.data())

    const product = {
        ...data,
        active: !charge,
        geohash: hash,
        created: Timestamp.fromDate(new Date()),
    }

    const col = collection(db, COLLECTIONS.PRODUCTS)
    const p = await addDoc(col, product)

    if (file) {
        await uploadProductImage(file, setPercent, data.uid, p.id)
    }

    if (charge) {
        handleDish({ id: p.id, ...product })
    } else {
        updateDays(p.id, days)
        closeModal()
    }

    handleFetch()

}

export const addMenu = async (file, setPercent, data, navigateToAccount) => {
    const hash = geohashForLocation([data.lat, data.lng])
    console.log('hash', data)
    const d = {
        ...data,
        geohash: hash,
        created: Timestamp.fromDate(new Date()),
    }
    const col = collection(db, COLLECTIONS.MENUS)
    const m = await addDoc(col, d)
    const update = {
        menus: arrayUnion(m.id),
        phone: data.phone,
        isVendor: true
    }
    await updateUser(data.uid, update)
    if (file) {
        await uploadStoreImage(file, setPercent, data.uid, data.uid, navigateToAccount)
    } else {
        navigateToAccount()
    }
}
export const getShared = async (id, setItem, setShares, completed) => {

    const ref = doc(db, COLLECTIONS.SHARED, id)
    // console.log(id)

    const d = await getDoc(ref)
    if (d.exists()) {

        const doc_data = {
            caption: d.data().description,
            hits: d.data().hits,
            thumb: d.data().thumb,
            images: d.data().images,
            title: d.data().title,
            uid: d.data().uid,
            phone: d.data().phone,
            menuid: d.data().menuid,
            dominantColor: d.data().dominantColor,

        }
        setItem(doc_data)

        const moment = getMoment()
        console.log('hit')
        const update = {
            hits: increment(1),
            items: arrayUnion({
                src: COLLECTIONS.SHARED,
                id: id,
                title: doc_data.title,
                time: moment.time,
            })
        }
        // await setDoc(ref,update, { merge: true })

        await getUserShares(doc_data.uid, setShares)

    } else {
        console.log(id, 'no exist')
    }
    completed(true)


}

export const addToCollection = async (file, data, closeListing) => {

    var i = 0
    const update = async (img) => {
        i = i + 1
        await setDoc(doc(db, COLLECTIONS.SHARED, data.id), img, { merge: true })
        console.log(i)
        if (i === file.length) {
            closeListing()
        }
    }
    await uploadShareImages(file, data.uid, data.id, COLLECTIONS.SHARED, data.size, update)


}

export const addSharable = async (file, data, closeListing) => {

    const col = collection(db, COLLECTIONS.SHARED)

    const post = await addDoc(col, {
        ...data,
        created: Timestamp.fromDate(new Date())
    })

    var i = 0
    const update = async (data) => {
        i = i + 1
        await setDoc(doc(db, COLLECTIONS.SHARED, post.id), data, { merge: true })
        console.log(i)
        if (i === file.length) {
            closeListing()
        }
    }
    await uploadShareImages(file, data.uid, post.id, COLLECTIONS.SHARED, 0, update)

    const link = await shorten(`https://dukadash.com/photos//${post.id}`)
    return link

}
const uploadShareImages = async (files, uid, pid, collection, j, update) => {
    await files.forEach(async (file, idx) => {
        const i = idx + j
        const extension = file.name.split('.').pop()
        const moment = getMoment()
        if (i === 0) {
            const path = `/thumbs/${uid}/${pid}${i}.${extension}`
            const url = await uploadTaskPromise(path, file)
            const thumb = url.replace(`${pid}${i}`, `${pid}${i}_600x600`)
            await setDoc(doc(db, COLLECTIONS.SHARED, pid), { thumb: thumb }, { merge: true })
        }
        const path = `/app/${collection.toLowerCase()}/${uid}/${pid}${i}.${extension}`
        const url = await uploadTaskPromise(path, file)
        update({ images: arrayUnion({ caption: '', url: url, time: moment.time }) })
    })
}

export const addListing = async (file, setPercent, data, closeListing) => {
    const hash = geohashForLocation([data.lat, data.lng])
    const d = {
        ...data,
        geohash: hash,
        posted: Timestamp.fromDate(new Date()),
        version: 2,
    }
    const col = collection(db, COLLECTIONS.PRELOVED)

    const post = await addDoc(col, d)
    await updateUser(data.uid, {
        phone: data.phone
    })
    const update = async (data) => {
        await prelovedUpdate(post.id, { code: post.id, ...data })
    }

    await uploadImages(file, closeListing, data.uid, post.id, COLLECTIONS.PRELOVED, update)




    console.log('done')

}
const uploadTaskPromise = async (path, file) => {

    return new Promise((resolve, reject) => {
        const storageRef = ref(storage, path)
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                )
                // update progress
                // setPercent(percent)
            },
            (err) => {
                console.log(err)
                reject()
            },
            async () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    resolve(url)
                });
            }
        )
    })
}

const uploadImage = async (files, closeListing, uid, pid, collection, update) => {
    await files.forEach(async (file, i) => {
        const extension = file.name.split('.').pop()
        const path = `/app/${collection.toLowerCase()}/${uid}/${pid}${i}.${extension}`
        const url = await uploadTaskPromise(path, file)
        update({ image: url })
        closeListing(pid)
    })
}
const uploadImages = async (files, closeListing, uid, pid, collection, update) => {
    await files.forEach(async (file, i) => {
        const extension = file.name.split('.').pop()
        const path = `/app/${collection.toLowerCase()}/${uid}/${pid}${i}.${extension}`
        const url = await uploadTaskPromise(path, file)
        update({ images: arrayUnion(url) })
        closeListing(pid)
    })
}

export const updateDays = async (id, days) => {

    const now = new Date()
    const then = addDays(now, days)

    const data = {
        days: days,
        from: now,
        until: then
    }

    const col = doc(db, COLLECTIONS.PRODUCTS, id)
    await setDoc(col, data, { merge: true })
}

export const getOutletProduct = async (id) => {
    const q = query(
        collection(db, COLLECTIONS.PRODUCTS),
        where('menuid', '==', id),
        orderBy("created", "desc"),
        limit(2000)
    )
    const querySnapshot = await getDocs(q);
    const data = []

    if (!querySnapshot.empty) {
        querySnapshot.forEach((doc, idx) => {
            data.push({
                name: doc.data().name,
                price: doc.data().price
            })
        })
    }
    return data
}

export const getProducts = async (id, setItems) => {
    logEvent(analytics, "user products request", { account: id })
    const q = query(
        collection(db, COLLECTIONS.PRODUCTS),
        where('menuid', '==', id),
        orderBy("created", "desc"),
        limit(2000)
    )


    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data = []
        querySnapshot.forEach((doc) => {

            const until = doc.data().until
            const date = until ? until.toDate() : null
            const shortdate = date ? date.toLocaleDateString("en-GB") : 'ACTIVE'
            data.push({
                id: doc.id,
                dist: 0,
                name: doc.data().name,
                enabled: doc.data().enabled,
                active: doc.data().active,
                from: doc.data().from,
                until: shortdate,
                discounted: doc.data().discounted,
                discount: doc.data().discount < 0 ? '' : doc.data().discount,
                image: doc.data().image,
                uname: doc.data().uname,
                price: doc.data().price,
                symbol: doc.data().symbol,
                currency: doc.data().currency,
                phone: doc.data().phone,
                desc: doc.data().desc
            })
        })
        setItems(data)
    });
    return unsubscribe
}

export const grabNearby = (pos, setItems) => {
    logEvent(analytics, "products request", { pos: pos })

    if (pos.lat && pos.lng) {

        const center = [pos.lat, pos.lng];

        const data = []
        const bounds = geohashQueryBounds(center, radiusInM);
        const promises = [];
        for (const b of bounds) {
            const q = query(
                collection(db, COLLECTIONS.PRODUCTS),
                where('active', '==', true),
                orderBy('geohash', 'asc'),
                startAt(b[0]),
                endAt(b[1]),
                limit(2000)
            )
            promises.push(getDocs(q));
        }

        // Collect all the query results together into a single list
        Promise.all(promises).then((snapshots) => {

            for (const snap of snapshots) {
                for (const doc of snap.docs) {
                    const lat = doc.get('lat');
                    const lng = doc.get('lng');

                    // We have to filter out a few false positives due to GeoHash
                    // accuracy, but most will match
                    const distanceInKm = distanceBetween([lat, lng], center).toFixed(1)
                    const distanceInM = distanceInKm * 1000;
                    if (distanceInM <= radiusInM) {

                        const until = doc.data().until
                        const date = until ? until.toDate() : ''
                        const shortdate = until ? date.toLocaleDateString("en-GB") : ''
                        const push = {
                            dist: distanceInKm,
                            id: doc.id,
                            name: doc.data().name,
                            enabled: doc.data().enabled,
                            discounted: doc.data().discounted,
                            active: doc.data().active,
                            from: doc.data().from,
                            until: shortdate,
                            discount: doc.data().discount < 0 ? '' : doc.data().discount,
                            image: doc.data().image,
                            price: doc.data().price,
                            symbol: doc.data().symbol,
                            uname: doc.data().uname,
                            phone: doc.data().phone,
                            currency: doc.data().currency,
                            desc: doc.data().desc
                        }
                        data.push(push);
                    }
                }
            }

            return data.sort((a, b) => (a.dist > b.dist ? 1 : -1))

        }).then((docs) => {
            setItems(docs)
        });

    } else {
        const q = query(
            collection(db, COLLECTIONS.PRODUCTS),
            where('active', '==', true),
            orderBy("created", "desc"),
            limit(2000)
        )


        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const data = []
            querySnapshot.forEach((doc) => {

                const until = doc.data().until
                const date = until ? until.toDate() : null
                const shortdate = date ? date.toLocaleDateString("en-GB") : 'ACTIVE'
                data.push({
                    id: doc.id,
                    dist: 0,
                    name: doc.data().name,
                    enabled: doc.data().enabled,
                    active: doc.data().active,
                    from: doc.data().from,
                    until: shortdate,
                    discounted: doc.data().discounted,
                    discount: doc.data().discount < 0 ? '' : doc.data().discount,
                    image: doc.data().image,
                    price: doc.data().price,
                    symbol: doc.data().symbol,
                    currency: doc.data().currency,
                    phone: doc.data().phone,
                    desc: doc.data().desc
                })
            })
            setItems(data)
        });
        return unsubscribe
    }
}


export const updateUsage = async (uid, created, debit, clipping, setIsClipping) => {

    const days = debit === 7000 ? 28 : debit === 2000 ? 7 : 1
    const exp = addDays(created.toDate(), days)
    const expDate = exp.toLocaleDateString("en-GB")
    const expTime = exp.toLocaleTimeString("en-GB")
    const today = new Date()
    const expStr = `${expDate} ${expTime}`
    console.log("expires", expStr)
    if (today > exp) {
        console.log("expired")
        setIsClipping(true)
        if (clipping) {
            const user_doc = doc(db, COLLECTIONS.USERS, uid);
            await updateDoc(user_doc, { clipboard: false })
        }
    } else {
        console.log("valid")
    }
    return expStr
}
export const saveMessage = async (id, docData, replies) => {

    const chat_doc = doc(db, COLLECTIONS.CHATS, `${id}${getMoment().window}`);
    const docSnap = await getDoc(chat_doc);
    const toks = replies - 1

    if (docSnap.exists()) {
        const data = {
            updated: Timestamp.fromDate(new Date()),
            chat: arrayUnion(docData)
        }
        await updateDoc(chat_doc, data)
    } else {
        const data = {
            uid: auth.currentUser.uid,
            updated: Timestamp.fromDate(new Date()),
            created: Timestamp.fromDate(new Date()),
            chat: arrayUnion(docData)
        }
        await setDoc(chat_doc, data)
    }

    if (docData.role === 'user') {
        // console.log('less')
        await updateDoc(doc(db, COLLECTIONS.USERS, id), {
            replies: toks >= 1 ? increment(-1) : 0
        })
    }
}
export const getMessage = async (id) => {
    const user_doc = doc(db, COLLECTIONS.CHATS, `${id}${getMoment().window}`)
    const docSnap = await getDoc(user_doc)

    if (docSnap.exists()) {
        const d = docSnap.data()
        return d.chat ?? []
    } else {
        return []
    }

}