import { useState, useRef, useEffect } from 'react'
import { Transition } from '@headlessui/react'
import { signInWithGoogle, logout, getUser, signInWithMicrosoft } from '../../firebaseConfig'
import { Link, useNavigate } from 'react-router-dom'
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { ContentPaste } from '@mui/icons-material'

export default function MobileMenu({ user, handleLocation, riding, setRiding }) {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [isVendor, setIsVendor] = useState(false)

  const trigger = useRef(null)
  const mobileNav = useRef(null)
  const handleSignIn = async () => {
    // await signInWithMicrosoft()
    await signInWithGoogle()
    setMobileNavOpen(false)
    // navigate(`/account`)
    handleClose()
  }
  const navigate = useNavigate();
  const handleSignOut = () => {
    navigate(`/`)
    logout()
    handleClose()

  }

  const toPhotos = () => {
    navigate('/photos')
    handleClose()
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAccount = () => {
    // 👇️ navigate
    if (isVendor) {

      navigate(`/account`, { uid: user.uid })
    } else {
      handleLocation(true)
    }
    handleClose()
  }
  const handleDelivery = () => {
    setRiding(true)
    handleClose()
  }

  // close the mobile menu on click outside
  useEffect(() => {
    user && getUser(user.uid, (res) => setIsVendor(res.isVendor)).then()
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
      setMobileNavOpen(false)
    };
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })
  const menustyle = { paddingLeft: '16px', paddingRight: '24px', display: 'flex', gap: '16px', marginTop: '16px' }

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false)
    };
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  return (
    <div className=" ml-2 justify-between flex">

      {/* Hamburger button */}
      <button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {/* <span className=" text-red-900 border border-red-900 px-4 py-2 font-light text-sm rounded-full">Sell here</span> */}
        <span className="sr-only">Menu</span>
        <svg className="w-6 h-6 fill-current text-red-900" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <rect y="4" width="24" height="1" />
          <rect y="11" width="24" height="1" />
          <rect y="18" width="24" height="1" />
        </svg>
      </button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {/* <MenuItem onClick={toPhotos} sx={menustyle}>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>

          <ListItemText>Photos</ListItemText>
        </MenuItem> */}

        {
          user ?
            <>
              <MenuItem onClick={user ? handleAccount : handleSignIn} sx={menustyle}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 21v-7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349M3.75 21V9.349m0 0a3.001 3.001 0 0 0 3.75-.615A2.993 2.993 0 0 0 9.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 0 0 2.25 1.016c.896 0 1.7-.393 2.25-1.015a3.001 3.001 0 0 0 3.75.614m-16.5 0a3.004 3.004 0 0 1-.621-4.72l1.189-1.19A1.5 1.5 0 0 1 5.378 3h13.243a1.5 1.5 0 0 1 1.06.44l1.19 1.189a3 3 0 0 1-.621 4.72M6.75 18h3.75a.75.75 0 0 0 .75-.75V13.5a.75.75 0 0 0-.75-.75H6.75a.75.75 0 0 0-.75.75v3.75c0 .414.336.75.75.75Z" />
                </svg>

                <ListItemText>{'Sell'}</ListItemText>
              </MenuItem>
              <MenuItem onClick={user ? handleDelivery : handleSignIn} sx={menustyle}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                </svg>


                <ListItemText>{'Dash'}</ListItemText>
              </MenuItem>

              {/* <MenuItem onClick={toPreloved} sx={menustyle}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
                <ListItemText>Resales</ListItemText>
              </MenuItem> */}

              <MenuItem onClick={handleSignOut} sx={menustyle}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                </svg>
                <ListItemText>Logout</ListItemText>
              </MenuItem>
            </>

            :
            <>
              <MenuItem onClick={handleSignIn} sx={menustyle}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>

                <ListItemText>{'Sign In'}</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleSignIn} sx={menustyle}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                </svg>
                <ListItemText>Sell/Dash</ListItemText>
              </MenuItem>
            </>
        }
      </Menu>

      {/*Mobile navigation */}
      <div ref={mobileNav}>
        <Transition
          show={mobileNavOpen}
          as="nav"
          id="mobile-nav"
          className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white"
          enter="transition ease-out duration-200 transform"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <ul className="flex flex-col items-center space-y-8 justify-center h-1/2">
            <Link to={'/'} onClick={() => setMobileNavOpen(!mobileNavOpen)}
              className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
              Home
            </Link>
            {
              user ? <>
                {/* <Link to={'/account'} onClick={() => setMobileNavOpen(!mobileNavOpen)}
                  className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                  Account
                </Link> */}
                <button onClick={handleSignOut}
                  className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                  Sign Out
                </button>
              </>
                : <li>
                  <button onClick={handleSignIn}
                    className="flex rounded items-center py-4 px-6 btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                    <span>Sell on DUKADASH</span>
                    <svg className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                    </svg>
                  </button>
                </li>
            }
          </ul>
        </Transition>
      </div>
    </div>
  )
}