import React, { useEffect, useState } from 'react'
import sparkle from '../assets/sparkle.gif'

const AIButton = ({ showAssistant, text, chatai, select }) => {


  return (
    <button className={`flex justify-center gap-2 items-center ${select===chatai?'border-2 border-red-900 text-red-900':'border'} rounded-lg p-2`}
      onClick={() => {
        showAssistant()
      }}>
      {chatai==='Self serve' && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z" />
      </svg>}

       {chatai==='Assisted' && <img className=" w-4" alt="" src={sparkle} />}
      <span className='font-semibold text-sm'>{text}</span>
    </button>
  )
}

export default AIButton