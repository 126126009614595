
import React, { Fragment, useEffect, useState } from 'react'
import { joinListWithCount, sleep, toProperCase } from '../helpers';
import { Dialog, Transition } from '@headlessui/react';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { captureRequest, getCategories, getMenu, getProducts } from '../firebaseConfig';
import MenuItem from '../components/MenuItem';

const Outlets = ({ user, pos, outlets }) => {
    const params = useParams()

    const [categories, setCategories] = useState([])
    const [outlet, setOutlet] = useState(null)
    const [naming, setNaming] = useState(false)
    const [requestor, setRequestor] = useState('')
    const [cart, setCart] = useState([])
    const [search, setSearch] = useState('')
    const [items, setItems] = useState(null)
    const [waiting, setWaiting] = useState(false);


    const handleRequestorChange = (event) => {
        const r = toProperCase(event.target.value.trim())
        setRequestor(r);
    }

    const handleDismiss = () => {
        setRequestor('')
        setNaming(false)
        setWaiting(false)
    }

    const handleRequestOutlet = async () => {
        setWaiting(true)
        const message_outlet = (rid, distance) => {
            const texts = []
            var total = 0
            cart.forEach((item, idx) => {
                var c = item.discounted ? item.discount : item.price
                // texts.push(`${item.name}${item.discounted?` ~@${item.price}/=~`:''} @ _${charge}/=_`)
                texts.push(`${item.name}`)
                total += c
            })
            const applink = `https://dukadash.com/request/${rid}`
            const hello = `${requestor.trim() === '' ? 'Hello, requesting' : `*${requestor}* here! Requesting`} delivery.`
            const text = joinListWithCount(texts)
            const date = new Date()
            const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" }).toUpperCase()
            const shortdate = date.toLocaleDateString("en-GB")
            const loc = `🧭 _My location:_\nhttps://www.google.com/maps/dir/?api=1&destination=${pos.lat},${pos.lng}`

            const message = `*DUKADASH* ${'```'}${shortdate}-${time}${'```'}\n${applink}\n\n\n${hello}\n${text}\n*Listed total: ${total}/=*\n\n\n${loc}`

            return encodeURIComponent(message)
        }

        const distance = parseFloat(outlet?.dist)//+parseFloat(deliver.dist)

        const data = {
            cart: cart.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    price: item.price,
                    discount: item.discount,
                    discounted: item.discounted
                };
            }),
            charge: 0,
            logged: new Date(),
            ids: [user?.uid, outlet?.id],
            user: { id: user?.uid ?? '', name: '', image: user?.image, location: pos },
            vendor: { id: outlet?.id ?? '', name: outlet?.title, image: outlet?.image, location: { lat: outlet?.lat, lng: outlet?.lng } },
            dasher: {},
            sent: false,
            received: false,
            paid: false,
        }
        // console.log(data)
        const rid = await captureRequest(data)
        window.location.href = `https://wa.me/${outlet?.phone}?text=${message_outlet(rid, distance)}`
        handleDismiss()
    }

    const handleCart = async (value, action) => {
        await sleep(15)

        // const dist = value.dist
        // const distval = dist < 1 ? (dist * 100) < 1 ? `${(dist * 100000).toFixed(2)} cm` : `${(dist * 1000).toFixed(2)} m` : `${dist.toFixed(2)} km`


        if (action === 'ADD') {
            // console.log(value.id, value.name)
            setCart([...cart, value])
            // setIsOpen(-1)
        } else {
            const index = cart.indexOf(value)
            if (index >= 0) {
                cart.splice(index, 1)
                // console.log(index)
                setCart([...cart])
            }
        }

    }

    useEffect(() => {
        if (params.id) {
            getMenu(params.id, pos).then((res) => { setOutlet(res) })
            getProducts(params.id, setItems)
        }
        const handleCategories = async () => {
            const cats = await getCategories()
            setCategories([{ index: 0, id: 'auto', name: 'All outlets' }, ...cats, { index: cats.length + 1, id: 'fin', name: 'etc' }])
        }
        handleCategories()
    }, [params])

    return (
        <section className="absolute top-20 bottom-0 left-0 right-0 flex flex-col justify-center items-center">
            <div className='flex flex-col h-full w-full md:max-w-lg'>
                <div className="py-4 px-4 flex gap-2 items-center">
                    <div className="shadow-card">
                        <div className='w-20 h-20 relative'>
                            <img alt=''
                                className={`border card-front-image card-image border-black w-full h-20 object-cover object-center rounded-md md:rounded-xl`}
                                loading='lazy'
                                src={outlet?.image} />
                        </div>
                        <div class="card-faders w-full">
                            {
                                Array(8).fill().map(() =>
                                    <img alt=''
                                        className={`card-fader card-image border w-full h-20 object-cover object-center rounded-md md:rounded-xl`}
                                        loading='lazy'
                                        src={outlet?.image} />
                                )
                            }
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between h-full items-start w-full flex-1 px-2">
                        <div className="flex flex-col justify-start items-stretch flex-1 px-2">
                            <span className={`font-semibold text-start text-[14px] text-black line-clamp-1`}>{`${outlet?.title ?? ''}`}</span>
                            <span className={`${outlet?.within ? 'text-green-600 font-light' : 'text-red-500 font-light'} text-start font-semibold rounded-full line-clamp-1 text-[8px]`}>
                                {outlet && `${outlet?.categories?.map((cat) => { return categories.find(i => cat === i.id)?.name })}  ${outlet?.within ? '' : ''} ${outlet?.dist * 5 < 60 ? `${(1 + outlet?.dist * 5)?.toFixed(0) ?? '--'} minute${(1 + outlet?.dist * 5)?.toFixed(0) === '1' ? '' : 's'} away` : `${((outlet?.dist * 5) / 60)?.toFixed(0) ?? '--'} ${((outlet?.dist * 5) / 60)?.toFixed(0) === '1' ? 'hour' : 'hrs'} away`}`}
                            </span>
                        </div>
                        <input
                            className={`border-2 w-full font-light py-2 px-4 text-xs text-start text-black line-clamp-1 overflow-hidden bg-white bg-opacity-80 rounded-full`}
                            placeholder={`Search outlet`}
                            autoFocus={false}
                            type='text'
                            onChange={(e) => { setSearch(e.target.value) }} />
                    </div>
                </div>

                <div className={`flex w-full flex-1 flex-col items-stretch text-gray-900 overflow-auto bg-white border-t border-b border-black`}>

                    {
                        items ?
                            <div className='w-full flex-1 flex flex-col items-center overflow-y-auto'>
                                <div className='flex flex-col w-full items-center flex-wrap justify-center'>

                                    {items?.filter((item) => item.name.toLowerCase().includes(search ?? ''.trim().toLowerCase()))?.filter((item) => (item.active)).map((item) => {
                                        return (
                                            <>
                                                {(item.enabled) && <MenuItem menuid={outlet?.id} cart={cart ?? []} data={item} handleCart={handleCart} editor={false} isparam={true && !(!user?.active || user?.active)} />}
                                            </>)
                                    }
                                    )}
                                </div>
                            </div>
                            :
                            <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                                <ReactLoading type="bubbles" color="black" />
                            </div>
                    }
                </div>

                <div className='w-full py-3 px-4 flex  bg-white justify-between space-x-4 items-center'>

                    <span className='font-bold text-xs'>{`Total: ${cart.reduce((acc, item) => {
                        const charge = item.discounted ? item.discount : item.price;
                        return acc + charge;
                    }, 0)
                        }/=`}</span>

                    <button
                        className="flex justify-center items-center rounded-full border bg-green-700 text-white px-4 text-xs font-medium disabled:text-gray-900 disabled:bg-gray-200  hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                        onClick={() => {
                            //setPickup(true)
                            setNaming(true)
                        }
                        }
                        disabled={!(cart.length > 0)}
                    >
                        <span className='py-2'>Request</span>

                    </button>


                </div>
                <Transition appear show={naming} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={() => handleDismiss()}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 px-3">
                            <div className="flex flex-col h-full items-center justify-center text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >{
                                        pos ?
                                            <Dialog.Panel className="flex flex-col w-full max-h-[90vh] max-w-md transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
                                                <span className='font-light text-center w-full'>Add a name to the request? (Optional)</span>

                                                <div className='flex flex-col flex-1 gap-2 justify-between items-stretch w-full h-full my-4 px-6 py-2 overflow-y-auto'>

                                                    <input placeholder='Your Name'
                                                        defaultValue={requestor}
                                                        type='text'
                                                        onChange={(e) => handleRequestorChange(e)}
                                                        className={`p-4 text-center text-lg font-light relative w-full cursor-default overflow-hidden rounded-xl bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ring-teal-100 focus-visible:ring-offset-teal-300`}
                                                    />
                                                </div>


                                                <div className='flex flex-row px-6 w-full justify-around items-center bg-white border-black'>

                                                    <button
                                                        type="button"
                                                        className="rounded-full px-4 py-2 text-sm font-semibold hover:text-gray-500 text-gray-400 bg-gray-100"
                                                        onClick={() => handleDismiss()}
                                                        disabled={waiting}
                                                    >


                                                        <span className='py-2'>Dismiss</span>
                                                    </button>

                                                    <button
                                                        type="button"
                                                        className="rounded-full border border-transparent px-4 py-2 text-sm font-semibold hover:text-green-950 text-green-800 bg-green-100"
                                                        onClick={() => handleRequestOutlet()}
                                                        disabled={waiting}
                                                    >

                                                        {waiting ?
                                                            <ReactLoading height={'32px'} width={'32px'} type="bubbles" color="black" />
                                                            :
                                                            <span className='py-2'>Continue</span>
                                                        }</button>
                                                </div>
                                            </Dialog.Panel>
                                            :
                                            <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                                                <ReactLoading type="bubbles" color="black" />
                                            </div>
                                    }
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </section>
    )
}

export default Outlets