import React from "react";

function Message({ msg, time, isLink, img, sent }){
  return (
    // Message container
    <div
      className={`relative rounded-md max-w-[90%] my-1 ${
        sent ? "bg-[#005c4b] ml-auto pl-2 mr-2" : "bg-[#202d33] mr-auto ml-2 pr-2"
      }`}
    >
        <div class={`${
        sent ? "bg-[#005c4b] -right-2 border-r-8 border-l-8 border-r-white" : "bg-[#202d33] -left-2 border-r-8 border-l-8 border-l-white"
      } absolute w-0 h-0 top-0 border-transparent border-t-8 border-b-8 border-b-transparent`}>

        </div>
      {/* Image message */}
      {img ? (
        <div className="relative w-100 p-2">
          {/* Image */}
          {/* <img
            src={img}
            alt="img_message"
            className="rounded-md max-w-[270px] w-100"
          /> */}
          {/* Time */}
          {/* <p className="absolute right-2 bottom-3 text-[#8796a1] text-[10px] min-w-[50px]">
            {time}
          </p> */}
        </div>
      ) : (
        // Text (link/normal) message
        <div
          className="p-2 min-w-[50px]"
          style={{ wordBreak: "break-word" }}
        >
          {/* Link */}
          {isLink ? (
            <a
              href={msg}
              target="blank"
              className="text-[#53beec] hover:text-[#53beec] focus:text-[#53beec] active:text-[#53beec] text-sm underline hover:underline mr-2"
            >
              {msg}
            </a>
          ) : (
            // Normal text
            <span className="text-white text-sm ">{msg}</span>
          )}
          {/* <p className="text-[#8796a1] text-[10px]">{time}</p> */}
        </div>
      )}
    </div>
  );
}

export default Message;
