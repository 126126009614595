import React, { Fragment, useEffect, useState } from 'react'
import Menu from '../pages/Menu'
import { captureRequest, getCategories, getDeliversNear, likeUnlike, signInWithGoogle } from '../firebaseConfig'
import { distances, handleSignIn, joinListWithCount, toProperCase } from '../helpers'
import ReactLoading from 'react-loading';
import { Dialog, Transition } from '@headlessui/react';
import ReactTimeago from 'react-timeago';
import { useNavigate } from 'react-router-dom';

const Listing = ({ item, handleFaves, user, pos, handleShare, categories, isOpen, setIsOpen, ai = false }) => {
    const image = item.image
    const dist = item.dist
    const desc = item.desc
    const requests = item.requests
    const within = item.within
    const base = 30


    const navigate = useNavigate();
    const toOutlet = () => {
        navigate(`/outlets/${item.id}`)
    }

    const distval = dist < 1 ? `${(dist * 1000)} m away` : `${dist} km away`

    const [naming, setNaming] = useState(false)
    const [requestor, setRequestor] = useState('')
    const [loading, setLoading] = useState(false)
    const [toggleSearch, setToggle] = useState(false)
    const [search, setSearch] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [cart, setCart] = useState([])
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [requesting, setRequesting] = useState(null)
    const [pickup, setPickup] = useState(false)
    const [delivers, setDelivers] = useState([])
    const [locerr, setLocErr] = useState('');
    const [locsugg, setLocSugg] = useState('')

    const handleRequestorChange = (event) => {
        const r = toProperCase(event.target.value.trim())
        setRequestor(r);
    }

    const here = async () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLocErr('')
                    setLocSugg('')
                    setLatitude(position.coords.latitude)
                    setLongitude(position.coords.longitude)

                },
                () => {
                    console.log("Browser doesn't support Geolocation")
                    setLocErr('Sorry, Geolocation is unsupported here or permissions were not given.')
                    setLocSugg('This is needed to suggest nearby vendors')
                    // handleLocationError(true, infoWindow, map.getCenter());
                }
            );
        } else {
            console.log("Browser doesn't support Geolocation")
            setLocErr('Sorry, Geolocation is unsupported here.')
            setLocSugg('Try a different browser.')
            // Browser doesn't support Geolocation
            // handleLocationError(false, infoWindow, map.getCenter());
        }
    }
    const onLike = (isLike) => {
        // setLiked(isLike)
        handleFaves()
    }


    useEffect(() => {
        getDeliversNear(item, setDelivers)
        setDisabled(!user || !user.active)

    }, [user, pos])

    const handleClick = () => {
        if (toggleSearch) {
            setSearch('')
        }
        setToggle(!toggleSearch)
    }
    const handleLike = (id, likes) => {
        if (user) {
            const data = {
                id: id,
                likes: likes,
                uid: user.uid
            }
            likeUnlike(data, onLike)
        } else {
            handleSignIn()
        }
    }


    const toggleRequest = async (b) => {
        setRequesting(b)
    }

    const handleRequestDasher = async (deliver) => {
        const message_dasher = (rid, deliver, distance) => {
            const texts = []
            var total = 0
            cart.forEach((item, idx) => {
                var c = item.discounted ? item.discount : item.price
                // texts.push(`${item.name}${item.discounted?` ~@${item.price}/=~`:''} @ _${charge}/=_`)
                texts.push(`${item.name}`)
                total += c
            })
            const applink = `https://dukadash.com/request/${rid}`
            const store = `*${item.title}?* Located here: https://www.google.com/maps/dir/?api=1&destination=${item.lat},${item.lng}`
            const hello = `Hello, can you get me the items above from ${store}\n`
            const text = `${applink}\n\n${joinListWithCount(texts)}`
            const date = new Date()
            const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" }).toUpperCase()
            const shortdate = date.toLocaleDateString("en-GB")
            const loc = `🧭 _Deliver to my current location:_\nhttps://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`
            const charge = new Intl.NumberFormat().format((base + (deliver.price * distance)).toFixed(0))

            return encodeURIComponent(`*DUKADASH* ${'```'}${shortdate}-${time}${'```'}\n${text}\n\n${hello}\n${loc}\n\n\n*Item Cost: ${total}/=*\n*Pickup Cost: ${charge}/=*\n\n*Total owed: ${(parseInt(charge) + parseInt(total))}/=*`)
        }
        await toggleRequest(deliver.id)
        const distance = parseFloat(item.dist)//+parseFloat(deliver.dist)

        const data = {
            cart: cart.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    price: item.price,
                    discount: item.discount,
                    discounted: item.discounted
                };
            }),
            charge: new Intl.NumberFormat().format((base + (deliver.price * distance)).toFixed(0)),
            logged: new Date(),
            ids: [user?.uid, item?.id, deliver?.id],
            user: { id: user?.uid ?? '', name: '', image: user?.image, location: { lat: latitude, lng: longitude } },
            vendor: { id: item?.id ?? '', name: item.title, image: item.image, location: { lat: item.lat, lng: item.lng } },
            dasher: { id: deliver?.id ?? '', name: deliver?.name ?? '', image: deliver?.image ?? '', location: { lat: deliver?.lat ?? '', lng: deliver?.lng ?? '' } },
            sent: false,
            received: false,
            paid: false,
        }
        // console.log(data)
        const rid = await captureRequest(data)
        window.location.href = `https://wa.me/${deliver?.phone}?text=${message_dasher(rid, deliver, distance)}`
        await toggleRequest(null)
    }

    const handleRequestOutlet = async () => {
        setLoading(true)
        const message_outlet = (rid, distance) => {
            const texts = []
            var total = 0
            cart.forEach((item, idx) => {
                var c = item.discounted ? item.discount : item.price
                // texts.push(`${item.name}${item.discounted?` ~@${item.price}/=~`:''} @ _${charge}/=_`)
                texts.push(`${item.name}`)
                total += c
            })
            const applink = `https://dukadash.com/request/${rid}`
            const store = `*${item.title}?* Located here: https://www.google.com/maps/dir/?api=1&destination=${item.lat},${item.lng}`
            const hello = `${requestor.trim() === '' ? 'Hello, requesting' : `*${requestor}* here! Requesting`} delivery.`
            const text = joinListWithCount(texts)
            const date = new Date()
            const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" }).toUpperCase()
            const shortdate = date.toLocaleDateString("en-GB")
            const loc = `🧭 _My location:_\nhttps://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`

            const message = `*DUKADASH* ${'```'}${shortdate}-${time}${'```'}\n${applink}\n\n\n${hello}\n${text}\n*Listed total: ${total}/=*\n\n\n${loc}`

            return encodeURIComponent(message)
        }

        const distance = parseFloat(item.dist)//+parseFloat(deliver.dist)

        const data = {
            cart: cart.map(item => {
                return {
                    id: item.id,
                    name: item.name,
                    phone: item.phone,
                    price: item.price,
                    discount: item.discount,
                    discounted: item.discounted
                };
            }),
            charge: 0,
            logged: new Date(),
            ids: [user?.uid, item?.id],
            user: { id: user?.uid ?? '', name: '', image: user?.image, location: { lat: latitude, lng: longitude } },
            vendor: { id: item?.id ?? '', name: item.title, image: item.image, location: { lat: item.lat, lng: item.lng } },
            dasher: {},
            sent: false,
            received: false,
            paid: false,
        }
        // console.log(data)
        const rid = await captureRequest(data)
        window.location.href = `https://wa.me/${item?.phone}?text=${message_outlet(rid, distance)}`
        setLoading(false)
        setRequestor('');
    }

    const handleSelect = () => {
        setIsOpen(isOpen.includes(item?.id) ? isOpen.filter(id => id !== item?.id) : [item?.id, ...isOpen]);

        setCart([])
        here()
        // navigateToMenu(item)
    }

    return (
        <div className={`${(isOpen.includes(item?.id)) ? 'border border-black shadow-sm mx-2' : ''} flex flex-col rounded-2xl overflow-clip bg-whitemy-1`}>
            <div className={`flex items-stretch w-full rounded-xl space-x-2 px-4 `}>

                <div className={`flex flex-col w-full items-stretch justify-start rounded-t-2xl flex-1`}>
                    <div className="py-4 flex gap-2 items-start">
                        <div className="shadow-card">
                            <div className='w-20 h-20 relative'>
                                <img alt=''
                                    className={`${item?.editor ? 'cursor-pointer' : ''} border card-front-image card-image border-black w-full h-20 object-cover object-center rounded-md md:rounded-xl`}
                                    loading='lazy'
                                    src={item?.image}
                                    onClick={toOutlet} />
                                {/* {pos && item?.dist &&
                                        <span className={`${item?.within ? 'text-green-600 font-light' : 'text-red-500 font-light'} border border-black w-5/6 mx-auto right-0 left-0 absolute top-2 z-[4] bg-white px-2 py-[1px] text-center font-semibold rounded-full line-clamp-1 text-[8px]`}>{`${item?.within ? '' : ''} ${item?.dist * 5 < 60 ? `${(1 + item?.dist * 5)?.toFixed(0) ?? '--'} minute${(1 + item?.dist * 5)?.toFixed(0) === '1' ? '' : 's'} away` : `${((item?.dist * 5) / 60)?.toFixed(0) ?? '--'} ${((item?.dist * 5) / 60)?.toFixed(0) === '1' ? 'hour' : 'hrs'} away`}`}</span>
                                    } */}
                            </div>
                            {(isOpen.includes(item?.id)) && <div class="card-faders w-full">
                                {Array(8).fill().map(() =>
                                    <img alt=''
                                        className={`${item?.editor ? 'cursor-pointer' : ''} card-fader card-image border w-full h-20 object-cover object-center rounded-md md:rounded-xl`}
                                        loading='lazy'
                                        src={item?.image} />
                                )
                                }
                            </div>}
                        </div>
                        <div className="flex flex-col justify-between h-full items-stretch flex-1 px-2">
                            <div className="flex flex-col justify-start items-stretch flex-1 px-2">
                                <span className={`font-semibold text-start text-[14px] py-1 text-black line-clamp-1`}>{`${item?.title}`}</span>
                                {pos && item?.dist &&
                                    <span className={`${item?.within ? 'text-green-600 font-light' : 'text-red-500 font-light'} text-start py-[1px] font-semibold rounded-full line-clamp-1 text-[8px]`}>{`${item.categories.map((cat) => { return categories.find(i => cat === i.id)?.name })}  ${item?.within ? '' : ''} ${item?.dist * 5 < 60 ? `${(1 + item?.dist * 5)?.toFixed(0) ?? '--'} minute${(1 + item?.dist * 5)?.toFixed(0) === '1' ? '' : 's'} away` : `${((item?.dist * 5) / 60)?.toFixed(0) ?? '--'} ${((item?.dist * 5) / 60)?.toFixed(0) === '1' ? 'hour' : 'hrs'} away`}`}</span>
                                }
                            </div>
                            <button className={`flex items-center justify-center gap-2 font-semibold rounded text-xs text-white bg-green-600 px-8 py-2`}
                                onClick={
                                    toOutlet
                                    // handleSelect
                                }>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                </svg>
                                <span className='text-center'>{(isOpen.includes(item?.id)) ? 'Close' : `Shop`}</span>
                            </button>
                        </div>
                    </div>

                    {(!isOpen.includes(item?.id)) && <div className='hidden justify-between'>

                        {/* <div className='flex-1 flex flex-col gap-1 items-center justify-between pb-4'>
                            <a className={`flex gap-2 fon text-xs text-start text-white bg-green-600 px-8 py-2`}
                                href={`tel://${item?.phone}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4">
                                    <path fillRule="evenodd" d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z" clipRule="evenodd" />
                                </svg>

                                <span>{`Call to Pre-Order`}</span>
                            </a>
                        </div> */}
                        <button className='w-full py-4 text-sm font-bold text-black border-t bg-white'
                            onClick={
                                handleSelect
                            }>
                            <span>{`Shop`}</span>
                        </button>
                        {/* <div className='flex justify-center items-center'>
            <span className={`text-black px-2 py-[1px] rounded line-clamp-1 text-[8px]`}>{requests}</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                <path fillRule="evenodd" d="M4.25 2A2.25 2.25 0 0 0 2 4.25v11.5A2.25 2.25 0 0 0 4.25 18h11.5A2.25 2.25 0 0 0 18 15.75V4.25A2.25 2.25 0 0 0 15.75 2H4.25ZM15 5.75a.75.75 0 0 0-1.5 0v8.5a.75.75 0 0 0 1.5 0v-8.5Zm-8.5 6a.75.75 0 0 0-1.5 0v2.5a.75.75 0 0 0 1.5 0v-2.5ZM8.584 9a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5a.75.75 0 0 1 .75-.75Zm3.58-1.25a.75.75 0 0 0-1.5 0v6.5a.75.75 0 0 0 1.5 0v-6.5Z" clipRule="evenodd" />
            </svg>
        </div> */}
                    </div>}
                </div>


                {/* <button
                    onClick={handleClick}>
                    {toggleSearch ?
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                        </svg>
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                            <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                        </svg>

                    }

                </button> */}

                {/* <button
                    onClick={() => handleLike(item?.id, item?.likes)}>
                    {liked ?
                        <Liked />
                        :
                        <Like />

                    }

                </button> */}

                {/* {item?.id && <button
                    onClick={() => handleShare(item)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                        <path d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z" />
                    </svg>
                </button>} */}
            </div>
            {
                (isOpen.includes(item?.id)) && <>
                    <div className={`flex items-center border-b border-t border-b-black bg-white py-4 space-x-2 px-4`}>
                        {toggleSearch ?
                            <input
                                className={`${item?.editor ? 'border-green-400' : 'border-white'} border-2 w-full h-10 md:h-12 font-light py-2 px-4 text-xs text-center text-black line-clamp-1 overflow-hidden bg-white bg-opacity-80 rounded-full`}
                                placeholder={item?.title}
                                autoFocus='false'
                                type='text'
                                onChange={(e) => { setSearch(e.target.value) }} />
                            :
                            <>
                                <img alt='' src={item?.image}
                                    className={`${item?.editor ? 'cursor-pointer' : ''} border-white border-0 ml-1 w-8 h-8 md:w-10 md:h-10 object-cover object-center rounded-full bg-slate-500`}
                                    loading='lazy' />
                                <button className='flex-1 flex flex-col items-center justify-center '
                                    onClick={handleClick}>
                                    <span
                                        className={`font-light px-2 py-1 text-xs text-center text-black `}
                                    >{item?.title}</span>

                                    {/* {pos && item?.dist && <span className={`${item?.within ? 'bg-green-500 font-light' : 'bg-red-500 font-light'} text-white px-2 py-[1px] rounded line-clamp-1 text-[8px]`}>{`${item?.within ? '' : ''} ${item?.dist < 1 ? `${(item?.dist * 1000) ?? '--'} m away` : `${item?.dist ?? '--'} km away`}`}</span>} */}
                                </button>
                            </>
                        }

                        <button onClick={handleClick}>
                            {toggleSearch ?
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                    <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
                                </svg>
                            }
                        </button>


                        {
                            /* {item?.id && <button
                                onClick={() => handleShare(item, false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4">
                                    <path d="M13 4.5a2.5 2.5 0 11.702 1.737L6.97 9.604a2.518 2.518 0 010 .792l6.733 3.367a2.5 2.5 0 11-.671 1.341l-6.733-3.367a2.5 2.5 0 110-3.475l6.733-3.366A2.52 2.52 0 0113 4.5z" />
                                </svg>
                            </button>} */
                        }
                    </div>

                    <div className='w-full flex-1'>

                        <Menu editor={false} user={user} menuid={item.id} search={search} isparam={true && !(!user?.active || user?.active)} cart={cart} setCart={setCart} />
                    </div>

                    <div className='w-full py-2 px-4 flex  border-t border-black bg-white justify-between space-x-4 items-center'>

                        <span className='font-bold text-xs'>{`Total: ${cart.reduce((acc, item) => {
                            const charge = item.discounted ? item.discount : item.price;
                            return acc + charge;
                        }, 0)
                            }/=`}</span>

                        <button
                            className="flex justify-center items-center rounded-full border bg-green-700 text-white px-4 text-xs font-medium disabled:text-gray-900 disabled:bg-gray-200  hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={() => {
                                //setPickup(true)
                                setNaming(true)
                            }
                            }
                            disabled={!(cart.length > 0)}
                        >
                            <span className='py-2'>Request</span>

                        </button>


                    </div>
                    <button className='w-full py-4 text-sm font-bold text-black border-t bg-white'
                        onClick={handleSelect}>
                        {`Close ${item?.title}`}
                    </button>
                </>
            }

            <Transition appear show={pickup} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setPickup}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 px-3">
                        <div className="flex flex-col h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >{
                                    pos ?
                                        <Dialog.Panel className="flex flex-col w-full max-h-[90vh] max-w-md transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 px-6" > <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                            </svg>Dukadashers near {item.title}!<br /><span className='font-semibold text-sm'>Insist on paying after delivery</span></Dialog.Title>

                                            <div className='bg-gray-200 flex flex-col flex-1 gap-2 justify-between items-stretch w-full h-full border-t border-b my-4 px-3 py-2 overflow-y-auto'>
                                                {

                                                    delivers.map((deliver, idx) => {
                                                        return (

                                                            <div key={idx} className={`border shadow-md rounded p-2 flex flex-col justify-between items-start bg-white gap-1 px-2 text-sm font-medium`}>
                                                                <div key={idx} className='flex flex-row w-full justify-start items-start gap-2 border-b px-1 pt-2 pb-1'>
                                                                    <img alt='' src={deliver.image} className={`w-6 h-6 border object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                                                    <div className='flex-1 flex flex-col justify-start items-start gap-0'>
                                                                        <span className={`my-0 text-sm text-start font-semibold ${(deliver?.dist) > 1 ? 'text-red-600' : 'text-green-600'}`}>{`${deliver.name}`}</span>
                                                                        {/* <span className={`my-0 text-[8px] px-[2px] text-center font-semibold text-gray-600`}>{`${deliver.vehicle.name}`}</span> */}
                                                                        {/* <span className={`my-0 text-[8px] text-center font-semibold`}>{`${(deliver?.dist)} km from the outlet`} <ReactTimeago date={deliver.updated} minPeriod={60} /></span> */}
                                                                        {/* <span className={`my-0 text-[8px] text-center font-semibold`}>Updated <ReactTimeago date={deliver.updated} minPeriod={60} /></span> */}
                                                                        <span className='my-0 text-xs text-center font-semibold'>{`${new Intl.NumberFormat().format((base + (deliver.price * parseFloat(item.dist)/*+parseFloat(deliver.dist)*/)).toFixed(0))}/=`}</span>
                                                                    </div>
                                                                    <div className={`flex flex-col justify-start items-end`}>
                                                                        <div className='flex w-full flex-row gap-8 py-2 px-1 items-center justify-between'>
                                                                            {/* <a className={`flex gap-2  text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                                                        href={`tel://${deliver.phone}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                                                                            <path fill-rule="evenodd" d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z" clip-rule="evenodd" />
                                                                        </svg>
                                                                        <span>{`Call`}</span>
                                                                    </a> */}
                                                                            <div className='flex justify-center items-center flex-1'>
                                                                                {requesting === deliver.id ?
                                                                                    <ReactLoading height={'16px'} width={'32px'} type="bubbles" color="black" />
                                                                                    :
                                                                                    <button className={`flex gap-2 text-xs min-w-fit text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                                                                        onClick={() => {
                                                                                            if (user) { handleRequestDasher(deliver) } else { signInWithGoogle(() => handleRequestDasher(deliver)) }
                                                                                        }}>
                                                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                                                                        </svg> */}
                                                                                        <span>{`Select`}</span>
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <span className='text-center w-full text-[10px] font-light  py-1 px-2'>{deliver.vehicle.name}</span>

                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </div>


                                            <div className='flex flex-row px-6 justify-between items-center gap-3 bg-white border-black'>

                                                <button
                                                    type="button"
                                                    className="rounded border border-transparent px-4 py-2 text-sm font-medium  text-gray-900 bg-gray-100"
                                                    onClick={() => setPickup(false)}
                                                >Dismiss</button>
                                            </div>
                                        </Dialog.Panel>
                                        :
                                        <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                                            <ReactLoading type="bubbles" color="black" />
                                        </div>
                                }
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

            <Transition appear show={naming} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setNaming}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 px-3">
                        <div className="flex flex-col h-full items-center justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >{
                                    pos ?
                                        <Dialog.Panel className="flex flex-col w-full max-h-[90vh] max-w-md transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
                                            <span className='font-semibold text-sm text-center w-full'>Add a name to the request? (Optional)</span>

                                            <div className='bg-gray-200 flex flex-col flex-1 gap-2 justify-between items-stretch w-full h-full border-t border-b my-4 px-6 py-2 overflow-y-auto'>

                                                <input placeholder='Your Name'
                                                    defaultValue={requestor}
                                                    type='text'
                                                    onChange={(e) => handleRequestorChange(e)}
                                                    className={`p-4 text-center text-lg font-light relative w-full cursor-default overflow-hidden rounded-xl bg-white  shadow-md ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ring-teal-100 focus-visible:ring-offset-teal-300`}
                                                />
                                            </div>


                                            <div className='flex flex-row px-6 w-full justify-between items-center bg-white border-black'>

                                                <button
                                                    type="button"
                                                    className="rounded-full border border-transparent px-4 py-2 text-sm font-semibold  text-gray-900 bg-gray-100"
                                                    onClick={() => setNaming(false)}
                                                    disabled={loading}
                                                >


                                                    <span className='py-2'>Dismiss</span>
                                                </button>

                                                <button
                                                    type="button"
                                                    className="rounded-full border border-transparent px-4 py-2 text-sm font-semibold  text-green-900 bg-green-100"
                                                    onClick={() => handleRequestOutlet()}
                                                    disabled={loading}
                                                >

                                                    {loading ?
                                                        <ReactLoading height={'32px'} width={'32px'} type="bubbles" color="black" />
                                                        :
                                                        <span className='py-2'>Continue...</span>
                                                    }</button>
                                            </div>
                                        </Dialog.Panel>
                                        :
                                        <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                                            <ReactLoading type="bubbles" color="black" />
                                        </div>
                                }
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>

        </div>
    )
}

export default Listing