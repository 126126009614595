import React from 'react'
import logo from '../assets/logo.png'
import gif from '../assets/7dy7.gif'
import ReactLoading from 'react-loading';

const Splash = ({ pos }) => {
  return (
    <div className='w-screen h-screen max-h-[100vh] flex flex-col items-center justify-center gap-2 px-8 pb-28'>

      <img className='my-0 mx-6 logo' src={logo} width={80} height={80} alt='logo' />
      <span className=' font-light text-3xl text-red-700 tracking-widest text-center line-clamp-2'>{`DUKADASH`}</span>
      <h1 className="text-4xl px-2 text-center md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
          Send for it
        </span>
      </h1>

      {/* <img className='my-0 mx-6 bg-black rounded-md' src={gif} width={80} height={80} alt='logo' /> */}
      <ReactLoading type='bubbles' color='#b91c1c' height={'20px'} width={'40px'} />
      {!pos && <div className='mt-16 flex flex-col px-8 space-y-4 items-center justify-center'>

        <span className='text-lg font-light text-blue-600 max-w-sm text-center'>Location access is required to show nearby dukas. Use a regular browser then enable to continue!</span>

      </div>}
    </div>
  )
}

export default Splash