// import VideoThumb from '@/public/images/hero-image.png'
// import ModalVideo from '@/components/modal-video'

import { useNavigate } from "react-router-dom";
import { COLLECTIONS, db, getUser, signInWithGoogle } from "../firebaseConfig"
import { useEffect, useState } from "react";
import { handleSignIn, sleep, validatePhoneNumber } from "../helpers";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import ImageToLink from "./ImageToLink";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import hero from '../assets/affordability-hero.webp'
import Donate from "./Donate";
import AddDuka from "./AddDuka";

export default function Hero({ user, handleLocation }) {
  const navigate = useNavigate()

  const periods = [
    // { key: 'day', days: 1, min: 69, moment: 1 },
    { key: 'week', days: 7, min: 299, moment: 1 },
    { key: 'month', days: 28, min: 999, moment: 1 },
    { key: 'months', days: 28 * 3, min: 1999, moment: 3 },
    // { key: 'year', days: (28 * 13), min: 4999, moment: 1 },
  ]

  const [period, setPeriod] = useState(periods[1])

  const [value, loading, error] = useDocument(doc(db, COLLECTIONS.CONSTS, "app"), { snapshotListenOptions: { includeMetadataChanges: true } });


  const handlePeriod = (p) => {
    setPeriod(p)
  }

  const handleSignIn = async () => {
    await signInWithGoogle()
    // handleAccount()
  }


  return (
    <section className="pt-20 relative">
      {/* <div className="flex flex-col items-center justify-center max-w-lg mx-auto md:px-16 px-8 min-h-[15vh] text-center place-content-center bg-cover bg-no-repeat bg-center overlay" style={{backgroundImage:`url(${hero})`}}>
        <span className="text-white text-xl font-light">Need anything? There's an outlet nearby!</span>
      </div> */}
    </section>
  )
}