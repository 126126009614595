import React from 'react'
import { useNavigate } from 'react-router-dom';


const RequestData = ({ item, clickable=false }) => {
    const navigate = useNavigate();

    const toRequest = () => {
        navigate(`/request/${item.id}`)
    }
    return (

        <button className={`flex flex-col justify-center items-stretch w-full border-2 px-2 py-1 max-w-lg ${clickable?'hover:border-black':''}`}
        disabled={!clickable}
            onClick={toRequest}>
            <div className='flex flex-row justify-start items-stretch gap-2'>
                <img alt='' src={item?.vendor?.image}
                    className={`h-8 w-8 object-cover my-2 object-center rounded-full bg-slate-500`}
                    loading='lazy' />
                <div className='flex flex-col justify-center items-start flex-1'>
                    <span className='font-semibold text-sm text-start'>{`${item?.vendor?.name ?? '--'}`}</span>
                    <span className='font-light font-mono text-[8px] text-center'>{`${item?.date ?? '--/--/----'}`}</span>
                </div>
            </div>
            <div className='flex flex-col justify-start items-start border my-1 py-2 px-2 w-full'>
                {item?.list ? item?.list.map((i) => <span className='text-xs'>{i}</span>)
                    : <p>--</p>}
                <span className='font-semibold text-xs'>Total: {item?.total ?? "--"}/=</span>
            </div>
        </button>
    )
}

export default RequestData