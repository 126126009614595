import React, { useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Button, Fab, IconButton, Link } from '@mui/material';
import { joinListWithCount, toTitleCase } from '../helpers';
import SendRoundedIcon from '@mui/icons-material/SendRounded';

const MenuItem = ({menuid, data, handleCart, cart, editor, isparam }) => {
  const image = data.image
  const name = toTitleCase(data.name)
  const until = data.until
  const dist = data.dist
  const id = data.id
  const distval = dist < 1 ? `(${(dist * 1000).toFixed(2)}m away)` : `(${dist.toFixed(2)} km away)`
  // const distval = dist < 1 ? (dist * 100) < 1 ?  `${(dist * 100000).toFixed(2)} cm` : `${(dist * 1000).toFixed(2)}m` : `${dist.toFixed(2)} km`
  const enabled = data.enabled
  const active = data.active
  const uname = data.uname
  const discount = `${data.discount}${data.symbol}`
  const discounted = data.discounted && data.discount !== ''
  const price = `${data.price}${data.symbol}`


  return (
    <div className={`w-[100%] ${menuid? '':editor ? 'md:w-2/3' : 'md:w-2/3'}`}>
    {/* <div className='absolute h-20 md:h-28 flex text-gray-950 bg-cover bg-cener bg-slate-200 mx-2 mt-2'
      style={{ backgroundImage: `url(${image})`, backgroundRepeat: 'repeat-x' }}>
        </div> */}
      <div className={`${editor?' mx-8':''} h-20 md:h-24 flex text-gray-950 bg-cover bg-center mt-0`}>
        <div className={`${cart?.includes(data) ? ' bg-green-300' : 'md:hover:bg-blue-300 md:hover:bg-opacity-50'} flex flex-1 px-2`}
          style={{ backdropFilter: 'blur(18px)' }}>

          {/* menu card */}
          <button disabled={isparam}
          onClick={() => {
            handleCart(data, 'ADD');
          }}
            style={{ backdropFilter: 'blur(16px)' }}
            className={`my-1 overflow-clip border rounded-lg md:rounded-2xl flex flex-1 flex-row items-stretch justify-center`}>
            <div className='w-16 md:w-24 h-20 md:h-24'>
              <img alt='item_photo' src={image} className={`${enabled ? '' : 'grayscale'} h-full w-full object-cover object-center`} loading='lazy' />
            </div>
            <div className={`flex flex-row flex-1 bg-opacity-50 bg-white ${editor?'':'items-center'}`}>
              {/* item details */}
              <div className='flex flex-col flex-1 justify-center items-stretch px-3 '>
                {/* <span className={` bg-opacity-70 border-x ${'border-red-700 bg-white text-red-700'} rounded-md align-middle text-center pl-3 pr-2 md:py-1 text-[8px] md:text-md font-medium`}>{`${uname} ${distval}`}</span> */}
                <span className={`${enabled ? '' : 'line-through decoration-black'} text-[13px] md:text-2xl font-semibold md:font-bold text-start line-clamp-2`}>
                  {`${cart.includes(data) ? `${cart.filter((i) => i.id === id).length} * ` : ''} ${(name)}`}
                </span>
                <div className='flex flex-row space-x-4 items-center'>
                  {<span className={`${discounted ? 'line-through decoration-black' : ''} text-[10px] md:text-lg font-light text-start`}>{price}</span>}
                  {<span className={`${discounted ? '' : 'hidden'} align-middle rounded px-2 bg-red-500 text-[10px] md:text-lg font-semibold text-white`}>{discount}</span>}
                </div>
              </div>
              {/* buying */}
              <div className='my-2'>
                {editor && <span className={` border-s ${!active ? 'bg-white  border-red-500 text-red-500' : 'bg-green-500 border-white text-white'} rounded-s-md align-middle text-center px-4 py-1 text-[11px] md:text-lg font-semibold`}>{active ? until : 'INACTIVE'}</span>}
              </div>

              {!editor && !isparam && <div className='p-4 text-green-900'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              </div>}
            </div>

          </button>
          {!editor && cart.includes(data) && <>
            <div className='flex flex-row justify-between items-center space-x-4 m-1 rounded bg-black bg-opacity-50'>
              <IconButton variant="outlined"
                onClick={() => { handleCart(data, '') }}>
                <RemoveCircleOutlineIcon className=' text-white' />
              </IconButton>
            </div>
          </>
          }
        </div>
      </div>

    </div>
  )
}

export default MenuItem