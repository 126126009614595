import React from 'react'
import { handleSignIn } from '../helpers'
import { useNavigate } from 'react-router-dom';

function AddDuka({user, handleLocation, text}) {
    const navigate = useNavigate();
    const handleAccount = () => {
      // 👇️ navigate
      if (user?.isVendor) {
        navigate(`/account`, { uid: user.uid })
      } else {
        handleLocation(true)
      }
    }
  return (
      <button
        onClick={user ? handleAccount : handleSignIn}
        className="m-2 flex-1  min-w-fit inline-flex justify-center items-center rounded-md border border-transparent bg-green-100 disabled:bg-gray-300 disabled:text-gray-900 px-3 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2">
        <span className='mx-2 font-semibold text-sm'>{user ? `${text}` : `${text}`}</span>
        {/* <svg className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
        </svg> */}
      </button>
  )
}

export default AddDuka