import React, { Fragment, useEffect, useRef, useState } from 'react'
import MenuItem from './MenuItem'
import AddMenuItem from './AddMenuItem'
import SellPrompt from './SellPrompt'
import { Dialog, Switch, Transition } from '@headlessui/react'
import { addSharable, getProducts, getUser, grabNearby } from '../firebaseConfig'
import ReactLoading from 'react-loading';
import { BrowserNotSupportedTwoTone, Face2Outlined, Face3, FaceOutlined, FoodBank, Refresh, Share } from '@mui/icons-material'
import { Alert, Button, Collapse } from '@mui/material'
import { shareOnMobile } from 'react-mobile-share'
import CopyToClipboard from 'react-copy-to-clipboard'
import CircularProgressWithValue from './CircularProgressWithValue'
import { validatePhoneNumber } from '../helpers'
import { useNavigate } from 'react-router-dom'


const MenuList = ({ menuid, state, items, editor, handleCart, cart, user, handleNew, locerr, locsugg, handleMenu, isparam }) => {

  const id = user?.uid
  const title = (state?.title)//.toUpperCase()
  const navigate = useNavigate();

  const [user_data, setUser] = useState(null)
  const [link, setLink] = useState(null)
  const [share, setShare] = useState(false)
  const [copied, setCopy] = useState(false)
  const [phone, setPhone] = useState(state?.phone)
  const [canWithdraw, setCanWithdraw] = useState(false)
  const [withdraw, setWithdraw] = useState(false)
  const withdrawThresh = 500

  const [uploading, setUploading] = useState(false)
  const [isListing, setIsListing] = useState(false)
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [files, setFiles] = useState([])


  const closeListing = (id) => {
    setIsListing(false)
    setName('')
    setDesc('')
    setUploading(false)
    setFiles([])
  }
  const handleNameChange = (event) => {
    setName(event.target.value);
  }
  const handleDescChange = (event) => {
    setDesc(event.target.value);
  }
  const handleFileChange = (event) => {
    const files = event.target.files;
    setFiles([...files]);
  }
  const handleShareClose = () => {
    setShare(false);
    setCopy(false);
    closeListing()
    closeModal()
  }
  const handleShareCopy = () => {
    setCopy(true);
  }
  const openModal = () => {
    setUploading(false)
    setLink(null)
    setCopy(false)
    setIsListing(true)
  }
  const closeModal = () => {
    setIsListing(false)
  }
  const handleUpload = async () => {
    setUploading(true)
    const data = {
      title: name,
      uid: user?.uid ?? '',
      menuid: menuid,
      description: desc,
      caption: desc
    }

    if (files.length > 0) {
      const link = await addSharable(files, data, closeListing)
      setLink(link)
    } else {
      setUploading(false)
      console.log('no file')
    }
  }

  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value
    const result = validatePhoneNumber(phoneNumber);
    // console.log(result, phoneNumber)
    if (result.result !== "invalid") {
      setPhone(result.result);
    } else {
      setPhone('')
    }
  }

  const handleClick = () => {
    handleMenu(state.id)
  }
  const closeWithdraw = () => {
    setWithdraw(false)
  }
  const openWithdraw = () => {
    setCanWithdraw(state?.balance >= withdrawThresh)
    setWithdraw(true)
  }
  const message = (m) => {
    return encodeURIComponent(m)
  }
  const handleWithdraw = () => {
    var total = state?.balance
    const date = new Date()
    const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" }).toUpperCase()
    const shortdate = date.toLocaleDateString("en-GB")
    const text = 'Withdrawal Request'
    const m = `*DUKADASH* ${'```'}${shortdate}-${time}${'```'}\nAccount: ${title}\nID: ${state?.id}\n\n${text}\n*Total: ${total}/=*`
    window.open(`https://wa.me/254745978124?text=${message(m)}`, '_blank');
  }

  const handleChat = () => {
    const date = new Date()
    const time = date.toLocaleTimeString([], { hour12: true, hour: "2-digit", minute: "2-digit" }).toUpperCase()
    const shortdate = date.toLocaleDateString("en-GB")
    const text = 'Hey there! Came by by your store on DUKADASH and I would like to chat as a potential client.'
    const m = `*DUKADASH* ${'```'}${shortdate}-${time}${'```'}\n\n\n*_${title}_*\n${text}`
    window.open(`https://wa.me/${state?.phone}?text=${message(m)}`, '_blank');
  }

  const handleVisit = (lat, lng) => {
    window.open(`https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`, '_blank');
  }

  const handleNav = () => {
    if (state.editor) {
      navigate('/account')
    }
  }


  useEffect(() => {
    user && getUser(user.uid, setUser).then((unsub) => { })
  }, [user])

  return (
    <section className="h-full">
      <div className={`${menuid && !editor ? '' : 'py-20'} flex flex-col items-stretch h-full`}>

        {/* title */}
        {editor && user?.uid && state &&
          <div className='flex flex-col justify-center md:justify-between items-center py-2 mx-8 flex-wrap'>

            <button
              onClick={() => handleClick()}
              className='flex flex-col justify-center md:justify-between items-center bg-white space-y-2 py-2 rounded-xl px-4 shadow hover:shadow-lg'
            >
              <img alt='' src={state?.image} className={`w-16 h-16 md:w-16 md:h-16 object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
              <span className='font-semibold text-xl text-center'>{`${title} (${state.active ? 'Listed' : 'Unlisted'})`}</span>
            </button>
            {state && state.prompt && <>
              {/* <button
              onClick={openWithdraw}
              className={`rounded-full px-8 py-1 hover:underline ${canWithdraw ? 'hover:text-green-600' : 'hover:text-orange-600'}`}
            >
              <span className='font-semibold text-xs text-center'>{`Sales Balance: ${state?.balance}/=`}</span>
            </button> */}
              {/* <CircularProgressWithValue8 value={state.sales / 10} target={state.bonus} /> */}
            </>}

          </div>
        }
        {editor && user?.uid && <AddMenuItem mid={id} editor={editor} user={user} handleNew={handleNew} />}
        {
          items ?
            <div className='w-full flex-1 flex flex-col items-center overflow-y-auto'>
              {/* <div className='flex  justify-around items-center'>
                <button
                  onClick={openModal}
                  className="m-2 inline-flex justify-center items-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2">
                  <span className='mx-2 text-xs'>New image to link</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                  </svg>
                </button>

              </div> */}
              {/* <div className='flex justify-around'>
                <button
                  onClick={handleChat}
                  className="m-2 inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2">
                  <span className='mx-2 text-xs'>Chat</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 01-2.555-.337A5.972 5.972 0 015.41 20.97a5.969 5.969 0 01-.474-.065 4.48 4.48 0 00.978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25z" />
                  </svg>
                </button>
                {state?.lat && state?.lng && <button
                  onClick={() => handleVisit(state?.lat, state?.lng)}
                  className="m-2 inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
                  </svg>
                  <span className='mx-2 text-xs'>Visit</span>
                </button>}
              </div> */}
              <div className='flex flex-col w-full items-center flex-wrap justify-center pb-4'>

                {items.filter((item) => (editor || item.active)).map((item) => {
                  return (
                    <>
                      {(item.enabled || editor) && <MenuItem menuid={menuid} cart={cart??[]} data={item} handleCart={handleCart} editor={editor} isparam={isparam} />}
                    </>)
                }
                )}
              </div>
            </div>
            :
            locerr !== '' ?
              // let them try another browser
              <div className="flex flex-col  mt-4 items-center justify-center">
                <div className="text-xs flex flex-col items-center justify-center mb-4">
                  <span className=' text-center'>{locerr}</span>
                  <span className=' text-center'>{locsugg}</span>
                </div>
                <Button variant="outlined" onClick={() =>
                  shareOnMobile(
                    {
                      text: '',
                      url: `https://dukadash.com/`,
                      title: 'DUKADASH',
                    },
                    () => {
                      setShare(true)
                      console.log('sharing')
                    }
                  )}
                  sx={{
                    //backgroundColor: "green",
                    borderColor: "rgb(185,28,28)",
                    color: "rgb(185,28,28)",
                    borderRadius: 999,
                    textTransform: "none",
                    fontSize: 15,
                    fontWeight: "400px",
                    fontFamily: [
                      '-apple-system',
                      'BlinkMacSystemFont',
                      '"Segoe UI"',
                      'Roboto',
                      '"Helvetica Neue"',
                      'Arial',
                      'sans-serif',
                      '"Apple Color Emoji"',
                      '"Segoe UI Emoji"',
                      '"Segoe UI Symbol"',
                    ].join(','),
                    '&:hover': {
                      // backgroundColor: "green",
                      borderColor: "rgb(185,28,28)",
                      color: "rgb(185,28,28)",
                      fontWeight: "600px"
                    }
                  }}>{"Try another browser"}
                  <Share
                    sx={{
                      color: "rgb(185,28,28)",
                      fontSize: 16,
                      mt: 0.2,
                      mx: 1,
                      '&:hover': {
                        color: "rgb(185,28,28)"
                      }
                    }} />
                </Button>
              </div>
              :
              <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                <ReactLoading type="bubbles" color="black" />
              </div>
        }
        {/* <SellPrompt user={user} err={locerr !== ''} handleNew={handleNew} /> */}

      </div>

      <Transition appear show={share} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={handleShareClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Copy and paste link elsewhere:
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className='flex flex-col space-y-4'>
                      <span style={{ fontSize: '12px', fontWeight: '700' }}>
                        {`https://dukadash.com/`}
                      </span>

                      <Collapse in={copied}>
                        <Alert severity="success" sx={{ mb: 2 }}>
                          Link copied!
                        </Alert>
                      </Collapse>
                    </div>
                  </div>

                  <div className="flex items-center mt-4">
                    <button onClick={handleShareClose}
                      className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                      Dismiss
                    </button>
                    <CopyToClipboard
                      text={`https://dukadash.com/`}
                      onCopy={handleShareCopy}>
                      <button
                        className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                      >Copy
                      </button>
                    </CopyToClipboard>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={withdraw} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeWithdraw}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Congratulations on your success!
                  </Dialog.Title>
                  <div className="mt-2">
                    <div className='flex flex-col space-y-4'>
                      <span className='font-light text-lg'>
                        Your sales balance of <b>{state?.balance}/=</b> will be sent to the below Mpesa Number
                      </span>
                      <input placeholder='Whatsapp Number' type='text' onChange={(e) => handlePhoneChange(e)}
                        defaultValue={''}
                        className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />

                    </div>
                  </div>

                  <div className="flex items-center justify-between mt-8">
                    <button onClick={closeWithdraw}
                      className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                      Dismiss
                    </button>
                    {canWithdraw ? <button
                      onClick={handleWithdraw}
                      className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                    >Submit Withdrawal Request
                    </button>
                      :
                      <button
                        disabled={true}
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        <span>Withdral minimum is {withdrawThresh}/=</span>
                      </button>

                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>



      {user && <Transition appear show={isListing} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >{
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Upload the image you want to share!
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className='flex flex-col space-y-4'>
                        <input placeholder='Title' type='text' onChange={(e) => handleNameChange(e)}
                          className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                        <input placeholder='Single line caption' type='text' onChange={(e) => handleDescChange(e)}
                          className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                        <label for="avatar">Photo(s) to share:</label>
                        <div className=' flex flex-wrap'>

                          {files.length > 0 && files.map((file) => {
                            const image = URL.createObjectURL(file)
                            return (
                              <img alt='item_photo' src={image} className='w-16 md:w-24 h-16 md:h-24 object-cover object-center rounded m-1' loading='lazy' />
                            )
                          })}
                        </div>
                        <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                          onChange={handleFileChange} multiple />

                        <Collapse in={copied}>
                          <Alert severity="success" sx={{ mb: 2 }}>
                            {`Link copied! ${link}`}
                          </Alert>
                        </Collapse>
                      </div>
                    </div>

                    <div className="mt-4">
                      {

                        link ?

                          <div className="flex items-center mt-4">
                            <CopyToClipboard
                              text={link}
                              onCopy={handleShareCopy}>
                              <button
                                className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                              >Copy
                              </button>
                            </CopyToClipboard>
                            <button onClick={handleShareClose}
                              className="font-medium text-gray-600 hover:text-red-400 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                              Dismiss
                            </button>
                          </div> : uploading ?
                            <ReactLoading type="bubbles" color="black" />

                            : <button
                              disabled={
                                name === ''
                                || !files
                                || desc === ''
                              }
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                              onClick={handleUpload}
                            >
                              Create Link
                            </button>
                      }
                    </div>
                  </Dialog.Panel>
                }
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>}
    </section>
  )
}

export default MenuList