import React, { Fragment, useEffect, useState } from 'react'
import Menu from './Menu'
import CircularProgressWithValue from '../components/CircularProgressWithValue'
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import { useParams } from 'react-router-dom';

const Account = ({prod, handleTitle, editor, user, menuid, search, handleLocation }) => {

  const params = useParams();
  const [cart, setCart] = useState([])

  const [curr_user, user_loading, user_error] = useAuthState(auth);
  useEffect(()=>{

  })

  return (
    <div className=''>
        <Menu prod={prod} editor={true} user={user??curr_user}  handleLocation={handleLocation} menuid={params.id} cart={cart} setCart={setCart} />
    </div>
  )
}

export default Account