import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Menu from './pages/Menu';
import Landing from './pages/Landing';
import Header from './components/ui/header';
import { Fragment, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, captureDeliver, getCategories, getDeliverUser, getUser, signInWithGoogle, updateDeliverInfo } from './firebaseConfig';
import Request from './pages/Request';
import Account from './pages/Account';
import { Helmet } from 'react-helmet';
import Preloved from './pages/Preloved';
import { addMenu, getMenus } from './firebaseConfig'
import { useNavigate } from 'react-router-dom'
import { distances, validatePhoneNumber } from './helpers'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import ReactLoading from 'react-loading';
import Accounts from './pages/Accounts';
import WhatsappEverClear from './pages/WhatsappEverClear';
import Splash from './pages/Splash';
import { ProgressBar } from 'react-bootstrap';
import ChatWindow from './components/ChatWindow';
import Outlets from './pages/Outlets';


function App() {
  const cathead = { index: 0, id: 'auto', name: 'Select Category' }

  const base = 20
  const prod = true;
  const [naved, setNav] = useState(false)
  const [loading_app, setLoadingApp] = useState(true)

  const [user, user_loading, user_error] = useAuthState(auth);

  const [riding, setRiding] = useState(false)
  const [chatai, setChatAI] = useState(false)
  const [user_data, setUser] = useState(null)
  const [hasLocation, setHasLocation] = useState(true)
  const [enabled, setEnabled] = useState(false)
  const [likes, setLikes] = useState([])
  const [pos, setPos] = useState(null)
  const [limit, setLimit] = useState(distances[0].max)
  const [items, setItems] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [name, setName] = useState('')
  const [busuness, setBusiness] = useState('')
  const [desc, setDesc] = useState('')
  const [phone, setPhone] = useState('')
  const [file, setFile] = useState("")
  const [price, setPrice] = useState(null)
  const [editpickup, setEditPickup] = useState(null)
  const [files, setFiles] = useState([])
  const [percent, setPercent] = useState(0)
  const [categories, setCategories] = useState([cathead])
  const [selectedCategory, setSelectedCategory] = useState(cathead)
  const [delivers, setUserDelivers] = useState([])
  const [vehicles, setVehicles] = useState([{ id: 0, name: 'Select mode of transport' }, { id: 1, name: 'Foot' }, { id: 2, name: 'Bicycle' }, { id: 3, name: 'Motorcycle' }])
  const [selectedVehicle, setSelectedVehicle] = useState({ id: 0, name: 'Select Transport' })

  const resetRiding = () => {
    setEditPickup(null)
    setRiding(false)
    setUploading(false)
    setFiles([])
    setName('')
    setDesc('')
    setPhone('')
    setPrice(0)

}

const handleNameChange = (event) => {
  setName(event.target.value);
}
const handleDescChange = (event) => {
  setDesc(event.target.value);
}
const handlePriceChange = (event) => {
  setPrice(event.target.value);
}
const handleFilesChange = (event) => {
  setPercent(0)
  const files = event.target.files;
  setFiles([...files]);
}
const getDeliver = async ({uid, isRider}) => {
  if(isRider && pos){
    const user_delivers = await getDeliverUser(uid, pos)
    setUserDelivers(user_delivers)
  }

}
const handleDeliver = async () => {
    if (pos) {
        setUploading(true)
        const data = {
            ...pos,
            name: name,
            vehicle: selectedVehicle,
            intro: '',
            price: 30,
            uid: user.uid,
            phone: phone,
        }


        if (editpickup === null) {
            await captureDeliver(files, setPercent, data, resetRiding)
        } else {
            await updateDeliverInfo(editpickup?.id, files, setPercent, data, resetRiding)
        }
        getDeliver(user_data)
    } else {
        console.log('no pos')
    }
}

  const navigateToAccount = () => {
    // 👇️ navigate
    setIsOpen(false)
    setUploading(false)
    window.location.href = '/account'
  }
  const openModal = () => {
    setIsOpen(true)
  }
  const closeModal = () => {
    setIsOpen(false)
  }
  const handleBusinessNameChange = (event) => {
    setBusiness(event.target.value);
  }
  const handleFileChange = (event) => {
    setPercent(0)
    setFile(event.target.files[0]);
  }
  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value
    const result = validatePhoneNumber(phoneNumber);
    // console.log(result, phoneNumber)
    if (result.result !== "invalid") {
      setPhone(result.result);
    } else {
      setPhone('')
    }
  }
  const handleUpload = async () => {
    if (pos) {
      setUploading(true)
      const data = {
        ...pos,
        title: busuness,
        desc: desc,
        requests: 0,
        likes: 0,
        uid: user.uid,
        image: '',
        categories: [selectedCategory.id],
        balance: 0,
        sales: 0,
        bonus: enabled ? 500 : 0,
        limit: parseInt(limit),
        active: true,
        phone: phone,
        prompt: enabled
      }

      if (file) {
        await addMenu(file, setPercent, data, navigateToAccount)
      } else {
        setUploading(false)
        console.log('no file')
      }
    } else {
      console.log('no pos')
    }
  }
  const handleFetch = (data) => {
    setItems(data)
    setTimeout(() => { setLoadingApp(false) }, 1000)
  }
  const handleLocation = async (open) => {
    setTimeout(() => { setLoadingApp(false) }, 3000)
    //console.log(pos)
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "granted") {
        open && openModal()
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: parseFloat(position.coords.latitude),
              lng: parseFloat(position.coords.longitude),
            }

            setPos(pos)
            getMenus(pos, handleFetch)

          },
          (e) => {
            console.log("Browser doesn't support Geolocation", e)
            // handleLocationError(true, infoWindow, map.getCenter());
          }
        );
      } else if (result.state === "prompt") {
        open && openModal()
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: parseFloat(position.coords.latitude),
              lng: parseFloat(position.coords.longitude),
            }

            setPos(pos)
            getMenus(pos, handleFetch)

          },
          (e) => {
            console.log("Browser doesn't support Geolocation", e)
            // handleLocationError(true, infoWindow, map.getCenter());
          }
        );
      } else if (result.state === "denied") {

      }
      result.addEventListener("change", () => {
        window.location.reload()
      });
    });
  }
  const handleCategories = async () => {
    const cats = await getCategories()
    setCategories([cathead, ...cats, { index: cats.length + 1, id: 'fin', name: 'etc' }])
  }
  useEffect(() => {
    handleCategories()
    handleLocation(false)
  }, [])

  const handleUser = (user) => {
    setUser(user)
    getDeliver(user)
  }

  useEffect(() => {
    user && getUser(user.uid, handleUser).then((unsub) => { })
  }, [user])

  return (
    <div className=''>

      {loading_app ?
        <Router>

          <Routes>
            <Route path="*" element={<Splash pos={pos} />} />
          </Routes>
        </Router> :
        <Router>
          <Header user={user_data} naved={naved} handleLocation={handleLocation} riding={riding} setRiding={setRiding} />
          <Routes>
            <Route path="/" element={<Landing setChatAI={setChatAI} user={user_data} handleLocation={handleLocation} items={items} pos={pos} />} />
            <Route path="/account" element={<Accounts prod={prod} editor={true} user={user} handleLocation={handleLocation} />} />
            <Route path="/outlets/:id" element={<Outlets user={user_data} pos={pos} outlets={items}/>} />
            <Route path="/account/:id" element={<Account prod={prod} editor={true} user={user} handleLocation={handleLocation} />} />
            <Route path="/request/:id" element={<Request prod={prod} user={user} />} />
            <Route path="/preloved" element={<Preloved prod={prod} user={user} />} />
            <Route path="/preloved/:prelovedid" element={<Landing prod={prod} user={user} />} />
            <Route path="/:id" element={<Landing prod={prod} user={user} />} />
            <Route path="/photos" element={<WhatsappEverClear prod={prod} user={user} />} />
            <Route path="/photos/:id" element={<WhatsappEverClear prod={prod} user={user} />} />
            <Route path="/whatsapp-ever-clear" element={<WhatsappEverClear prod={prod} user={user} />} />
          </Routes>
        </Router>}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >{
                  pos ? <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Welcome to DUKADASH!<br/>Let's first create your store
                      <span className='text-xs hidden'><b>Note:</b> Buyers pay you an additional 30/= per km + 30/= base for delivery. Ready? Let's do business!</span>
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className='flex flex-col space-y-4'>

                        <Listbox className='my-4' as={'div'} value={selectedCategory} onChange={setSelectedCategory}>
                          <ListboxButton className={`bg-white px-4 py-1 rounded-md min-w-[250px] relative border focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${selectedCategory?.index === 0 ? 'focus-visible:ring-offset-red-300 border-red-300' : 'focus-visible:ring-offset-teal-300 border-teal-300'}`}>
                            {selectedCategory?.name}
                            <ChevronDownIcon
                              className="group pointer-events-none absolute top-2.5 right-2.5 w-4 fill-black/60"
                              aria-hidden="true"
                            />
                          </ListboxButton>
                          <ListboxOptions className='bg-white rounded-md border my-1 h-[200px] overflow-scroll' anchor="bottom">
                            {categories.map((category) => (
                              <ListboxOption key={category.id} value={category}
                                className={`group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-blue-100 data-[selected]:text-white  m-1 ${selectedCategory?.index === 0 ? 'data-[selected]:bg-red-300' : 'data-[selected]:bg-teal-300'}`}
                              >
                                <CheckIcon className="invisible h-4 fill-white group-data-[selected]:visible" />
                                {category.name}
                              </ListboxOption>
                            ))}
                          </ListboxOptions>
                        </Listbox>
                        {/* <label for="prompt" className='text-sm hidden'><b>Note:</b> Karibu Bonus applies to <u>Prompt to Pay Vendors payable at KES 5500 sales balance</u></label> */}
                        {/* <Switch.Group name='prompt'>
                          <div className="flex items-center">
                            <Switch.Label className="mr-4">Enable Prompt to Pay? </Switch.Label>
                            <Switch
                              checked={enabled}
                              onChange={setEnabled}
                              className={`${enabled ? 'bg-blue-600' : 'bg-gray-200'
                                } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                            >
                              <span
                                className={`${enabled ? 'translate-x-6' : 'translate-x-1'
                                  } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                              />
                            </Switch>
                          </div>
                        </Switch.Group> */}
                        <input placeholder='Business Name (client facing)' type='text' onChange={(e) => handleBusinessNameChange(e)}
                          className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                        <input placeholder='Whatsapp Number (for orders)' type='number' onChange={(e) => handlePhoneChange(e)}
                          className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />
                        <label for="limit">How far can you serve customers?</label>
                        <div className='flex flex-row items-center justify-center px-4 flex-wrap'>
                          {distances.map((p) => <button
                            className={`inline-flex justify-center rounded-md border border-transparent ${p.max === limit ? 'bg-green-500 text-white' : 'bg-green-100 text-green-900'} px-6 py-4 text-sm font-medium  focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2 m-1`}
                            onClick={() => setLimit(p.max)}
                          >
                            {`${p.max} ${p.key}`}
                          </button>)}
                        </div>
                        <label for="avatar">Upload a photo of yourself / store:</label>
                        <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                          onChange={handleFileChange} />
                      </div>
                    </div>

                    <div className="mt-4">
                      {uploading ?
                        <ReactLoading type="bubbles" color="black" />
                        : <button
                          disabled={
                            phone === ''
                            || !pos
                            || !file
                            || selectedCategory.index === 0
                          }
                          type="button"
                          className={`inline-flex justify-center rounded-md border border-transparent ${selectedCategory?.index === 0 ? 'bg-gray-100 text-gray-900' : 'bg-green-100 text-green-900 hover:bg-green-200 '} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2`}
                          onClick={handleUpload}
                        >
                          Create Store
                        </button>}
                    </div>
                  </Dialog.Panel>
                    :
                    <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                      <ReactLoading type="bubbles" color="black" />
                    </div>
                }
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={chatai} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setChatAI}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-3 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full flex flex-col gap-1 max-w-md transform overflow-hidden rounded-2xl bg-white py-6 px-4 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className=" text-sm font-medium leading-6 py-4 text-gray-900"
                  >
                    Hey there, I'm Near! Here to help you locate what you need..
                  </Dialog.Title>
                  <ChatWindow user={user_data} items={items} categories={categories} setChatAI={setChatAI} />

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>


      <Transition appear show={riding} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {
          resetRiding()
        }}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >{
                  pos ?
                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                      <Dialog.Title
                        as="h3"
                        className="font-medium leading-6 text-gray-900"
                      >
                        <span className='text-sm'>As a Dasher, users will pay you the (item cost) + (30/= per km) + (30/= base fee) after delivery. So make sure you always have some cash at hand to fulfill requests<br/>Ready? Let's Dash!</span>
                      </Dialog.Title>
                      {(editpickup || !user_data?.isRider) ?
                        <div className="mt-2">
                          <div className='flex flex-col gap-y-4'>
                            <input placeholder='Name' type='text' onChange={(e) => handleNameChange(e)} defaultValue={name}
                              className=" p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                            <input placeholder='Tell the customer about yourself' type='text' onChange={(e) => handleDescChange(e)} defaultValue={desc}
                              className=" p-4 hidden w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />

                            <Listbox className='my-1' as={'div'} value={selectedVehicle} onChange={setSelectedVehicle}>
                              <ListboxButton className={`bg-white text-start px-4 py-1 rounded-md min-w-[250px] relative border focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${selectedCategory?.index === 0 ? 'focus-visible:ring-offset-red-300 border-red-300' : 'focus-visible:ring-offset-teal-300 border-teal-300'}`}>
                                {selectedVehicle?.name}
                                <ChevronDownIcon
                                  className="group pointer-events-none absolute top-2.5 right-2.5 w-4 fill-black/60"
                                  aria-hidden="true"
                                />
                              </ListboxButton>
                              <ListboxOptions className='bg-white rounded-md border my-1 h-[200px] overflow-scroll' anchor="bottom">
                                {vehicles.map((category) => (
                                  <ListboxOption key={category.id} value={category}
                                    className={`group flex cursor-default items-start gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-blue-100 data-[selected]:text-white  m-1 ${selectedCategory?.index === 0 ? 'data-[selected]:bg-red-300' : 'data-[selected]:bg-teal-300'}`}
                                  >
                                    <CheckIcon className="invisible h-4 fill-white group-data-[selected]:visible" />
                                    {category.name}
                                  </ListboxOption>
                                ))}
                              </ListboxOptions>
                            </Listbox>

                            <input placeholder='Price per kilometer' type='number' onChange={(e) => handlePriceChange(e)} defaultValue={30}
                              className=" p-4 hidden w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm" />
                            <input placeholder='Phone Number' type='number' onChange={(e) => handlePhoneChange(e)} defaultValue={phone}
                              className={`p-4 relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 ${phone === '' ? 'focus-visible:ring-offset-red-300' : 'focus-visible:ring-offset-teal-300'} sm:text-sm`} />

                            <label for="avatar">Upload your photo:</label>
                            <div className=' flex flex-wrap'>

                              {files.length > 0 ? files.map((file) => {
                                const image = URL.createObjectURL(file)
                                return (
                                  <img alt='item_photo' src={image} className='w-16 md:w-24 h-16 md:h-24 object-cover object-center rounded m-1' loading='lazy' />
                                )
                              })
                                :
                                editpickup && <img alt='item_photo' src={editpickup?.image} className='w-16 md:w-24 h-16 md:h-24 object-cover object-center rounded m-1' loading='lazy' />
                              }
                            </div>
                            <input className='bg-white' type="file" accept="image/*" id="avatar" name="avatar"
                              onChange={handleFilesChange} multiple={false} />
                          </div>
                        </div>
                        :
                        <div className='border rounded flex flex-col items-stretch'>
                          {

                            delivers.map((deliver, idx) => {
                              return (

                                <button key={idx}
                                  onClick={() => {
                                    setSelectedVehicle(deliver.vehicle)
                                    setName(deliver.name)
                                    setDesc(deliver.intro)
                                    setPhone(deliver.phone)
                                    setPrice(deliver.price)
                                    setEditPickup(deliver)
                                  }}
                                  className={`m-2 flex flex-col justify-between items-start rounded-md bg-white gap-1 px-2 text-sm font-medium`}>
                                  <div key={idx} className='flex flex-row w-full justify-between items-start gap-2 pt-2 py-1'>
                                    <img alt='' src={deliver.image} className={`w-6 h-6 border object-cover object-center rounded-full bg-slate-500`} loading='lazy' />
                                    <div className='flex-1 flex flex-col justify-start items-start'>
                                      <span className='my-0 text-sm text-center'>{`${deliver.name}`}</span>
                                      <span className='my-0 text-[8px] text-center font-semibold text-gray-600'>{`Location last updated on ${deliver.updated}`}</span>
                                      <span className={`my-0 text-[8px] text-center font-semibold ${(deliver?.dist) > 1 ? 'text-red-600' : 'text-green-600'}`}>{`About ${(deliver?.dist)} km away from last saved position`}</span>
                                    </div>
                                  </div>
                                  {/* <span className='text-justify text-[11px] font-light py-3 px-2'>{deliver.intro}</span> */}

                                  <div className='hidden w-full flex-row gap-8 py-2 items-center justify-between'>
                                    <a className={`flex gap-2 flex-1 text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                      href={`tel://${deliver.phone}`}>
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4">
                                        <path fill-rule="evenodd" d="m3.855 7.286 1.067-.534a1 1 0 0 0 .542-1.046l-.44-2.858A1 1 0 0 0 4.036 2H3a1 1 0 0 0-1 1v2c0 .709.082 1.4.238 2.062a9.012 9.012 0 0 0 6.7 6.7A9.024 9.024 0 0 0 11 14h2a1 1 0 0 0 1-1v-1.036a1 1 0 0 0-.848-.988l-2.858-.44a1 1 0 0 0-1.046.542l-.534 1.067a7.52 7.52 0 0 1-4.86-4.859Z" clip-rule="evenodd" />
                                      </svg>
                                      <span>{`Call`}</span>
                                    </a>
                                    <a className={`flex gap-2 flex-1 text-xs text-center justify-center text-white bg-green-600 px-8 py-2 rounded-full`}
                                      href={`https://wa.me/${deliver.phone}?text=${encodeURIComponent("Hi, I'd like to engage your procurement service")}`}>
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                                      </svg>
                                      <span>{`Text`}</span>
                                    </a>
                                  </div>
                                </button>
                              )
                            }
                            )
                          }
                        </div>
                      }

                      <div className='flex flex-row justify-between items-center gap-3 border-t mt-4 pt-4 bg-white border-black'>
                        {uploading ?
                          <ReactLoading type="bubbles" color="black" />
                          : user ? (!user_data?.isRider || editpickup) && <button
                            disabled={
                              name === ''
                              || selectedVehicle.id === 0
                              || phone === ''
                              || !pos
                              || (files.length === 0 && editpickup === null)
                            }
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 disabled:text-gray-900 disabled:bg-gray-100 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                            onClick={handleDeliver}
                          >
                            {editpickup ? 'Update' : 'Join'}
                          </button>
                            :
                            <button
                              type="button"
                              className="rounded border border-transparent px-4 py-2 text-sm font-medium text-green-900 bg-green-100 hover:bg-green-2 disabled:text-gray-900 disabled:bg-gray-100"
                              onClick={() => signInWithGoogle(() => { })}
                            >Sign In to Join</button>
                        }
                        <button
                          type="button"
                          className="rounded border border-transparent px-4 py-2 text-sm font-medium  text-gray-900 bg-gray-100"
                          onClick={() => {
                            resetRiding()
                          }
                          }
                        >Dismiss</button>
                      </div>
                    </Dialog.Panel>
                    :
                    <div className='flex flex-row flex-wrap items-center justify-center pt-[10vh]'>
                      <ReactLoading type="bubbles" color="black" />
                    </div>
                }
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </div>
  );
}

export default App;
