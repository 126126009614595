import { useState, useEffect } from 'react'

import Logo from '../../assets/logo.png'
import Dropdown from '../utils/dropdown'
import MobileMenu from './mobile-menu'
import { getUser, signInWithGoogle } from '../../firebaseConfig'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Header({ user, naved, handleLocation, riding, setRiding }) {

  const [top, setTop] = useState(true)
  const title = 'DUKADASH'


  const handleSignIn = () => {
    signInWithGoogle()
  }

  const openMenu = () => {

  }
  // detect whether user has scrolled the page down by 10px
  const scrollHandler = () => {
    window.scrollY > 10 ? setTop(false) : setTop(true)
  }

  useEffect(() => {
    scrollHandler()
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top, user])

  return (
    <div className={`fixed w-full z-10 bg-opacity-90 transition duration-300 ease-in-out ${!top ? 'bg-white backdrop-blur-sm shadow-lg' : ''}`}>

      <Helmet>
        <title>DUKADASH</title>
        <meta property="og:title" content="Dukadash" />
        <meta property="og:description" content='Enable location shopping' />
        <meta name="description" content="Visit duaknear.com to take advantage of amazing resale prices!" />
        <link rel="canonical" href={`http://dukadash.com`} />
      </Helmet>
      <div className="flex items-center justify-between p-4 md:h-20">

        {/* Site branding */}

        <Link to={'/'} className='flex flex-row items-center space-x-1'>
          <img alt='logo' src={Logo} className='logo object-cover h-8 w-8' />
        </Link>

        <Link to={'/'} className='flex flex-row items-center space-x-1 decoration-transparent'>
          <span className=' font-light text-3xl text-red-700 tracking-widest text-center line-clamp-2'>{title}</span>
        </Link>
        {/* Desktop navigation */}
        <nav className="hidden">
          {/* Desktop sign in links */}
          <ul className="flex grow justify-end flex-wrap items-center">
            {/* <li>
                <a href="/signin" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Sign in</a></li> */}
            <li>{
              user ? <>
                <button className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clipRule="evenodd" />
                  </svg>

                </button>
              </>
                : <button className="flex rounded items-center py-4 px-6 btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3"
                  onClick={handleSignIn}>
                  <span>Sign in</span>
                  <svg className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                  </svg>
                </button>
            }
            </li>

          </ul>

        </nav>

        <MobileMenu user={user} handleLocation={handleLocation} setRiding={setRiding} />

      </div>
    </div>
  )
}